import styled from "@emotion/styled";
import { theme } from "theme";

export const DropdownWrapper = styled.div`
  position: relative;
  z-index: 1000;
`;

export const DropdownMenu = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: var(--spacing-xs);
  position: absolute;
  top: calc(100% + var(--spacing-sm));
  right: 0;
  background-color: ${theme.color.white};
  border-radius: var(--border-radius);
  width: 220px;
  padding: var(--spacing-md);
  box-shadow: var(--dropdown-shadow);
`;
