import {
  CurrencySelect,
  FormDatePickerField,
  FormNumericField,
  FormSelect,
  FormTextField,
} from "components/FormItems";
import { useLocale } from "hooks";
import { getLocalizedPlaceholderLabel } from "new-components/TextEditor/utils";
import {
  ContractFieldDTOV1,
  DurationFieldTypeDtoV1,
  ListFieldTypeDtoV1,
} from "openapi";
import React from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { PlaceholderForm } from "../types";

type DatapointComponentFactoryProps = {
  datapoint: ContractFieldDTOV1;
  slug: string;
  fieldKey: string;
};

export const DatapointComponentFactory = ({
  datapoint,
  slug,
  fieldKey,
}: DatapointComponentFactoryProps) => {
  const { t } = useTranslation();
  const { control } = useFormContext<PlaceholderForm>();
  const { locale } = useLocale();
  const label = getLocalizedPlaceholderLabel(
    datapoint,
    locale,
    t,
    slug,
    fieldKey
  );

  switch (datapoint?.type) {
    case ContractFieldDTOV1.type.DATE:
      return (
        <FormDatePickerField control={control} label={label} name="value" />
      );
    case ContractFieldDTOV1.type.AMOUNT: {
      return fieldKey === "value" ? (
        <FormNumericField control={control} label={label} name="value" />
      ) : (
        <CurrencySelect control={control} label={label} name="value" />
      );
    }
    case ContractFieldDTOV1.type.LIST: {
      const options = (datapoint.data as ListFieldTypeDtoV1)?.items?.map(
        (item) => {
          return { key: item.id, value: item.value };
        }
      );

      return (
        <FormSelect
          control={control}
          label={label}
          name="value"
          translationPrefix={
            datapoint.oldStandardField && datapoint.visibleId
              ? `enums.${datapoint.visibleId}`
              : undefined
          }
          options={options ?? []}
        />
      );
    }

    case ContractFieldDTOV1.type.NUMBER:
      return <FormNumericField control={control} label={label} name="value" />;
    case ContractFieldDTOV1.type.DURATION: {
      switch (fieldKey) {
        case "startDate":
        case "endDate":
        case "terminationDate":
          return (
            <FormDatePickerField control={control} label={label} name="value" />
          );
        case "type":
          return (
            <FormSelect
              control={control}
              label={label}
              name="value"
              translationPrefix="pages.contractEdit.forms.durationForm.durationTypeOptions"
              options={Object.keys(DurationFieldTypeDtoV1.type)}
            />
          );
        default:
          return (
            <FormNumericField
              control={control}
              label={label}
              name="value"
              decimalScale={0}
            />
          );
      }
    }
    default:
      return (
        <FormTextField control={control} label={label} name="value" fullWidth />
      );
  }
};
