import styled from "@emotion/styled";
import CustomTagIcon from "assets/svg/tag-icon.svg?react";
import CustomAddIcon from "assets/svg/plus.svg?react";
import CustomBinIcon from "assets/svg/bin-icon.svg?react";
import CustomEditIcon from "assets/svg/edit-icon.svg?react";
import { theme } from "theme";
import { Autocomplete as MUIAutoCompleteSelect } from "@mui/material";

export const TagWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: ${theme.spacing.sm};
`;

export const AddTagButton = styled.button<{ visible?: boolean }>`
  border: 1px dashed ${theme.color.gray[300]};
  border-radius: ${theme.borderRadius};
  color: ${theme.color.gray[600]};
  background-color: transparent;
  padding: 0 ${theme.spacing.xs} 0 0;
  margin: 0 ${theme.spacing.xs} 0;
  display: flex;
  align-items: center;
  gap: ${theme.spacing.xs};
  cursor: pointer;
  transition: border 0.15s ease-in-out;

  :hover {
    border: 1px dashed ${theme.color.gray[400]};
  }
`;

export const paperStyles = {
  padding: "8px",
  marginTop: ".5rem",
  maxWidth: "420px",
  maxHeight: "520px",
  overflow: "hidden",
  boxShadow: "var(--dropdown-shadow)",
};

export const SelectedTags = styled.div`
  display: flex;
  gap: ${theme.spacing.xs};
  flex-wrap: wrap;
`;

export const AddIcon = styled(CustomAddIcon)`
  fill: ${theme.color.gray[600]};
`;

export const TagsContainer = styled.button<{
  stretched?: boolean;
  $isGridView: boolean;
}>`
  display: flex;
  gap: ${theme.spacing.xs};
  border: 1px solid transparent;
  background-color: transparent;
  padding: ${theme.spacing.xxs} 0;
  border-radius: ${theme.borderRadius};
  cursor: pointer;
  transition: border 0.15s ease-in-out;
  ${({ stretched, $isGridView }) =>
    $isGridView && `max-width: ${stretched ? "360px" : "170px"};`};
  ${({ $isGridView }) => ($isGridView ? "flex-wrap: wrap;" : "")}
  overflow: hidden;
`;

const commonTagStyles = `
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${theme.spacing.xs};
  color: ${theme.color.blue[700]};
  background-color: ${theme.color.blue[100]};
  border-radius: ${theme.borderRadius};
  padding: ${theme.spacing.xxs} ${theme.spacing.xs};
`;

export const Tag = styled.div`
  ${commonTagStyles};
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const TagCount = styled.div`
  ${commonTagStyles};
  border: none;
`;

export const TagIcon = styled(CustomTagIcon)`
  fill: ${theme.color.blue[700]};
  width: 12px;
  height: 12px;
`;

export const TagSettings = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.sm};
`;

export const List = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  width: 170px;
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.xs};
  width: 100%;
`;

export const ListItem = styled.li<{ remove?: boolean }>`
  display: flex;
  justify-content: space-between;
  gap: ${theme.spacing.sm};
  padding: ${theme.spacing.xs};
  cursor: pointer;
  color: ${({ remove }) => (remove ? theme.color.red[600] : theme.color.black)};

  :hover {
    background-color: ${theme.color.gray[100]};
    border-radius: ${theme.borderRadius};
  }

  span {
    display: flex;
    align-items: center;
    gap: ${theme.spacing.sm};
    padding: ${theme.spacing.xs} ${theme.spacing.sm};
  }
`;

export const Rename = styled.div`
  display: flex;
  gap: ${theme.spacing.sm};
  color: ${theme.color.black};
  cursor: pointer;
  background-color: transparent;
  border: none;
`;

export const EditIcon = styled(CustomEditIcon)`
  fill: ${theme.color.black};
`;
export const BinIcon = styled(CustomBinIcon)`
  fill: ${theme.color.red[600]};
`;

export const popoverStyles = {
  borderRadius: theme.borderRadius,
};

export const AutocompleteSelect = styled(MUIAutoCompleteSelect)``;
