import {
  INSERT_UNORDERED_LIST_COMMAND,
  INSERT_CHECK_LIST_COMMAND,
  INSERT_ORDERED_LIST_COMMAND,
} from "@lexical/list";
import { HeadingTagType, $createHeadingNode } from "@lexical/rich-text";
import { $isAtNodeEnd, $setBlocksType } from "@lexical/selection";
import { $generateNodesFromDOM } from "@lexical/html";
import {
  $createParagraphNode,
  $getRoot,
  $getSelection,
  $insertNodes,
  $isRangeSelection,
  $nodesOfType,
  EditorState,
  ElementNode,
  LexicalEditor,
  RangeSelection,
  TextNode,
} from "lexical";
import { ContractFieldDTOV1 } from "openapi";
import { Language } from "shared/i18n/i18n";
import { TFunction } from "i18next";
import { PlaceholderTextNode } from "./nodes/PlaceholderTextNode";
import SupportedLexicalNodes from "./config";
import { createHeadlessEditor } from "@lexical/headless";

export const importHTML = (html: string, editor: LexicalEditor) => {
  editor?.update(() => {
    const parser = new DOMParser();
    const dom = parser.parseFromString(html, "text/html");

    const nodes = $generateNodesFromDOM(editor, dom);
    $getRoot().clear();
    $getRoot().select();
    $insertNodes(nodes);
  });
};

export const formatParagraph = (editor: LexicalEditor) => {
  editor.update(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      $setBlocksType(selection, () => $createParagraphNode());
    }
  });
};

export const formatHeading = (
  editor: LexicalEditor,
  currentBlockType: string,
  headingSize: HeadingTagType
) => {
  if (currentBlockType !== headingSize) {
    editor.update(() => {
      const selection = $getSelection();
      $setBlocksType(selection, () => $createHeadingNode(headingSize));
    });
  }
};

export const formatBulletList = (
  editor: LexicalEditor,
  currentBlockType: string
) => {
  if (currentBlockType !== "bullet") {
    editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined);
  } else {
    formatParagraph(editor);
  }
};

export const formatCheckList = (
  editor: LexicalEditor,
  currentBlockType: string
) => {
  if (currentBlockType !== "check") {
    editor.dispatchCommand(INSERT_CHECK_LIST_COMMAND, undefined);
  } else {
    formatParagraph(editor);
  }
};

export const formatNumberedList = (
  editor: LexicalEditor,
  currentBlockType: string
) => {
  if (currentBlockType !== "number") {
    editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined);
  } else {
    formatParagraph(editor);
  }
};

export function getSelectedNode(
  selection: RangeSelection
): TextNode | ElementNode {
  const anchor = selection.anchor;
  const focus = selection.focus;
  const anchorNode = selection.anchor.getNode();
  const focusNode = selection.focus.getNode();
  if (anchorNode === focusNode) {
    return anchorNode;
  }
  const isBackward = selection.isBackward();
  if (isBackward) {
    return $isAtNodeEnd(focus) ? anchorNode : focusNode;
  } else {
    return $isAtNodeEnd(anchor) ? anchorNode : focusNode;
  }
}

export const getLocalizedPlaceholderLabel = (
  datapoint: ContractFieldDTOV1 | null | undefined,
  locale: Language,
  t: TFunction,
  slug: string,
  fieldKey: string
): string => {
  if (!datapoint) return PlaceholderTextNode.getDefaultText(slug, fieldKey);

  const res: { slug: string | null; fieldKey: string | null } = {
    slug: datapoint.name[locale],
    fieldKey: null,
  };

  if (fieldKey !== "value") {
    switch (datapoint.type) {
      case ContractFieldDTOV1.type.CONTACT:
        res.fieldKey = t(`pages.contacts.table.columnNames.${fieldKey}`);
        break;
      case ContractFieldDTOV1.type.DURATION:
        res.fieldKey = t(`pages.import.labels.duration.${fieldKey}`);
        res.slug = "";
        break;
      case ContractFieldDTOV1.type.AMOUNT:
        res.fieldKey = t(`pages.contracts.tableColumns.${fieldKey}`);
        if (fieldKey === "currency") {
          res.slug = res.fieldKey;
          res.fieldKey = datapoint.name[locale];
        }
        break;
      default:
        break;
    }

    return PlaceholderTextNode.getDefaultText(
      res.slug ?? slug,
      res.fieldKey ?? fieldKey
    );
  }

  return res.slug as string;
};

export const filterEmptyPlaceholdersInEditorState = (
  editorState: EditorState
): EditorState => {
  const headlessEditor = createHeadlessEditor({
    nodes: SupportedLexicalNodes,
    onError: () => void 0,
    editorState,
  });

  headlessEditor.update(
    () => {
      const placeholders = $nodesOfType(PlaceholderTextNode);
      placeholders.forEach((placeholder) => {
        if (!placeholder.hasEphemeralValue()) {
          placeholder.remove();
        }
      });
    },
    {
      discrete: true,
    }
  );

  return headlessEditor.getEditorState();
};
