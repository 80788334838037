import { clearTemporaryStates } from "contexts/grid/storage";
import { useUserInfo } from "hooks/GlobalStateHooks";
import { useSnackbar } from "notistack";
import { TeamPermissionDto, TeamPermissionInfoDto } from "openapi";
import { useContext, useEffect, useRef } from "react";
import { getSelectedTeamPayload } from "./actions";
import { TeamContext } from "./context";
import { SetSelectedTeamPayload, TeamActionType } from "./types";

export function useTeam() {
  const { state, dispatch } = useContext(TeamContext);
  const { userInfo } = useUserInfo();
  const { enqueueSnackbar } = useSnackbar();

  const drawerOpenState = state.drawerOpenState;
  const selectedTeamId = state.selectedTeam?.id ?? "";
  const selectedTeam = state.selectedTeam;
  const status = state.status;
  const error = state.error;
  const permissionSet = state.permissions;
  const features = state.selectedTeam?.features;
  const maxDocumentsPerContract = state.selectedTeam?.maxDocumentsPerContract;
  const parentTeamId = selectedTeam?.parentTeamId ?? selectedTeamId;

  const selectedTeamRef = useRef(selectedTeam);
  selectedTeamRef.current = selectedTeam;

  useEffect(() => {
    if (error && error !== "Unauthorized") {
      enqueueSnackbar(error, { variant: "error" });
    }
  }, [error]);

  const toggleDrawer = (openState: boolean) => {
    dispatch({ type: TeamActionType.toggleDrawer, params: openState });
  };

  const setSelectedTeam = async (teamId?: string) => {
    let actionPayload: SetSelectedTeamPayload = { error: null };

    if (teamId) {
      actionPayload = await getSelectedTeamPayload(teamId, userInfo);
    }

    if (
      actionPayload.data &&
      selectedTeamId !== actionPayload.data.teamInfo.id
    ) {
      clearTemporaryStates();
    }

    dispatch({
      type: TeamActionType.setSelectedTeam,
      params: actionPayload,
    });
  };

  const refetch = async () => {
    await setSelectedTeam(selectedTeamRef.current?.id);
  };

  const getRoleByTeamId = (teamId?: string) => {
    const teamID = teamId || selectedTeamId;
    if (permissionSet && teamID) {
      return permissionSet[teamID]?.role;
    }
    return;
  };

  const isLimitedUser = (teamId?: string) => {
    return getRoleByTeamId(teamId) === TeamPermissionInfoDto.role.LIMITED_USER;
  };

  const isUser = (teamId?: string) => {
    return getRoleByTeamId(teamId) === TeamPermissionInfoDto.role.USER;
  };

  const isOwner = (teamId?: string) => {
    return getRoleByTeamId(teamId) === TeamPermissionInfoDto.role.OWNER;
  };

  const hasReadOrWriteAccess = (
    categoryId: string | undefined,
    teamId?: string
  ) => {
    if (!permissionSet || !permissionSet?.[teamId ?? selectedTeamId]) {
      return false;
    }
    if (isOwner(teamId) || isUser(teamId)) {
      return true;
    }
    const access = permissionSet[teamId ?? selectedTeamId]?.permissions?.find(
      (element) => element.categoryId === categoryId
    );
    return Boolean(access);
  };

  const hasWriteAccess = (categoryId: string | undefined, teamId?: string) => {
    if (!permissionSet || !permissionSet?.[teamId ?? selectedTeamId]) {
      return false;
    }
    if (isOwner(teamId) || isUser(teamId)) {
      return true;
    }
    return (
      permissionSet[teamId ?? selectedTeamId]?.permissions?.find(
        (element) => element.categoryId === categoryId
      )?.level === TeamPermissionDto.level.WRITE
    );
  };

  const hasWriteAccessToAny = (teamId?: string) => {
    if (!permissionSet || !permissionSet?.[teamId ?? selectedTeamId]) {
      return false;
    }
    if (isOwner(teamId) || isUser(teamId)) {
      return true;
    }

    return !!permissionSet[teamId ?? selectedTeamId]?.permissions?.find(
      (element) => element.level === TeamPermissionDto.level.WRITE
    );
  };

  const hasFeature = (feature: string): boolean =>
    features?.includes(feature) ?? false;

  // This is the 'parentTeam' and not the child team, for the rest it is fine, but not clean.
  const userInfoTeam = userInfo?.teams.find(
    (team) =>
      team.id === selectedTeam?.id ||
      team.children.find((child) => child.id === selectedTeam?.id)
  );
  const organizationId = userInfoTeam?.organizationId as string;

  return {
    drawerOpenState,
    selectedTeamId,
    maxDocumentsPerContract,
    selectedTeam,
    status,
    error,
    permissionSet,
    features,
    parentTeamId,
    organizationId,
    refetch,
    toggleDrawer,
    setSelectedTeam,
    getRoleByTeamId,
    isLimitedUser,
    isUser,
    isOwner,
    hasReadOrWriteAccess,
    hasWriteAccess,
    hasWriteAccessToAny,
    hasFeature,
  };
}
