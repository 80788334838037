import styled from "@emotion/styled";
import ChevronIcon from "assets/svg/ChevronDown.svg?react";
import theme from "theme/theme";

const arrowSize = "12px";
const arrowWrapperSize = "20px";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${arrowWrapperSize};
  width: ${arrowWrapperSize};
  padding: var(--spacing-xs);
  border-radius: 50%;
  transition: background-color 0.25s ease-in-out;

  &:hover {
    background-color: ${theme.color.gray[400]}80;
    cursor: pointer;
  }
`;

export const Arrow = styled(ChevronIcon)<{ active: boolean }>`
  width: ${arrowSize};
  height: ${arrowSize};
  fill: ${theme.color.gray[600]};

  ${({ active }) =>
    active &&
    `
    rotate: 180deg;
  `}
`;

export const DurationFieldsList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.xs};
  list-style-type: none;
  margin-left: ${theme.spacing.lg};
  padding-left: ${theme.spacing.lg};
  border-left: 1px solid ${theme.color.gray[300]};
  color: ${theme.color.gray[600]};
`;
