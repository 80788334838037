import styled from "@emotion/styled";
import { theme } from "theme";

export const Layout = styled.div`
  display: flex;
  overflow-y: auto;
`;

export const Main = styled.div<{ fullWidth?: boolean }>`
  display: flex;
  flex: 0 0 ${({ fullWidth }) => (fullWidth ? "100%" : "75%")};
  flex-direction: column;
  background-color: #fcfaf6;
  size: A4;
`;

export const Sidebar = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-lg);
  flex: 0 0 25%;
  padding: var(--spacing-lg);
  border-top: 1px solid ${theme.color.gray[300]};
  border-left: 1px solid ${theme.color.gray[300]};
  background-color: ${theme.color.white};
`;
