import Grid from "@mui/material/Grid";
import { addSuffix, dateFormatter } from "constants/utils";
import { useLocale, useUserInfo } from "hooks/GlobalStateHooks";
import { DurationFieldTypeDtoV1 } from "openapi/models/DurationFieldTypeDtoV1";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { DataPointProps } from "./types";
import FormDurationField from "components/FormItems/FormDurationField/FormDurationField";
import { useAnalysis } from "./hooks/useAnalysis";
import { DatapointField } from "./components/DatapointField";
import useDataPointAnalysis from "./hooks/useDatapointAnalysis";

export const DurationDatapoint: FC<DataPointProps> = ({
  definition,
  values,
  editable,
  showAnalysis,
  contractId,
  teamId,
  fetchData,
}) => {
  const { t } = useTranslation();
  const { locale } = useLocale();
  const { userInfo } = useUserInfo();
  const userDateFormat = userInfo?.dateFormat;

  const data = values[definition.id] as DurationFieldTypeDtoV1;
  const analysis = useAnalysis(definition, data);

  const { suggestions, verify, selectInsight } = useDataPointAnalysis(
    definition,
    {
      id: contractId,
      teamId: teamId,
    },
    data
  );

  if (editable) {
    return (
      <Grid item xs={12} md={12}>
        <FormDurationField
          field={definition}
          analysis={showAnalysis ? analysis : undefined}
          suggestions={suggestions}
        />
      </Grid>
    );
  }

  const addMonthSuffix = (months: number | undefined | null) => {
    return addSuffix(
      t(
        (months ?? 0) > 1
          ? "pages.contractDetails.duration.months"
          : "pages.contractDetails.duration.month"
      ),
      months || undefined
    );
  };

  return (
    <>
      <Grid item xs={12} md={12}>
        <DatapointField
          definition={definition}
          data={data}
          field="type"
          suggestions={suggestions}
          name={t("pages.contracts.tableColumns.durationType")}
          onVerify={async () => {
            await verify(["type"]);
            void fetchData?.();
          }}
          onSelectInsight={async (value) => {
            await selectInsight("type", value);
            void fetchData?.();
          }}
        >
          {data
            ? data.type
              ? t(
                  `pages.contractEdit.forms.durationForm.durationTypeOptions.${data.type}`
                )
              : "-"
            : "-"}
        </DatapointField>
      </Grid>

      <Grid item xs={12} md={6}>
        <DatapointField
          definition={definition}
          data={data}
          field={"startDate"}
          suggestions={suggestions}
          name={t("pages.contractDetails.duration.startDate")}
          onVerify={async () => {
            await verify(["startDate"]);
            void fetchData?.();
          }}
          onSelectInsight={async (value) => {
            await selectInsight("startDate", value);
            void fetchData?.();
          }}
        >
          {dateFormatter(locale, data?.startDate, userDateFormat)}
        </DatapointField>
      </Grid>

      <Grid item xs={12} md={6}>
        <DatapointField
          definition={definition}
          data={data}
          field="interval"
          suggestions={suggestions}
          name={t("pages.contractDetails.duration.durationInMonths")}
          onVerify={async () => {
            await verify(["interval"]);
            void fetchData?.();
          }}
          onSelectInsight={async (value) => {
            await selectInsight("interval", value);
            void fetchData?.();
          }}
        >
          {addMonthSuffix(data?.interval)}
        </DatapointField>
      </Grid>

      <Grid item xs={12} md={6}>
        <DatapointField
          definition={definition}
          data={data}
          field="endDate"
          suggestions={suggestions}
          name={t("pages.contractDetails.duration.endDate")}
          onVerify={async () => {
            await verify(["endDate"]);
            void fetchData?.();
          }}
          onSelectInsight={async (value) => {
            await selectInsight("endDate", value);
            void fetchData?.();
          }}
        >
          {dateFormatter(locale, data?.endDate, userDateFormat)}
        </DatapointField>
      </Grid>

      <Grid item xs={12} md={6}>
        <DatapointField
          definition={definition}
          data={data}
          field="noticePeriod"
          suggestions={suggestions}
          name={t("pages.contractDetails.duration.noticeInMonths")}
          onVerify={async () => {
            await verify(["noticePeriod"]);
            void fetchData?.();
          }}
          onSelectInsight={async (value) => {
            await selectInsight("noticePeriod", value);
            void fetchData?.();
          }}
        >
          {addMonthSuffix(data?.noticePeriod)}
        </DatapointField>
      </Grid>

      <Grid item xs={12} md={6}>
        <DatapointField
          definition={definition}
          data={data}
          field="terminationDate"
          suggestions={suggestions}
          name={t("pages.contractDetails.duration.lastTerminationDate")}
          onVerify={async () => {
            await verify(["terminationDate"]);
            void fetchData?.();
          }}
          onSelectInsight={async (value) => {
            await selectInsight("terminationDate", value);
            void fetchData?.();
          }}
        >
          {dateFormatter(locale, data?.terminationDate, userDateFormat)}
        </DatapointField>
      </Grid>

      <Grid item xs={12} md={6}>
        <DatapointField
          definition={definition}
          data={data}
          field="automaticRenewal"
          suggestions={suggestions}
          name={t("pages.contractDetails.duration.autoRenewalInMonths")}
          onVerify={async () => {
            await verify(["automaticRenewal"]);
            void fetchData?.();
          }}
          onSelectInsight={async (value) => {
            await selectInsight("automaticRenewal", value);
            void fetchData?.();
          }}
        >
          {addMonthSuffix(data?.automaticRenewal)}
        </DatapointField>
      </Grid>
    </>
  );
};
