import React from "react";
import { useTranslation } from "react-i18next";
import ContractIcon from "assets/svg/documents-icon.svg?react";
import TasksIcon from "assets/svg/clipboard-check.svg?react";
import {
  Wrapper,
  List,
  ListItem,
  IconWrapper,
  Name,
} from "pages/Contract/components/RightSide/StickyMenu/styles";

type StickyMenuProps = {
  currentSelectedTab: {
    id: number;
    key: string;
  };
  handleSelectedTab: (val: number, key: string) => void;
};

export const options = [
  {
    id: 1,
    key: "documents",
    icon: ContractIcon,
    color: "var(--color-primary)",
  },
  {
    id: 2,
    key: "tasks",
    icon: TasksIcon,
    color: "var(--color-base-tasks)",
  },
] as const;

const StickyMenu = ({
  currentSelectedTab,
  handleSelectedTab,
}: StickyMenuProps) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <List>
        {options.map((item) => {
          const Icon = item.icon;
          // @TODO: Show only the tabs which are available for current user (based on permissions system).
          return (
            <ListItem
              key={item.key}
              active={currentSelectedTab.id === item.id}
              onClick={() => handleSelectedTab(item.id, item.key)}
              color={item.color}
            >
              <IconWrapper color={item.color}>
                <Icon />
              </IconWrapper>
              <Name color={item.color} className="list-item-name">
                {t(`pages.contact.folders.${item.key}`)}
              </Name>
            </ListItem>
          );
        })}
      </List>
    </Wrapper>
  );
};

export default StickyMenu;
