import React from "react";
import { InviteSupportUserModalHeader } from "./styles";
import { useTranslation } from "react-i18next";
import { useTeam } from "contexts/team/hooks";
import { useOrganizationQuery } from "shared/api/organization/organization";
import { useFormContext } from "react-hook-form";
import { FormSwitch } from "components/FormItems/FormSwitch/FormSwitch";
import { FormValues } from "./InviteSupportUserSection";

const InviteSupportUserModalContent = () => {
  const { t } = useTranslation();
  const { organizationId } = useTeam();

  const {
    control,
    formState: { defaultValues },
  } = useFormContext<FormValues>();
  const locale =
    "pages.settings.organization.overview.sections.inviteSupportUser.modal";

  const { data: organization } = useOrganizationQuery(organizationId);

  return (
    <>
      <InviteSupportUserModalHeader>
        {t(`${locale}.message.heading`, {
          organizationName: organization?.name,
        })}
      </InviteSupportUserModalHeader>
      <p>{t(`${locale}.message.description`)}</p>
      <FormSwitch
        checked={defaultValues?.allowAccess}
        control={control}
        label={t(`${locale}.grantAccess`)}
        labelPosition="left"
        name="allowAccess"
        stretch
      />
    </>
  );
};

export default InviteSupportUserModalContent;
