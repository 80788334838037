import { TFunction } from "i18next";

export const fieldsToSkip = [
  "id",
  "teamId",
  "parentId",
  "createdAt",
  "updatedAt",
  "createdBy",
  "tasksNumber",
  "hasFile",
  "analysisData",
  "annotationData",
  "metadata",
];

export const dateFields = ["startDate", "endDate", "terminationDate"];

export const getTranslation = (
  t: TFunction<"translation">,
  item: string,
  key: string
) => {
  switch (key) {
    case "status":
      return t(`enums.status.${item}`);

    case "type":
      return t(`contractType.${item}`);
  }
};
