/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { CustomFieldValueDto } from "./CustomFieldValueDto";

export type ContractUpdateDto = {
  categoryId: string | null;
  status: ContractUpdateDto.status;
  paymentType: ContractUpdateDto.paymentType | null;
  paymentCycle: ContractUpdateDto.paymentCycle | null;
  paymentMethod: ContractUpdateDto.paymentMethod | null;
  name: string;
  internalTeam: string | null;
  partnerCompany: string | null;
  partnerPerson: string | null;
  partnerEmail: string | null;
  partnerPhone: string | null;
  partnerAddress: string | null;
  partnerAddress2: string | null;
  partnerCity: string | null;
  partnerZip: string | null;
  partnerCountryCode: string | null;
  paymentPrice: number | null;
  paymentCurrencyCode: string;
  paymentTax: number | null;
  customFieldValues: Array<CustomFieldValueDto>;
};

export namespace ContractUpdateDto {
  export enum status {
    DRAFT = "DRAFT",
    SIGNING = "SIGNING",
    PROCESSING = "PROCESSING",
    ACTIVE = "ACTIVE",
    INACTIVE = "INACTIVE",
    CANCELLED = "CANCELLED",
    TERMINATED = "TERMINATED",
  }

  export enum paymentType {
    EXPENSE = "EXPENSE",
    INCOME = "INCOME",
    OTHER = "OTHER",
  }

  export enum paymentCycle {
    PER_HOUR = "PER_HOUR",
    PER_DAY = "PER_DAY",
    PROJECT_BASED = "PROJECT_BASED",
    ONETIME = "ONETIME",
    MONTHLY = "MONTHLY",
    BIMONTHLY = "BIMONTHLY",
    QUARTERLY = "QUARTERLY",
    BIANNUAL = "BIANNUAL",
    YEARLY = "YEARLY",
    EVERY_2_YEARS = "EVERY_2_YEARS",
    EVERY_3_YEARS = "EVERY_3_YEARS",
    NO_PAYMENT = "NO_PAYMENT",
  }

  export enum paymentMethod {
    WIRE_TRANSFER = "WIRE_TRANSFER",
    CREDIT_CARD = "CREDIT_CARD",
    DIRECT_DEBIT = "DIRECT_DEBIT",
    PAYPAL = "PAYPAL",
    OTHER = "OTHER",
  }
}
