import styled from "@emotion/styled";
import { theme } from "theme";
import ArrowIcon from "assets/svg/arrow-up.svg?react";

const flexCenterStyles = `
  display: flex;
  align-items: center;
  gap: var(--spacing-xs);
`;

export const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xl);
`;

export const BorderedBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-sm);
  padding: var(--spacing-md);
  border-radius: var(--border-radius);
  border: 1px solid ${theme.color.gray[300]};
`;

export const Type = styled.div`
  ${flexCenterStyles}
`;

export const ArrowRightIcon = styled(ArrowIcon)`
  transform: rotate(90deg);
  width: 20px;
  height: 20px;
`;

export const TypeLabel = styled.div`
  ${flexCenterStyles};
  font-size: ${theme.font.size.caption};
  svg {
    width: 16px;
    hieght: 16px;
    fill: ${theme.color.black};
  }
`;

export const List = styled.ul`
  padding: 0 0 0 var(--spacing-xl);
  margin: 0;
  list-style-type: square;
`;

export const Name = styled.span`
  color: ${theme.color.gray[600]};
  padding-right: var(--spacing-sm);
`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-sm);
`;
