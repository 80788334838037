import styled from "@emotion/styled";
import { Box, Button, Card, Popover as MUIPopover } from "@mui/material";
import { theme } from "theme";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${theme.spacing.lg};
  gap: ${theme.spacing.sm};
  flex-wrap: wrap;
  transition: opacity 0.25s ease-in-out;

  .tag-container {
    display: flex;
    gap: ${theme.spacing.xs};
    padding: ${theme.spacing.xs} ${theme.spacing.md};
    color: ${theme.color.purple[900]};

    svg {
      fill: transparent;
    }
  }
`;

export const FormWrapper = styled.div`
  margin-top: var(--spacing-lg);
`;

export const SaveButton = styled(Button)`
  padding: ${theme.spacing.md};
  background-color: ${theme.color.blue[600]};
  width: 100%;
  color: ${theme.color.white};
  text-transform: none;
  font-weight: 700;
  font-size: ${theme.font.size.subheading};

  :hover {
    background-color: ${theme.color.blue[400]};
  }
`;

export const StickyCard = styled(Card)`
  position: sticky;
  bottom: 0;
  border-radius: ${theme.borderRadius};
  padding: ${theme.spacing.lg};
  background-color: transparent;
  z-index: 2;
`;

export const ContractHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ContractTitle = styled.h1`
  padding-bottom: ${theme.spacing.lg};
  margin-bottom: ${theme.spacing.lg};
  border-width: ${theme.spacing.lg};
  border-bottom: 2.5px dashed #cccccca3;
`;

export const ContractGrid = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media screen and (min-width: 768px) {
    flex-direction: row;
    position: relative;
  }
`;

export const ContractColumn = styled.div`
  width: 100%;
  box-sizing: border-box;
  @media screen and (min-width: 768px) {
    width: 50%;
  }

  :nth-of-type(1) {
    margin-right: ${theme.spacing.sm};
  }

  :nth-of-type(2) {
    position: sticky;
    top: 100px;
    align-self: start;
  }
`;

export const Settings = styled.div`
  border: 1px solid orange;
  margin: ${theme.spacing.lg} 0;
  padding: ${theme.spacing.lg} 0;
`;

export const DatapointContent = styled.div`
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: pre-wrap;
`;

export const PreviewPDF = styled.object`
  height: calc(100% - 4.5rem);
  width: 100%;
  border: none;
  margin-top: ${theme.spacing.sm};
`;

export const Popover = styled(MUIPopover)`
  .MuiPaper-root {
    padding: ${theme.spacing.xl};
    min-width: 375px;
    border-radius: 16px;
    box-shadow: 0 20px 32px rgba(39, 44, 50, 0.25);
  }
`;

export const TextBox = styled(Box)`
  word-wrap: break-word;
`;

export const ButtonWrapper = styled(Button)`
  @media print {
    display: none;
  }
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: column;
  color: ${theme.color.gray[700]};
  margin: 0 ${theme.spacing.lg};
  font-size: ${theme.font.size.body};
  font-style: italic;
`;
