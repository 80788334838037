/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ContractAttachmentListItemDTO } from "../models/ContractAttachmentListItemDTO";
import type { ContractCreatedDto } from "../models/ContractCreatedDto";
import type { ContractNameDto } from "../models/ContractNameDto";
import type { ContractRelationDto } from "../models/ContractRelationDto";

import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";

export class ContractAttachmentService {
  /**
   * Get linkable contracts for main contract
   * Contracts are linkable if they are main contracts and don't have other contracts linked to them
   * @param teamId
   * @returns ContractNameDto
   * @throws ApiError
   */
  public static getLinkableContracts(
    teamId: string
  ): CancelablePromise<Array<ContractNameDto>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/contract-attachment/linkable-contracts",
      query: {
        teamId: teamId,
      },
    });
  }

  /**
   * Link a contract to a main contract as an attachment
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public static linkContract(
    requestBody: ContractRelationDto
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/contract-attachment/link-contract",
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * Unlink an attachment to make it into a main contract
   * @param contractId
   * @returns any
   * @throws ApiError
   */
  public static unlinkContract(contractId: string): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/contract-attachment/unlink-contract",
      query: {
        contractId: contractId,
      },
    });
  }

  /**
   * Swap a main contract with an attachment
   * The main contract will become an attachment and the attachment will become the main contract of the group
   * @param contractId
   * @returns any
   * @throws ApiError
   */
  public static swapMainContract(contractId: string): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/contract-attachment/swap-contracts",
      query: {
        contractId: contractId,
      },
    });
  }

  /**
   * Create a contract with attachment
   * Upload a file and create a contract.
   * @param skipAnalysis
   * @param teamId
   * @param formData
   * @returns ContractCreatedDto
   * @throws ApiError
   */
  public static createContract(
    skipAnalysis: boolean,
    teamId: string,
    formData: {
      file?: Blob;
      categoryId?: string;
      parentId?: string;
    }
  ): CancelablePromise<ContractCreatedDto> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/contract-attachment/create",
      query: {
        skipAnalysis: skipAnalysis,
        teamId: teamId,
      },
      formData: formData,
      mediaType: "multipart/form-data",
    });
  }

  /**
   * Get contracts of group by main contract id
   * @param contractId
   * @returns ContractAttachmentListItemDTO
   * @throws ApiError
   */
  public static getContractsOfGroup(
    contractId: string
  ): CancelablePromise<Array<ContractAttachmentListItemDTO>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/contract-attachment/contracts-of-group",
      query: {
        contractId: contractId,
      },
    });
  }
}
