import { StatisticsViewMode, StatisticsViewModeType } from "./Dashboard";

export const getSelectedData = <T>(
  statisticsViewMode: StatisticsViewModeType,
  overviewData?: { data: T; sumData: T }
) => {
  if (statisticsViewMode === StatisticsViewMode.SHOW_TOTAL) {
    return overviewData?.sumData;
  }

  return overviewData?.data;
};
