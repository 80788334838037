import * as React from "react";
import CardWrapper from "../../../components/CardWrapper/CardWrapper";
import CardHeaderWrapper from "../../../components/CardHeaderWrapper/CardHeaderWrapper";
import RssFeedIcon from "@mui/icons-material/RssFeed";
import CardBodyWrapper from "../../../components/CardCenteredBodyWrapper/CardCenteredBodyWrapper";
import { Box } from "@mui/material";
import ActivityItem from "./ActivityItem";
import SectionTableWrapper from "../SectionTableWrapper";
import { theme } from "theme";
import { useTranslation } from "react-i18next";
import { WidgetProps } from "../Dashboard";
import { useRecentNotificationsOverviewQuery } from "shared/api/overview";
import { useTeam } from "contexts/team/hooks";
import { getSelectedData } from "../utils";

const Activity = ({ statisticsViewMode }: WidgetProps) => {
  const { t } = useTranslation();
  const { selectedTeamId } = useTeam();
  const { data } = useRecentNotificationsOverviewQuery(selectedTeamId);
  const notifications = getSelectedData(statisticsViewMode, data);

  return (
    <CardWrapper
      isShowTopAndBottomPaddingOnly={notifications ? "true" : undefined}
      data-testid="activity-widget"
    >
      <>
        <Box
          sx={{ padding: notifications ? "0 1.25rem 1rem 1.25rem" : "none" }}
        >
          <CardHeaderWrapper
            title={t("pages.dashboard.activity.title")}
            titleStyles={{ color: theme.color.gray[600] }}
            icon={<RssFeedIcon style={{ color: theme.color.gray[600] }} />}
          />
        </Box>
        {notifications != null && notifications.length > 0 ? (
          <SectionTableWrapper maxHeight={360} scrollable>
            {notifications.map((notificationn, index) => {
              return (
                <ActivityItem key={index} index={index} data={notificationn} />
              );
            })}
          </SectionTableWrapper>
        ) : (
          <CardBodyWrapper
            textKey="pages.dashboard.activity.description"
            selectedData-testid="activity-widget-description"
          />
        )}
      </>
    </CardWrapper>
  );
};

export default Activity;
