import styled from "@emotion/styled";
import { FormTextField } from "components";
import AiIcon from "assets/svg/ai.svg?react";
import { theme } from "theme";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-lg);
  padding: var(--spacing-lg);
  position: relative;
  border-radius: calc(var(--border-radius) + 1px);
  background: var(--gradient-background);
  z-index: 1;

  :before {
    content: "";
    position: absolute;
    top: 1px;
    left: 1px;
    right: 1px;
    bottom: 1px;
    border-radius: var(--border-radius);
    background: var(--color-white);
    z-index: -1;
  }
`;

export const Heading = styled.div`
  display: flex;
  gap: var(--spacing-xs);
`;

export const HeadingIcon = styled(AiIcon)`
  flex: 0 0 auto;
  width: 22px;
  height: 22px;
  color: var(--color-base-analysis);
`;

export const HeadingText = styled.div`
  font-weight: ${theme.font.weight.semiBold};
`;

export const TextArea = styled(FormTextField)`
  margin: 0;
`;
