import styled from "@emotion/styled";
import { styled as muiStyled } from "@mui/system";
import theme from "theme/theme";

export const ToolbarButtonContainer = muiStyled("div")({
  padding: `0 ${theme.spacing.xxs}`,
});

export const PageInputContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  white-space: nowrap;
`;

export const ToolbarContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: var(--color-white);
`;

export const ToolbarRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: var(--font-family);
  width: 100%;
  padding: 0 var(--spacing-xs);
  container-type: inline-size;
  border-bottom: 1px solid ${theme.color.gray[300]};
  height: 40px;
  .toolbar-negative-collapsing-button {
    display: none;
  }
  @container (max-width: 500px) {
    .toolbar-collapsing-button {
      display: none;
    }
    .toolbar-negative-collapsing-button {
      display: block;
    }
  }
`;
