import { useEffect } from "react";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $getSelection, $isRangeSelection, $isNodeSelection } from "lexical";
import { ContractFieldDTOV1 } from "openapi";
import {
  PlaceholderTextNode,
  $isPlaceholderTextNode,
} from "../../nodes/PlaceholderTextNode";

type PlaceholderTextNodeType = {
  datapoint: ContractFieldDTOV1 & {
    visibleId: string;
    field: string;
    display: string;
  };
};

type CaretPosition = {
  offsetNode: Node;
  offset: number;
};

declare global {
  interface Document {
    caretPositionFromPoint?(x: number, y: number): CaretPosition | null;
  }
}

function TableCellDragDropPlugin(): null {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    const handleDragOver = (event: DragEvent) => {
      const target = event.target as HTMLElement;
      const cellElement = target.closest("th, td");

      if (!cellElement) return;
      event.preventDefault();

      const position = document.caretPositionFromPoint?.(
        event.clientX,
        event.clientY
      );
      if (position) {
        const selection = window.getSelection();
        selection?.removeAllRanges();
        const range = document.createRange();
        range.setStart(position.offsetNode, position.offset);
        selection?.addRange(range);
        return;
      }

      // @Note: Fallback to non-standard method
      const range = document.caretRangeFromPoint?.(
        event.clientX,
        event.clientY
      );
      if (range) {
        const selection = window.getSelection();
        selection?.removeAllRanges();
        selection?.addRange(range);
      }
    };

    const handleDrop = (event: DragEvent) => {
      const target = event.target as HTMLElement;
      const cellElement = target.closest("th, td");

      if (!cellElement) return;

      event.preventDefault();

      const data = event.dataTransfer?.getData("text/plain");

      if (!data) return;

      const {
        datapoint: { visibleId, field, display },
      } = JSON.parse(data) as PlaceholderTextNodeType;

      editor.update(() => {
        const selection = $getSelection();
        const placeholder = new PlaceholderTextNode(
          visibleId ?? "",
          field,
          display
        );

        if ($isRangeSelection(selection)) {
          selection.insertNodes([placeholder]);
        }
      });
    };

    // @Note: This makes sure that we have the same behavior as the default one.
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Backspace" || event.key === "Delete") {
        editor.update(() => {
          const selection = $getSelection();

          if ($isNodeSelection(selection)) {
            const nodes = selection.getNodes();
            const hasPlaceholder = nodes.some((node) =>
              $isPlaceholderTextNode(node)
            );

            if (hasPlaceholder) {
              selection.getNodes().forEach((node) => {
                if ($isPlaceholderTextNode(node)) {
                  node.remove();
                }
              });
              event.preventDefault();
            }
          }

          if ($isRangeSelection(selection)) {
            const anchor = selection.anchor;
            const anchorNode = anchor.getNode();

            if ($isPlaceholderTextNode(anchorNode)) {
              anchorNode.remove();
              event.preventDefault();
            }
          }
        });
      }
    };

    editor.getRootElement()?.addEventListener("dragover", handleDragOver);
    editor.getRootElement()?.addEventListener("drop", handleDrop);
    editor.getRootElement()?.addEventListener("keydown", handleKeyDown);

    return () => {
      editor.getRootElement()?.removeEventListener("dragover", handleDragOver);
      editor.getRootElement()?.removeEventListener("drop", handleDrop);
      editor.getRootElement()?.removeEventListener("keydown", handleKeyDown);
    };
  }, [editor]);

  return null;
}

export default TableCellDragDropPlugin;
