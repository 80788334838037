import styled from "@emotion/styled";
import { theme } from "theme";

export const Wrapper = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: none;
  height: fit-content;
  width: fit-content;
  cursor: pointer;
  transition: transform 0.15s ease-in-out;
  background-color: transparent;
  height: 32px;

  :hover,
  :focus {
    outline-offset: 2px;
    border-radius: ${theme.borderRadius};
  }

  :hover {
    outline: 1px solid ${theme.color.blue[600]};
  }

  :focus {
    outline: 1px solid ${theme.color.blue[900]};
  }

  .template-category {
    padding: ${theme.spacing.xs};
  }
`;

export const paperStyles = {
  backgroundColor: theme.color.white,
  padding: theme.spacing.md,
  maxHeight: "420px",
  width: "280px",
  borderRadius: theme.borderRadius,
  boxShadow: "var(--dropdown-shadow)",
};

export const PopoverContainer = styled.div``;

export const List = styled.ul`
  list-style-type: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.sm};
  max-height: 320px;
  overflow-y: scroll;
`;

export const ColorPoint = styled.span<{ color?: string }>`
  height: 0.75rem;
  width: 0.75rem;
  min-width: 0.75rem;
  border-radius: 4px;
  background-color: ${({ color }) => color || theme.color.gray[300]};
`;

export const ListItem = styled.li<{ color?: string }>`
  padding: ${theme.spacing.sm};
  border-radius: ${theme.borderRadius};
  transition: background-color 0.15s ease-in-out;
  display: flex;
  align-items: center;
  gap: ${theme.spacing.sm};

  :hover {
    background-color: ${theme.color.gray[200]};
    cursor: pointer;
  }
`;
