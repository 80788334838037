import React, { useState } from "react";
import { Box, DialogActions, Radio } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ApiError, ContractAttachmentService } from "openapi";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { useSnackbar } from "notistack";
import { ForbiddenResponse } from "shared/types/ErrorResponse.type";
import { AlertWrapper } from "components/Alert/Alert";
import { AlertTypeEnum } from "shared/enums/alert.enum";
import { useTeam } from "contexts/team/hooks";
import { CTAButton } from "components";

type Props = {
  handleClose: () => void;
  selectedContractId?: string | null;
  refetchData?: () => Promise<void>;
};

const ContractSwapModalContent = ({
  handleClose,
  selectedContractId,
  refetchData,
}: Props) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { isOwner, selectedTeam } = useTeam();

  const [selectedValue, setSelectedValue] = useState<string>("");
  const [isContractLimitReached, setIsContractLimitReached] =
    useState<boolean>(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(event.target.value);
  };

  const unlinkContract = async () => {
    if (selectedContractId) {
      try {
        await ContractAttachmentService.unlinkContract(selectedContractId);
        handleClose();
        refetchData && void refetchData();
        enqueueSnackbar(
          t("pages.contractDetails.modals.linkContract.successMessage"),
          { variant: "success" }
        );
      } catch (e) {
        if (!(e instanceof ApiError)) {
          return;
        }

        if (
          e.status === 403 &&
          (e.body as ForbiddenResponse).message === "contract limit reached"
        ) {
          setIsContractLimitReached(true);
          enqueueSnackbar(
            t(
              "pages.contractDetails.modals.swapMainContract.validation.maxContractLimit"
            ),
            {
              variant: "error",
            }
          );
        } else {
          enqueueSnackbar(
            t("pages.contractDetails.modals.linkContract.errorMessage"),
            { variant: "error" }
          );
        }
      }
    } else {
      enqueueSnackbar(t("pages.contractDetails.modals.linkContract.notFound"), {
        variant: "error",
      });
    }
  };

  const swapMainContract = async () => {
    if (selectedContractId) {
      await ContractAttachmentService.swapMainContract(selectedContractId);
      handleClose();
      refetchData && void refetchData();
      enqueueSnackbar(
        t(
          "pages.contractDetails.modals.swapMainContract.validation.successMessage"
        ),
        { variant: "success" }
      );
    } else {
      enqueueSnackbar(
        t(
          "pages.contractDetails.modals.swapMainContract.validation.errorMessage"
        ),
        { variant: "error" }
      );
    }
  };

  const onSubmit = () => {
    if (selectedValue === "swap") {
      void swapMainContract();
    } else {
      void unlinkContract();
    }
  };

  return (
    <>
      <Box>{t("pages.contractDetails.modals.swapMainContract.subtitle")}</Box>
      <form
        name="contractSwapForm"
        noValidate
        style={{
          marginTop: "1rem",
        }}
      >
        <FormControl component="fieldset">
          <RadioGroup
            name="radio-buttons-group"
            value={selectedValue}
            onChange={handleChange}
            sx={{ gap: "1rem" }}
          >
            <FormControlLabel
              sx={{ margin: 0 }}
              value="unlink"
              name="contract-unlink-radio-btn"
              control={<Radio />}
              label={
                <>
                  {t(
                    "pages.contractDetails.modals.swapMainContract.buttons.unlinkRadioButton"
                  )}
                </>
              }
            />
            <FormControlLabel
              sx={{ margin: 0 }}
              value="swap"
              name="contract-swap-radio-btn"
              control={<Radio />}
              label={
                <>
                  {t(
                    "pages.contractDetails.modals.swapMainContract.buttons.swapRadioButton"
                  )}
                </>
              }
            />
          </RadioGroup>
        </FormControl>
        {isContractLimitReached && (
          <AlertWrapper
            type={AlertTypeEnum.warning}
            role="alert"
            isLeftBorder={true}
          >
            {t(
              "pages.contractDetails.modals.swapMainContract.validation.currentContractLimit",
              {
                contractLimit: selectedTeam?.maxContracts,
              }
            )}
            {isOwner() ? (
              <Box>
                {t(
                  "pages.contractDetails.modals.swapMainContract.validation.upgradePlan"
                )}
              </Box>
            ) : (
              <Box>
                {t(
                  "pages.contractDetails.modals.swapMainContract.validation.checkPlan"
                )}
              </Box>
            )}
          </AlertWrapper>
        )}
        <DialogActions sx={{ marginTop: "2rem" }}>
          <CTAButton
            name={t(
              "pages.contractDetails.modals.swapMainContract.buttons.cancel"
            )}
            onClick={() => handleClose()}
            variant="secondary"
          />
          <CTAButton
            name={t(
              "pages.contractDetails.modals.swapMainContract.buttons.save"
            )}
            onClick={() => onSubmit()}
            disabled={!selectedValue}
          />
        </DialogActions>
      </form>
    </>
  );
};

export default ContractSwapModalContent;
