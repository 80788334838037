/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { TeamPermissionDto } from "./TeamPermissionDto";

export type TeamMemberDto = {
  id: string;
  role: TeamMemberDto.role | null;
  firstname?: string | null;
  lastname?: string | null;
  email: string;
  owner: boolean;
  categoryOwner: boolean;
  taskAutomationAdmin: boolean;
  contractTemplateAdmin: boolean;
  isSupportUser?: boolean;
  permissions: Array<TeamPermissionDto>;
};

export namespace TeamMemberDto {
  export enum role {
    USER = "USER",
    LIMITED_USER = "LIMITED_USER",
    OWNER = "OWNER",
  }
}
