import React from "react";
import { ContractDTOV1, ContractNameDto } from "openapi";
import { UploadTypeEnum } from "shared/enums/contractUpload.enum";
import UploadSingleFile from "./SingleFileUpload";
import UploadMultipleFiles from "./ContractUpload";

export type UploadMultipleFilesProps = {
  titleKey?: string;
  uploadType?: UploadTypeEnum;
  contractId?: string;
  contractData?: ContractDTOV1;
  contractGroup?: ContractNameDto[];
  handleClose?: () => void;
  setIsDragActive?: (value: boolean) => void;
};

export type UploadSingleFileProps = {
  contractId: string;
  showSelectTemplateButton: boolean;
  showImportFromTemplateModal: () => void;
  setIsLoading: (isLoading: boolean) => void;
  contractTeamId?: string;
};

type FileUploadComponentProps = {
  multiple?: boolean;
} & UploadMultipleFilesProps &
  UploadSingleFileProps;

const FileUploadComponent = ({
  multiple,
  ...props
}: FileUploadComponentProps) => {
  return multiple ? (
    <UploadMultipleFiles {...props} />
  ) : (
    <UploadSingleFile {...props} />
  );
};

export default FileUploadComponent;
