/* eslint-disable @typescript-eslint/unbound-method */
import { ToolbarProps, ToolbarSlot } from "@react-pdf-viewer/default-layout";
import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import {
  ToolbarButtonContainer,
  ToolbarRow,
  ToolbarContainer,
  PageInputContainer,
} from "./toolbar/styles";
import { OCRSearchPopoverProps } from "./search/OCRSearchPopover";
import { ToolbarButton } from "./toolbar/buttons/ToolbarButton";
import { RotateButton } from "./toolbar/buttons/RotateButton";
import { FullscreenButton } from "./toolbar/buttons/FullscreenButton";
import { ZoomOutButton } from "./toolbar/buttons/ZoomOutButton";
import { ZoomInButton } from "./toolbar/buttons/ZoomInButton";
import OCRIcon from "assets/svg/ocr-icon.svg?react";

export const getToolbarRenderFunction = (
  fileUrl: string,
  showOCR: boolean,
  hasOCR: boolean,
  onClickShowOCR: () => void,
  OCRSearchPopover: (
    props: Omit<OCRSearchPopoverProps, "store" | "onSearchChanged">
  ) => ReactElement
) => {
  return function RenderToolbar(
    Toolbar: (props: ToolbarProps) => ReactElement
  ) {
    const { t } = useTranslation();
    return (
      <Toolbar>
        {(slots: ToolbarSlot) => {
          const {
            CurrentPageInput,
            EnterFullScreen,
            NumberOfPages,
            Zoom,
            ZoomIn,
            Rotate,
            ZoomOut,
          } = slots;

          return (
            <ToolbarContainer>
              <ToolbarRow>
                {hasOCR && (
                  <>
                    <ToolbarButton
                      onClick={() => {
                        onClickShowOCR();
                      }}
                      tooltip={t("pdfViewer.toolbar.displayOCR")}
                      highlighted={showOCR}
                      iconSize="medium"
                    >
                      <OCRIcon />
                    </ToolbarButton>
                    <ToolbarButtonContainer>
                      <OCRSearchPopover
                        ocrEnabled={showOCR}
                        enableOCR={onClickShowOCR}
                      />
                    </ToolbarButtonContainer>
                  </>
                )}
                <ToolbarButtonContainer>
                  <PageInputContainer>
                    <CurrentPageInput /> / <NumberOfPages />
                  </PageInputContainer>
                </ToolbarButtonContainer>
                <ZoomOutButton Slot={ZoomOut} />
                <ToolbarButtonContainer>
                  <Zoom />
                </ToolbarButtonContainer>
                <ZoomInButton Slot={ZoomIn} />
                <RotateButton Slot={Rotate} />
                <FullscreenButton fileUrl={fileUrl} Slot={EnterFullScreen} />
              </ToolbarRow>
            </ToolbarContainer>
          );
        }}
      </Toolbar>
    );
  };
};
