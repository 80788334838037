import styled from "@emotion/styled";
import { theme } from "theme";

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: ${theme.spacing.md};
  padding-left: var(--spacing-md);

  &:before {
    content: "";
    position: absolute;
    height: calc(100% + var(--spacing-lg));
    width: 1px;
    top: 50%;
    left: 0;
    background-color: ${theme.color.gray[300]};
    transform: translateY(-50%);

    @media (max-width: 1200px) {
      display: none;
    }
  }

  @media (min-width: 768px) {
    justify-content: flex-start;
  }
`;
