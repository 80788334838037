import styled from "@emotion/styled";
import { IconButton as MUIIconButton } from "@mui/material";
import theme from "theme/theme";

// Section
export const SectionHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${theme.color.gray[600]};

  svg {
    color: ${theme.color.gray[600]};
  }
`;

export const SectionTitle = styled.h4`
  font-size: ${theme.font.size.subheading};
  font-weight: ${theme.font.weight.medium};
  margin: 0;
`;

// Field
export const DraggableFieldContainer = styled.div<{
  disabled?: boolean;
  overlay?: boolean;
}>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${theme.color.white};
  border: 1px solid ${theme.color.gray[200]};
  border-radius: ${theme.borderRadius};
  padding: 0 ${theme.spacing.xs};
  cursor: default;
  padding: ${theme.spacing.sm};
  box-sizing: border-box;
  transition: transform 0.25s ease-in-out, background-color 0.25s ease-in-out,
    color 0.25s ease-in-out;

  span:not(.MuiTouchRipple-root) {
    min-width: 80px;
    flex: 1;
    color: ${theme.color.blue[600]};
    transition: color 0.25s ease-in-out;
  }

  svg {
    color: ${theme.color.blue[600]};
  }

  :hover span {
    cursor: pointer;
  }

  :hover span,
  :hover svg {
    color: ${theme.color.gray[600]};
  }

  :hover {
    color: ${theme.color.gray[400]};
    background-color: ${theme.color.gray[200]};

    ${({ overlay }) =>
      overlay &&
      `
      border: 1px solid ${theme.color.gray[400]};
      color: ${theme.color.gray[400]};
      background-color: ${theme.color.gray[200]};
    `}
  }

  ${({ overlay }) =>
    overlay &&
    `
    color: ${theme.color.gray[400]};
    cursor: grabbing;

    svg {
      display: none;
    }
  `}

  ${({ disabled }) =>
    disabled &&
    `
    cursor: default;
    background-color: ${theme.color.gray[100]};

    span:not(.MuiTouchRipple-root) {
      color: ${theme.color.gray[600]};
    }

    :hover {
      background-color: ${theme.color.gray[100]};

      svg {
        color: ${theme.color.blue[600]};
      }
    }

    :hover span {
      cursor: default;
      color: ${theme.color.gray[600]};
    }
  `}
`;

export const DraggableArea = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const IconButton = styled(MUIIconButton)<{ draggable?: boolean }>`
  padding: 0;
`;

export const SortableFieldContainer = styled.div<{
  action?: "drag" | "delete" | null;
  delete?: boolean;
}>`
  display: flex;
  flex-direction: column;
  border-radius: ${theme.borderRadius};
  padding: ${theme.spacing.sm};
  transition: box-shadow 0.3s ease-in-out, background-color 0.3s ease-in-out;

  :hover {
    :first-child {
      svg {
        opacity: 1;
      }
    }
  }

  ${({ action }) =>
    action &&
    `
    background-color: ${
      action === "drag" ? theme.color.gray[200] : theme.color.red[200]
    } !important;
  `}
`;

export const SortableFieldItem = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;
  gap: ${theme.spacing.lg};
  padding: ${theme.spacing.xs};

  & > span {
    flex: 1;
    display: flex;
    align-items: flex-start;
    gap: ${theme.spacing.lg};
    min-width: 0;
  }

  svg {
    transition: opacity 0.3s ease-in-out;
    opacity: 0;
  }

  svg:nth-of-type(2) {
    background: ${theme.color.red[400]};
  }

  :hover {
    border-radius: ${theme.borderRadius};
  }

  ${({ active }: { active?: boolean }) => active && ``}
`;

export const SortableFieldLabel = styled.span`
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${theme.color.gray[700]};
  font-weight: ${theme.font.weight.semiBold};
`;

export const SortableFieldValue = styled.span`
  display: flex;
  margin-left: calc(${theme.spacing.lg} + 24px);
  width: 100%;
  padding: 0 ${theme.spacing.xs};
  color: ${theme.color.gray[800]};

  ${({ active }: { active?: boolean }) => active && ``}
`;

export const Remove = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  :hover {
    color: ${theme.color.red[400]};
  }
`;

export const MockFieldsGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.lg};
  width: 100%;
`;

export const MockFieldRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  .node {
    width: 50%;
  }
`;

export const Title = styled.span<{ grabbing?: boolean }>`
  display: flex;
  width: 100%;
  cursor: ${({ grabbing }) => (grabbing ? "grab" : "default")};
`;

export const Container = styled.div<{ dragging?: boolean }>`
  flex-basis: 100%;
  opacity: ${({ dragging }) => (dragging ? 0 : 1)};

  :hover .chevronIcon {
    fill: currentColor;
  }
`;

export const TagIndicators = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: ${theme.spacing.xs};
  margin: 0 ${theme.spacing.sm};
`;
