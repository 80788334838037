import styled from "@emotion/styled";
import { theme } from "theme";

export const Wrapper = styled.div<{
  $hoverEffect: boolean;
  $active: boolean;
  $isDraggable: boolean;
  $isClickable?: boolean;
}>`
  display: flex;
  cursor: ${({ $isClickable }) => ($isClickable ? "pointer" : "default")};
  position: relative;
  align-items: center;
  border-bottom: 1px solid ${theme.color.gray[300]};
  padding: ${theme.spacing.md} ${theme.spacing.xl} ${theme.spacing.md} 0;
  background-color: ${({ $active }) =>
    $active ? theme.color.blue[100] : "transparent"};
  transition: background-color 0.25s;
  ${({ $hoverEffect, $isDraggable }) =>
    $hoverEffect &&
    `
  &:hover {
    background-color: ${theme.color.blue[100]}90;
    .document-buttons {
      opacity: 1;
      visibility: visible;
    }

    ${
      $isDraggable
        ? `.document-list-item-drag-icon {
    display: block;
  }`
        : ``
    }}
  }`}
`;

export const StyledDragIcon = styled.div`
  display: none;
  position: absolute;
  height: 100%;
`;

export const StyledIconContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 0 var(--spacing-xs) 0 0;
  gap: var(--spacing-xs);

  svg {
    fill: var(--color-secondary);
  }
`;

export const Text = styled.div`
  padding: 0 ${theme.spacing.xl};
  width: 80%;
`;

export const Title = styled.div`
  font-size: ${theme.font.size.subheading};
  margin-bottom: ${theme.spacing.sm};
`;

export const Details = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${theme.spacing.xs};
  align-items: center;
  color: var(--color-secondary);
  font-size: ${theme.font.size.caption};
`;

export const Buttons = styled.div`
  opacity: 0;
  visibility: hidden;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: ${theme.spacing.sm};
  transition: all 0.25s;
`;

export const ContentSpan = styled.div<{
  $shouldAddContentBefore?: boolean;
}>`
  position: relative;
  margin-left: ${({ $shouldAddContentBefore }) =>
    $shouldAddContentBefore ? theme.spacing.xs : "0"};
  padding-left: ${({ $shouldAddContentBefore }) =>
    $shouldAddContentBefore ? theme.spacing.sm : "0"};

  &:before {
    ${({ $shouldAddContentBefore }) =>
      $shouldAddContentBefore &&
      `
    content: "";
    position: absolute;
    width: 4px;
    left: 0;
    top: 50%;
    height: 4px;
    border-radius: 50%;
    background-color: var(--color-secondary);
    transform: translateY(-50%);
    `}
  }
`;
