import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { Heading, HeadingIcon, HeadingText, TextArea, Wrapper } from "./styles";

import { useTranslation } from "react-i18next";
import { ContractFieldDTOV1 } from "openapi";
import Tabs from "./components/Tabs/Tabs";
// import Suggestions from "./components/Suggestions/Suggestions";

export type PromptProps = {
  fieldType: ContractFieldDTOV1.type | string;
};

const Prompt = ({ fieldType }: PromptProps) => {
  const { t } = useTranslation();
  const { control, setValue } = useFormContext();
  const textAreaName = "prompt";

  const amountTabs = [
    {
      name: t("customFields.typeOptions.AMOUNT"),
      key: "prompt.amount",
      placeholder: t(
        "pages.contractEdit.modals.customField.prompt.placeholder"
      ),
    },
    {
      name: t("pages.contracts.tableColumns.currency"),
      key: "prompt.currency",
      placeholder: t(
        "pages.contractEdit.modals.customField.prompt.placeholder"
      ),
    },
  ];

  const durationTabs = [
    {
      name: t("pages.contracts.tableColumns.type"),
      key: "prompt.type",
      placeholder: t(
        "pages.contractEdit.modals.customField.prompt.placeholder"
      ),
    },
    {
      name: t("pages.contracts.tableColumns.startDate"),
      key: "prompt.startDate",
      placeholder: t(
        "pages.contractEdit.modals.customField.prompt.placeholder"
      ),
    },
    {
      name: t("pages.contracts.tableColumns.endDate"),
      key: "prompt.endDate",
      placeholder: t(
        "pages.contractEdit.modals.customField.prompt.placeholder"
      ),
    },
    {
      name: t("pages.contracts.tableColumns.durationInMonth"),
      key: "prompt.duration",
      placeholder: t(
        "pages.contractEdit.modals.customField.prompt.placeholder"
      ),
    },
    {
      name: t("pages.contracts.tableColumns.noticePeriodInMonths"),
      key: "prompt.noticePeriod",
      placeholder: t(
        "pages.contractEdit.modals.customField.prompt.placeholder"
      ),
    },
    {
      name: t("pages.contracts.tableColumns.automaticRenewal"),
      key: "prompt.autoRenewal",
      placeholder: t(
        "pages.contractEdit.modals.customField.prompt.placeholder"
      ),
    },
    {
      name: t("pages.contracts.tableColumns.terminationDate"),
      key: "prompt.terminationDate",
      placeholder: t(
        "pages.contractEdit.modals.customField.prompt.placeholder"
      ),
    },
  ];

  useEffect(() => {
    setValue(textAreaName, "");
  }, [fieldType]);

  const renderContentByFieldType = () => {
    switch (fieldType) {
      case ContractFieldDTOV1.type.AMOUNT:
        return <Tabs tabs={amountTabs} />;

      case ContractFieldDTOV1.type.DURATION:
        return <Tabs tabs={durationTabs} scrollable />;

      default:
        return (
          <TextArea
            control={control}
            name={textAreaName}
            multiline
            rows={4}
            placeholder={t(
              "pages.contractEdit.modals.customField.prompt.placeholder"
            )}
          />
        );
    }
  };

  const contentFieldType = renderContentByFieldType();

  return (
    <Wrapper>
      <Heading>
        <HeadingIcon />
        <HeadingText>
          {t("pages.contractEdit.modals.customField.prompt.heading")}
        </HeadingText>
      </Heading>
      {contentFieldType}
      {/* Hidden for now */}
      {/* <Suggestions /> */}
    </Wrapper>
  );
};

export default Prompt;
