import styled from "@emotion/styled";
import { Modal } from "@mui/material";

export const ReviewModal = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ReviewIFrame = styled.iframe`
  height: 90%;
  width: 80%;
`;

export const EmailContainer = styled.div`
  display: grid;
  grid-template-columns: ${({
    isVerificationPending,
  }: {
    isVerificationPending: boolean;
  }) => (isVerificationPending ? "3fr 1fr" : "4fr")};
  gap: var(--spacing-xl);
  align-items: center;
`;
