import { TFunction } from "i18next";
import {
  SortDirection,
  ValueFormatterParams,
  ValueGetterParams,
} from "ag-grid-community";
import { Language } from "shared/i18n/i18n";
import { getDateColumnDefinition } from "table/ColumnDefinitions/DateColumnDefinition.config";
import {
  TitleCellRenderer,
  AssigneesCellRenderer,
  StatusCellRenderer,
} from "./cellRenderers";
import ContractCellRenderer from "./cellRenderers/ContractCellRenderer/ContractCellRenderer";
import { TaskNewDTO, TeamDescriptionDto } from "../../../../openapi";
import { ExtendedTaskData } from "./helpers";
import DueDateCellRenderer from "./cellRenderers/DueDateCellRenderer/DueDateCellRenderer";
import ReminderDateCellRenderer from "./cellRenderers/ReminderDateCellRenderer/ReminderDateCellRenderer";

export const getColumnDataConfig = (
  t: TFunction,
  locale: Language,
  teamById: (id: string) => TeamDescriptionDto | null,
  userDateFormat: string
) => {
  const dateColumnDefinition = getDateColumnDefinition(locale, userDateFormat);

  return [
    {
      headerName: t("pages.tasks.table.columnNames.title"),
      field: "title",
      cellRenderer: TitleCellRenderer,
      sortable: true,
      filter: "agTextColumnFilter",
      minWidth: 200,
    },
    {
      headerName: t("pages.tasks.table.columnNames.linkedContract"),
      field: "contract",
      cellRenderer: ContractCellRenderer,
      sortable: true,
      filter: true,
    },
    {
      headerName: t("common.status"),
      field: "status",
      cellRenderer: StatusCellRenderer,
      sortable: true,
      filter: true,
      filterParams: {
        valueFormatter: (params: ValueFormatterParams) => {
          return t(`pages.tasks.status.options.${params?.value as string}`);
        },
      },
    },
    {
      headerName: t("pages.tasks.table.columnNames.assignees"),
      field: "assignees",
      cellRenderer: AssigneesCellRenderer,
      sortable: true,
      filter: true,
      filterValueGetter: (params: ValueGetterParams) => {
        return (params.data as ExtendedTaskData).assignees?.map(
          (item) => `${item.firstname ?? ""} ${item.lastname ?? ""}`
        );
      },
    },
    {
      headerName: t("pages.tasks.table.columnNames.dueDate"),
      field: "dueDate",
      cellRenderer: DueDateCellRenderer,
      sortable: true,
      filter: true,
      ...dateColumnDefinition,
    },
    {
      headerName: t("pages.tasks.table.columnNames.reminderAt"),
      field: "reminderAt",
      cellRenderer: ReminderDateCellRenderer,
      sortable: true,
      filter: true,
      ...dateColumnDefinition,
    },
    {
      headerName: t("pages.tasks.table.columnNames.createdAt"),
      field: "createdAt",
      sortable: true,
      filter: true,
      sort: "desc" as SortDirection,
      hide: true,
      ...dateColumnDefinition,
    },
    {
      headerName: t("pages.tasks.table.columnNames.team"),
      field: "teamId",
      valueGetter: (params: ValueGetterParams) => {
        const result = teamById((params.data as TaskNewDTO).teamId);
        return result ? result.name : "–";
      },
      sortable: true,
      filter: true,
    },
  ];
};
