// Same styles for Contracts and Contacts

import styled from "@emotion/styled";
import { theme } from "theme";

export const SIDEBAR_WIDTH = "56px";

type ListItemProps = {
  active?: boolean;
  visible?: boolean;
  color?: string;
  backgroundColor?: string;
};

type ListProps = {
  visible?: boolean;
};

export const Wrapper = styled.div`
  width: ${SIDEBAR_WIDTH};
  background: ${theme.color.white};
  border-left: 1px solid ${theme.color.gray[200]};
`;

export const List = styled.ul<ListProps>`
  list-style-type: none;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transition: transform 0.2s ease-in-out;
  padding: ${theme.spacing.lg} 0 0;
  margin: 0;
  padding: 0;
`;

export const ListItem = styled.li<ListItemProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: var(--spacing-xs);
  width: 100%;
  height: 56px;
  cursor: pointer;
  position: relative;

  &:after {
    position: absolute;
    content: "";
    width: 2px;
    height: 100%;
    right: 0;
    top: 50%;
    background: transparent;
    transition: all 0.2s ease-in-out;
    transform: translateY(-50%);
  }

  &:before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    right: 0;
    top: 0;
    background: transparent;
    opacity: 0.1;
    transition: all 0.2s ease-in-out;
    transform-origin: right center;
  }

  &:hover:before {
    background: ${(props) => props.color || "var(--color-primary)"};
    border-right-color: ${(props) => props.color || "var(--color-primary)"};
  }

  ${({ active, color }) =>
    active &&
    `
    &:before, &:after {
      background-color: ${color || "var(--color-primary)"};
    }
    
    .list-item-name {
      font-weight: ${theme.font.weight.semiBold};
    }
  `}
`;

export const IconWrapper = styled.div<{ color: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    width: 20px;
    height: 20px;
    fill: ${(props) => props.color || "var(--color-primary)"};
    transition: all 0.25s;
  }
`;

export const Name = styled.div<{ color: string }>`
  font-size: 9.5px;
  color: ${(props) => props.color || "var(--color-primary)"};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 96%;
  text-align: center;
`;

export const ToggleMenu = styled.div`
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: ${theme.spacing.xs};
  cursor: pointer;
  color: ${theme.color.gray[600]};
  background-color: ${theme.color.gray[300]};
  transition: transform 0.15s ease-in-out;
  box-shadow: ${theme.shadow.standard};

  :active {
    transform: scale(0.95);
  }
`;
