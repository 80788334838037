import {
  AmountFieldTypeDtoV1,
  ContactFieldTypeDTOV1,
  ContractFieldDTOV1,
  CountryFieldTypeDtoV1,
  DateFieldTypeDtoV1,
  DurationFieldTypeDtoV1,
  FormulaFieldTypeDtoV1,
  LinkFieldTypeDtoV1,
  ListFieldTypeDtoV1,
  MultiLineTextFieldTypeDtoV1,
  NumberFieldTypeDtoV1,
  SingleLineTextFieldTypeDtoV1,
} from "openapi";
import { getFieldsByType } from "utils/helpers";

export type contractFieldType = Record<
  string,
  | AmountFieldTypeDtoV1
  | CountryFieldTypeDtoV1
  | DateFieldTypeDtoV1
  | LinkFieldTypeDtoV1
  | ListFieldTypeDtoV1
  | MultiLineTextFieldTypeDtoV1
  | NumberFieldTypeDtoV1
  | SingleLineTextFieldTypeDtoV1
  | FormulaFieldTypeDtoV1
  | DurationFieldTypeDtoV1
  | ContactFieldTypeDTOV1
>;

export function isValidKey<T extends object>(
  key: string | number | symbol,
  obj: T
): key is keyof T {
  return key in obj;
}

export function getFieldsCount(
  contractFields: contractFieldType,
  datapoints: ContractFieldDTOV1[]
) {
  if (!contractFields || !datapoints) return;

  const combinedFields: Record<string, ContractFieldDTOV1.type> = {};
  datapoints?.forEach((datapoint) => {
    if (
      [
        ContractFieldDTOV1.type.DURATION,
        ContractFieldDTOV1.type.AMOUNT,
      ].includes(datapoint.type)
    )
      combinedFields[datapoint.id] = datapoint.type;
  });

  let filledFieldsCount = 0;
  const defaultKey = "value";

  Object.entries(contractFields).forEach(([fieldId, fieldData]) => {
    const isDeafaultKeyExistsAndValid =
      isValidKey(defaultKey, fieldData) && Boolean(fieldData[defaultKey]);

    if (combinedFields[fieldId] === ContractFieldDTOV1.type.DURATION) {
      const valueFieldNames = getFieldsByType(combinedFields[fieldId]);

      valueFieldNames.forEach((fieldName) => {
        if (isValidKey(fieldName, fieldData) && Boolean(fieldData[fieldName]))
          filledFieldsCount++;
      });
      return;
    }
    if (combinedFields[fieldId] === ContractFieldDTOV1.type.AMOUNT) {
      const currencyField = "currency";
      if (isDeafaultKeyExistsAndValid) {
        isValidKey(currencyField, fieldData) &&
        Boolean(fieldData[currencyField]) // Check if 'currency' field exists and has some value
          ? (filledFieldsCount = filledFieldsCount + 2) // if 'currency' and 'value' are filled add 2
          : filledFieldsCount++; // if there is 'value' value only, add 1
      }
      return;
    }
    if (isDeafaultKeyExistsAndValid) filledFieldsCount++;
  });

  return filledFieldsCount;
}
