import styled from "@emotion/styled";
import { theme } from "theme";

export const Wrapper = styled.div`
  background-color: ${theme.color.gray[100]};
  border: 1px solid ${theme.color.gray[300]};
  border-radius: var(--border-radius);
  padding: var(--spacing-xxl);
  width: calc(100% - var(--spacing-xxl));
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: var(--spacing-lg) auto;
`;

export const Icon = styled.div`
  margin-bottom: var(--spacing-lg);
`;

export const Text = styled.p`
  font-size: ${theme.font.weight.semiBold};
  color: var(--color-secondary);
  margin: 0 0 var(--spacing-xxl);
`;
