import { ProfileDto, ProfileService } from "openapi";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

const queryKey = ["profile"];

export const useProfileQuery = () => {
  return useQuery({ queryKey, queryFn: () => ProfileService.getProfile() });
};

export const useProfileUpdateMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: ProfileDto) => {
      return ProfileService.updateProfile(data);
    },
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey });
    },
  });
};

export const useVerifyEmailMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (token: string) => {
      return ProfileService.verifyEmail(token);
    },
    onSuccess: () => {
      void queryClient.invalidateQueries({
        queryKey,
      });
    },
    onError: () => {
      void queryClient.invalidateQueries({
        queryKey,
      });
    },
  });
};
