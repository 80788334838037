import React, { useState } from "react";
import { DropdownWrapper, DropdownMenu } from "./styles";
import { useOutsideClick } from "hooks";
import { ContractAttachmentListItemDTO, ContractDTOV1 } from "openapi";
import { CTAButton, NewModal } from "components";
import Plus from "assets/svg/plus.svg?react";
import { theme } from "theme";
import ContractUpload from "components/ContractUpload/ContractUpload";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { useParams } from "react-router";
import { useTranslation } from "react-i18next";
import { UploadTypeEnum } from "shared/enums/contractUpload.enum";
import ContractLinkModalContent from "pages/Contract/Modals/LinkContract";
import AttachmentIcon from "assets/svg/attachment-icon.svg?react";
import ContractLinkIcon from "assets/svg/contract-link-icon.svg?react";
import { Tooltip } from "@mui/material";

type DropdownProps = {
  contractGroup: ContractAttachmentListItemDTO[];
  contractData?: ContractDTOV1;
  fetchPdf: (loadFromStash?: boolean | undefined) => Promise<void>;
  fetchData: (loadFromStash?: boolean | undefined) => Promise<void>;
};

const Dropdown = ({
  contractGroup,
  contractData,
  fetchPdf,
  fetchData,
}: DropdownProps) => {
  const [isDropdownOpened, setIsDropdownOpened] = useState(false);
  const { id } = useParams();
  const { t } = useTranslation();
  const [showAddRelatedModal, setShowAddRelatedModal] = useState(false);
  const [isDragActive, setIsDragActive] = useState(false);
  const [showLinkContractModal, setShowLinkContractModal] = useState(false);

  const dropdownRef = useOutsideClick(() => {
    if (isDropdownOpened) {
      setIsDropdownOpened(false);
    }
  });

  const handleAddRelated = () => setShowAddRelatedModal(true);
  const handleRelatedClose = () => {
    setShowAddRelatedModal(false);
    void fetchData();
  };

  const handleContractModalToLink = () => setShowLinkContractModal(true);
  const handleContractModalToLinkClose = () => setShowLinkContractModal(false);

  return (
    <>
      <DropdownWrapper ref={dropdownRef}>
        <Tooltip title={t("pages.contractDetails.buttons.addRelated")}>
          <CTAButton
            label="toggle-related-menu-button"
            icon={<Plus />}
            onClick={() => setIsDropdownOpened(!isDropdownOpened)}
            size="tiny"
            variant="tertiary"
          />
        </Tooltip>
        {!!isDropdownOpened && (
          <DropdownMenu>
            <CTAButton
              variant="tertiary"
              icon={<AttachmentIcon />}
              iconSize="medium"
              onClick={handleAddRelated}
              data-cy="addAttachment"
              label="addAttachment"
              name={t("pages.contractDetails.buttons.addAttachments")}
              color="gray"
            />
            <CTAButton
              variant="tertiary"
              icon={<ContractLinkIcon />}
              iconSize="medium"
              onClick={handleContractModalToLink}
              label="contractLinkBtn"
              name={t("pages.contractDetails.buttons.linkExistingContracts")}
              color="gray"
            />
          </DropdownMenu>
        )}
      </DropdownWrapper>

      <NewModal
        open={showAddRelatedModal}
        handleClose={handleRelatedClose}
        icon={<AttachFileIcon fontSize="small" sx={{ marginRight: ".4rem" }} />}
        title={t("pages.contractDetails.modals.addAttachments.title")}
        headerProps={{
          style: {
            transition: `background-color 0.25s ease-in-out`,
            backgroundColor: isDragActive ? `${theme.color.blue[200]}80` : "",
          },
        }}
        body={
          <ContractUpload
            contractId={id}
            contractData={contractData}
            contractGroup={contractGroup}
            handleClose={handleRelatedClose}
            uploadType={UploadTypeEnum.ATTACHMENT_CONTRACT}
            setIsDragActive={setIsDragActive}
          />
        }
        bodyProps={{
          noPadding: true,
        }}
        fullWidth
        disableBackdropClick
      />

      <NewModal
        open={showLinkContractModal}
        handleClose={handleContractModalToLinkClose}
        title={t("pages.contractDetails.modals.linkContract.title")}
        body={
          <ContractLinkModalContent
            handleClose={handleContractModalToLinkClose}
            selectedContract={contractData}
            contractGroup={contractGroup}
            refetchData={fetchData}
          />
        }
      />
    </>
  );
};

export default Dropdown;
