/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { AmountFieldTypeDtoV1 } from "./AmountFieldTypeDtoV1";
import type { ContactFieldTypeDTOV1 } from "./ContactFieldTypeDTOV1";
import type { CountryFieldTypeDtoV1 } from "./CountryFieldTypeDtoV1";
import type { DateFieldTypeDtoV1 } from "./DateFieldTypeDtoV1";
import type { DurationFieldTypeDtoV1 } from "./DurationFieldTypeDtoV1";
import type { FormulaFieldTypeDtoV1 } from "./FormulaFieldTypeDtoV1";
import type { LinkFieldTypeDtoV1 } from "./LinkFieldTypeDtoV1";
import type { ListFieldTypeDtoV1 } from "./ListFieldTypeDtoV1";
import type { MultiLineTextFieldTypeDtoV1 } from "./MultiLineTextFieldTypeDtoV1";
import type { NumberFieldTypeDtoV1 } from "./NumberFieldTypeDtoV1";
import type { SingleLineTextFieldTypeDtoV1 } from "./SingleLineTextFieldTypeDtoV1";

export type ContractInputDTOV1 = {
  id?: string;
  name?: string;
  type?: ContractInputDTOV1.type;
  teamId?: string;
  categoryId?: string;
  status?: ContractInputDTOV1.status;
  parentId?: string | null;
  fields?: Record<
    string,
    | AmountFieldTypeDtoV1
    | CountryFieldTypeDtoV1
    | DateFieldTypeDtoV1
    | LinkFieldTypeDtoV1
    | ListFieldTypeDtoV1
    | MultiLineTextFieldTypeDtoV1
    | NumberFieldTypeDtoV1
    | SingleLineTextFieldTypeDtoV1
    | FormulaFieldTypeDtoV1
    | DurationFieldTypeDtoV1
    | ContactFieldTypeDTOV1
  >;
  tags?: string;
};

export namespace ContractInputDTOV1 {
  export enum type {
    MAIN_CONTRACT = "MAIN_CONTRACT",
    ATTACHMENT = "ATTACHMENT",
  }

  export enum status {
    DRAFT = "DRAFT",
    SIGNING = "SIGNING",
    PROCESSING = "PROCESSING",
    ACTIVE = "ACTIVE",
    INACTIVE = "INACTIVE",
    CANCELLED = "CANCELLED",
    TERMINATED = "TERMINATED",
  }
}
