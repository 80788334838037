import styled from "@emotion/styled";
import { Store } from "@react-pdf-viewer/core";
import React, {
  FC,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import type { Block } from "aws-sdk/clients/textract";
import { theme } from "theme";
import { partitionBlocks } from "@contracthero/common";
import * as fontkit from "fontkit";
import arialUrl from "./assets/Arial.ttf?url";
import * as Sentry from "@sentry/react";
import {
  ActiveOCRItemData,
  OCRSearchStoreProps,
} from "./internal/search/types";
import { Box } from "@mui/material";
import Tag from "new-components/Tag";
import { useTranslation } from "react-i18next";

export type OCRLayerProps = {
  pageIndex: number;
  blocks: Block[];
  width: number;
  height: number;
  searchRegex?: RegExp;
  rotationDelta: number;
  store: Store<OCRSearchStoreProps>;
  handleSelection?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
};

const OCRLayerContainer = styled.div<{
  rotation: number;
  width: number;
  height: number;
}>`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  line-height: 1;
  z-index: 2;

  ${(p) => {
    if (p.rotation % 180 === 0) {
      return `
        transform: rotate(${p.rotation}deg);
      `;
    } else {
      const translateValue = Math.abs(p.height - p.width) / 2;
      return `
        transform: rotate(${p.rotation}deg) translate(${translateValue}px, -${translateValue}px);
      `;
    }
  }}
`;

const OCRLayerTextDiv = styled.div<{
  x: number;
  y: number;
  width: number;
  height: number;
}>`
  position: absolute;
  transform-origin: 0% 0%;
  left: ${(p) => p.x}px;
  top: ${(p) => p.y}px;
  width: ${(p) => p.width}px;
  height: ${(p) => p.height}px;
  font-size: ${(p) => p.height}px;
  color: black;
  background-color: white;
  pointer-events: auto;
`;

const OCRLayerTextSpan = styled.div<{
  fontSize: number;
  wordSpacing: number | null;
}>`
  display: inline-block;
  cursor: text;
  white-space: pre;
  font-size: ${(p) => p.fontSize}px;
  font-family: sans-serif, Arial;

  vertical-align: top;

  ${(p) => (p.wordSpacing ? `word-spacing: ${p.wordSpacing}px;` : null)}
  word-spacing: ${(p) => p.wordSpacing}px;

  & > #active-occurance {
    background-color: ${theme.color.orange[500]};
  }
`;

const OCRLayerText: FC<{
  id: string;
  groupIndex: number;
  blockIndex: number;
  x: number;
  y: number;
  width: number;
  height: number;
  children: string;
  searchRegex?: RegExp;
  font: fontkit.Font;
}> = ({
  id,
  groupIndex,
  blockIndex,
  x,
  y,
  width,
  height,
  children,
  searchRegex,
  font,
}) => {
  const divRef = useRef<HTMLDivElement>(null);
  const spanRef = useRef<HTMLDivElement>(null);
  const [adjustedHeight, setAdjustedHeight] = useState(height);
  const [wordSpacing, setWordSpacing] = useState<number | null>(null);

  useLayoutEffect(() => {
    if (spanRef.current && divRef.current) {
      const divWidth = divRef.current.clientWidth;

      // information about chrome rendering from here: https://stackoverflow.com/questions/43140096/reproduce-bounding-box-of-text-in-browsers

      // calculate font height, by subtracting ascent and descent
      const fontHeight = font.ascent - font.descent;

      // since some fonts have specified units-per-em (abstract square which takes up height space when rendering a character)
      // we need to calculate that into the lineHeight
      const lineHeight =
        fontHeight > font.unitsPerEm ? fontHeight : fontHeight + font.lineGap;

      //
      let fontSize = height / (lineHeight / font.unitsPerEm);

      // use fontkit to calculate a width estimate the rendered text will take up
      // we later use this textWidthBase to adjust the font-height so it fits in the
      // bounding box we get back from OCRing the document
      const textWidthBase =
        font
          .layout(children)
          .glyphs.reduce((last, curr) => last + curr.advanceWidth, 0) /
        font.unitsPerEm;
      let textWidth = textWidthBase * fontSize;

      // epsilon comparison, so we just ignore slight deviations
      const widthTolerance = 0.01;

      // adjust font size so it fits in the OCRed bounding box
      while (textWidth - divWidth > widthTolerance) {
        fontSize -= 0.005;
        textWidth = textWidthBase * fontSize;
      }
      setAdjustedHeight(fontSize);

      const wordCount = children.split(" ").length;
      const wordSpacing =
        divWidth > textWidth ? (divWidth - textWidth) / wordCount : null;
      setWordSpacing(wordSpacing);
    }
  }, [children, font, height]);

  if (!font) {
    return null;
  }

  return (
    <OCRLayerTextDiv ref={divRef} x={x} y={y} width={width} height={height}>
      <OCRLayerTextSpan
        data-block-id={id}
        data-group-index={groupIndex}
        data-block-index={blockIndex}
        ref={spanRef}
        className="ocr-layer-text-container"
        fontSize={adjustedHeight}
        wordSpacing={wordSpacing}
      >
        {searchRegex
          ? children.split(searchRegex).map((part, i) =>
              part.match(searchRegex) ? (
                <mark key={i} data-block-id={id} data-part-index={i}>
                  {part}
                </mark>
              ) : (
                <span key={i}>{part}</span>
              )
            )
          : children}
      </OCRLayerTextSpan>
      <br />
    </OCRLayerTextDiv>
  );
};

export const OCRLayer: FC<OCRLayerProps> = ({
  pageIndex,
  blocks,
  width,
  height,
  searchRegex,
  rotationDelta,
  handleSelection,
  store,
}) => {
  const { t } = useTranslation();
  const divRef = useRef<HTMLDivElement>(null);
  const [font, setFont] = useState<fontkit.Font>();
  const originalWidth = rotationDelta % 180 === 0 ? width : height;
  const originalHeight = rotationDelta % 180 === 0 ? height : width;

  const partitionedBlocks = useMemo(() => partitionBlocks(blocks), [blocks]);

  const [activeAnalysisItem, setActiveAnalysisItem] =
    useState<ActiveOCRItemData | null>(store.get("activeAnalysisItem") ?? null);

  useEffect(() => {
    const handler = (value: ActiveOCRItemData) => {
      setActiveAnalysisItem(value);
    };
    store.subscribe("activeAnalysisItem", handler);
    return () => {
      store.unsubscribe("activeAnalysisItem", handler);
    };
  }, []);

  function onMouseUp(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    if (handleSelection) {
      e.stopPropagation();
      handleSelection(e);
    }
  }

  const fetchFont = (retry = 0) => {
    void fetch(arialUrl).then((res) => {
      if (res.status !== 200) {
        if (retry > 3) {
          return;
        }
        fetchFont(retry + 1);
        return;
      }
      res
        .arrayBuffer()
        .then((ab) => {
          const font = fontkit.create(new Uint8Array(ab) as Buffer);
          setFont(font);
        })
        .catch((e) => Sentry.captureException(e));
    });
  };

  useEffect(() => {
    fetchFont();
  }, []);

  if (!font) {
    return null;
  }

  return (
    <OCRLayerContainer
      width={originalWidth}
      height={originalHeight}
      rotation={rotationDelta}
      ref={divRef}
      onMouseUp={onMouseUp}
    >
      {partitionedBlocks.map((partition, idx) => {
        return (
          <div
            key={idx}
            id="rpv-ocr_layer"
            style={{
              position: "absolute",
              left: `${partition.left * originalWidth}px`,
              top: `${partition.top * originalHeight}px`,
              width: `${(partition.right - partition.left) * originalWidth}px`,
              height: `${
                (partition.bottom - partition.top) * originalHeight
              }px`,
              pointerEvents: "none",
            }}
          >
            {pageIndex + 1 === activeAnalysisItem?.page &&
            idx === activeAnalysisItem?.groupIdx ? (
              <Box
                sx={{
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  borderRadius: theme.borderRadius,
                  outline: "1px solid #364FA6",
                  zIndex: 5,
                  backgroundColor: "#F7F2FF4D",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    position: "absolute",
                    justifyContent: "flex-end",
                    bottom: "calc(100% + 5px)",
                    width: "100%",
                  }}
                >
                  <Tag variant="search-doc">
                    <span
                      style={{ backgroundColor: "#ECEFFA", color: "#364FA6" }}
                    >
                      {t(
                        `pages.import.labels.${activeAnalysisItem.visibleId}${
                          activeAnalysisItem.fieldKey !== "value"
                            ? `.${activeAnalysisItem.fieldKey}`
                            : ""
                        }`
                      )}
                    </span>
                  </Tag>
                </Box>
              </Box>
            ) : null}
            {partition.lines.map((line, lineIdx) => {
              if (
                !line.id ||
                !line.height ||
                !line.width ||
                !line.left ||
                !line.top ||
                !line.text
              ) {
                return null;
              }
              // TODO: currently its not easily possible to detect and render vertical text
              // this should be fixed with a later iteration of OCR, but for now its not really possible
              // given the capabilities of AWS Textract
              if (line.height > line.width) {
                return null;
              }
              return (
                <OCRLayerText
                  id={line.id}
                  groupIndex={idx}
                  blockIndex={lineIdx}
                  key={lineIdx}
                  x={(line.left - partition.left) * originalWidth}
                  y={(line.top - partition.top) * originalHeight}
                  width={line.width * originalWidth}
                  height={line.height * originalHeight}
                  searchRegex={searchRegex}
                  font={font}
                >
                  {line.text}
                </OCRLayerText>
              );
            })}
          </div>
        );
      })}
    </OCRLayerContainer>
  );
};
