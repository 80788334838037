import { useCallback, useEffect } from "react";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { mergeRegister } from "@lexical/utils";
import { ContractDTOV1, ContractFieldDTOV1, FormulaFieldData } from "openapi";
import { useFormContext } from "react-hook-form";
import { PlaceholderTextNode } from "../nodes/PlaceholderTextNode";
import { $getNodeByKey, $nodesOfType, $setSelection } from "lexical";
import { dateFormatter, paymentFormatter } from "constants/utils";
import { useLocale, useUserInfo } from "hooks";
import {
  evaluateForumla,
  FormulaAmountResult,
} from "components/Datapoints/FormulaDatapoint";
import { useContactsQuery } from "shared/api";
import { useTeam } from "contexts/team/hooks";
import { getLocalizedPlaceholderLabel } from "../utils";
import { useTranslation } from "react-i18next";
import { ContactDatapointDTOs } from "pages/Contacts/ContactDataDTO";

type ContractFormSyncPluginProps = {
  fields: ContractFieldDTOV1[];
};

export const ContractFormSyncPlugin = ({
  fields,
}: ContractFormSyncPluginProps) => {
  const { locale } = useLocale();
  const { t } = useTranslation();
  const { userInfo } = useUserInfo();
  const userDateFormat = userInfo?.dateFormat;
  const { watch, setValue, getValues } = useFormContext<ContractDTOV1>();
  const { parentTeamId, selectedTeamId } = useTeam();
  const { data: contacts } = useContactsQuery(parentTeamId, selectedTeamId);
  const [editor] = useLexicalComposerContext();

  const syncPlaceholderNode = useCallback(
    (nodeKey: string) => {
      editor.update(
        () => {
          // we need to unselect whatever is currently selected in the editor, otherwise the editor
          // force focuses on the editor input, which makes this unusable.
          $setSelection(null);
          const placeholder = $getNodeByKey<PlaceholderTextNode>(nodeKey);
          if (!placeholder) {
            return;
          }

          const field = fields.find(
            (field) => field.visibleId === placeholder.getSlug()
          );
          if (!field) {
            return;
          }

          let value = getValues(
            `fields.${field.id}.${placeholder.getFieldKey()}`
          ) as string;

          let textValue: string | null = null;
          // Handle different field types and format the content accordingly
          switch (field.type) {
            case ContractFieldDTOV1.type.DATE:
              textValue = dateFormatter(locale, value, userDateFormat);
              break;
            case ContractFieldDTOV1.type.DURATION:
              switch (placeholder.getFieldKey()) {
                case "startDate":
                case "endDate":
                case "terminationDate":
                  textValue = dateFormatter(locale, value, userDateFormat);
                  break;
                case "type":
                  if (value) {
                    textValue = t(
                      `pages.contractEdit.forms.durationForm.durationTypeOptions.${value}`
                    );
                  }
                  break;
                default:
                  textValue = value;
                  break;
              }
              break;
            case ContractFieldDTOV1.type.LIST:
              if (value) {
                textValue =
                  field.oldStandardField && field.visibleId
                    ? t(`enums.${field.visibleId}.${value}`)
                    : value;
              }
              break;
            case ContractFieldDTOV1.type.CONTACT: {
              const contact = contacts?.find(
                (contact) =>
                  contact.id === getValues(`fields.${field.id}.value`)
              );

              if (!contact) break;

              const contactFieldData = contact[placeholder.getFieldKey()] as
                | ContactDatapointDTOs
                | undefined;

              if (contactFieldData && contactFieldData?.value?.value) {
                textValue = contactFieldData.value?.value as string;
                value = contactFieldData.value?.value as string;
              }
              break;
            }
            case ContractFieldDTOV1.type.AMOUNT: {
              if (placeholder.getFieldKey() === "value") {
                textValue = paymentFormatter(
                  locale,
                  +value,
                  getValues(`fields.${field.id}.currency`)
                );
              } else {
                textValue = value;
              }
              break;
            }
            case ContractFieldDTOV1.type.FORMULA: {
              const result = evaluateForumla({
                name: field.name.en,
                field: field,
                fields: fields,
                contractData: getValues().fields,
                data: (field.data as FormulaFieldData).formula,
              });
              if (isNaN(result.value)) {
                textValue = null;
                break;
              } else if (result.type === ContractFieldDTOV1.type.AMOUNT) {
                textValue = paymentFormatter(
                  locale,
                  result.value,
                  (result as FormulaAmountResult).currency
                );
              } else {
                textValue = result.value.toString();
              }
              value = result.value.toString();
              break;
            }
            default:
              textValue = value;
              break;
          }

          if (textValue === "–" || textValue === "") {
            // textValue is equivalent to null, setting it to null to avoid displaying "–" in the editor
            textValue = null;
          }

          placeholder
            .setEphemeralValue(value ?? null)
            .setTextContent(
              textValue ??
                getLocalizedPlaceholderLabel(
                  field,
                  locale,
                  t,
                  placeholder.getSlug(),
                  placeholder.getFieldKey()
                )
            );
        },
        {
          tag: "sync",
          skipTransforms: true,
        }
      );
    },
    [fields, getValues, contacts, editor, locale, userDateFormat]
  );

  /**
   * Effect hook to watch for changes in the contract form fields and update corresponding placeholders.
   */
  useEffect(() => {
    const subscription = watch((_data, { name }) => {
      if (!name || !name.startsWith("fields.")) {
        return;
      }
      const [, id, fieldKey] = name.split(".");
      const field = fields.find((field) => field.id === id);
      if (!field) {
        return;
      }

      const nodeKeys = editor.getEditorState().read(() => {
        const placeholders = $nodesOfType(PlaceholderTextNode);
        const nodesToSync = new Set<string>();

        /*
         * this block ensures that, whenever paymentTax and paymentPriceNet are updated,
         * the corresponding formula datapoints paymentPriceGross and paymentPriceMonthly placeholders are updated as well.
         * This should be updated with a more dynamic check in the future, if users can create their own formula datapoints
         */

        if (
          field.visibleId === "paymentTax" ||
          field.visibleId === "paymentPriceNet" ||
          field.visibleId === "paymentCycle"
        ) {
          placeholders
            .filter((placeholder) =>
              ["paymentPriceGross", "paymentPriceMonthly"].includes(
                placeholder.getSlug()
              )
            )
            .forEach((node) => nodesToSync.add(node.getKey()));
        }

        const nodes = placeholders.filter(
          (placeholder) =>
            placeholder.getSlug() === field.visibleId &&
            (placeholder.getFieldKey() === fieldKey ||
              field.type === "CONTACT" ||
              field.type === "AMOUNT")
        );

        nodes.forEach((node) => nodesToSync.add(node.getKey()));
        return nodesToSync;
      });

      nodeKeys.forEach((key) => syncPlaceholderNode(key));
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [watch, syncPlaceholderNode, editor]);

  /**
   * Register mutation listeners to update form values when placeholder nodes are modified directly.
   */
  useEffect(() => {
    return mergeRegister(
      editor.registerMutationListener(
        PlaceholderTextNode,
        (nodes, payload) => {
          for (const [nodeKey, mutationType] of nodes) {
            if (mutationType === "created") {
              syncPlaceholderNode(nodeKey);
              continue;
            }

            // checking for updateTag `sync` makes sure we don't reconcile if the "source" of the mutation
            // is not user input, but rather the form on the left changed.
            // we only want to override the form, if the user has manually updated the value in the editor.
            if (!payload.updateTags.has("sync") && mutationType === "updated") {
              const previousValue = payload.prevEditorState.read(() => {
                const node = $getNodeByKey<PlaceholderTextNode>(nodeKey);
                if (!node) {
                  return;
                }
                return node.getEphemeralValue();
              });

              editor.getEditorState().read(() => {
                const node = $getNodeByKey<PlaceholderTextNode>(nodeKey);
                if (!node) {
                  return;
                }
                const value = node.getEphemeralValue();
                if (previousValue === value) {
                  return;
                }

                const field = fields.find(
                  (field) => field.visibleId === node.getSlug()
                );
                if (!field) {
                  return;
                }

                // Update the form field with the new ephemeral value from the node
                setValue(
                  `fields.${field.id}.${node.getFieldKey()}`,
                  value as never,
                  {
                    shouldDirty: true,
                  }
                );
              });
            }
          }
        },
        { skipInitialization: false }
      )
    );
  }, [fields, setValue, editor, syncPlaceholderNode]);

  return null;
};
