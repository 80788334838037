import styled from "@emotion/styled";
import { theme } from "theme";

export const TemplateComponents = styled.div`
  display: flex;
  gap: ${theme.spacing.lg};
  flex-direction: column;
  overflow: hidden;
`;

export const Components = styled.div`
  display: flex;
  gap: ${theme.spacing.sm};
  flex-wrap: wrap;
`;

export const Title = styled.div`
  position: relative;

  span {
    position: relative;
    padding-right: ${theme.spacing.sm};
    font-size: ${theme.font.size.caption};
    background: ${theme.color.white};
    color: ${theme.color.gray[600]};
    font-weight: ${theme.font.weight.semiBold};
    letter-spacing: 0.4px;
    text-transform: uppercase;
    z-index: 1;
  }

  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    height: 1px;
    width: 100%;
    background-color: ${theme.color.gray[300]};
  }
`;
