import React, { useState } from "react";
import * as Sentry from "@sentry/react";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { Tooltip } from "@mui/material";
import {
  useContractQuery,
  useAddDocumentMutation,
  useFetchDocumentsQuery,
  useOrganizationCategoriesQuery,
  useFieldsQuery,
} from "shared/api";
import {
  ContractAttachmentListItemDTO,
  ContractService,
  DocumentDTO,
  DocumentService,
} from "openapi";
import { useTeam } from "contexts/team/hooks";
import { useLocale } from "hooks";
import { NewModal, CTAButton, MessageBox } from "components";
import { getNavigationToContractViewById } from "constants/utils";
import { ListItem } from "new-components/DocumentListItem/DocumentListItem";
import MigrateDocumentIcon from "assets/svg/migrate-document.svg?react";
import FilePdfIcon from "assets/svg/file-pdf.svg?react";
import AlertIcon from "assets/svg/annotation-alert.svg?react";
import { getCategory } from "pages/Contracts/helpers";
import { Tag } from "new-components";
import { getDarkenedColor, getSoftenedColor } from "utils/color";
import { theme } from "theme";
import {
  ArrowRightIcon,
  BorderedBox,
  Info,
  List,
  ModalBody,
  Name,
  Type,
  TypeLabel,
} from "./styles";
import { getFieldsCount } from "./utils";

export type MigrateRelatedProps = {
  attachment: ListItem;
  refetchOnDelete: () => void;
  mainContract?: ContractAttachmentListItemDTO;
};

const MigrateRelated = ({
  attachment,
  refetchOnDelete,
  mainContract,
}: MigrateRelatedProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { locale } = useLocale();
  const { hasWriteAccess, selectedTeamId, organizationId } = useTeam();
  const [showDeleteContractModal, setShowDeleteContractModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { mutateAsync: addDocument } = useAddDocumentMutation();
  const { data: contract } = useContractQuery(selectedTeamId, attachment.id);

  const { data: documents } = useFetchDocumentsQuery(
    organizationId,
    contract?.teamId,
    attachment.id
  );

  const { data: categories } = useOrganizationCategoriesQuery(organizationId);
  const { data: datapoints } = useFieldsQuery(organizationId);

  const mainContractName = mainContract?.name;

  const handleOnClose = () => setShowDeleteContractModal(false);

  const downloadDocument = async (document: DocumentDTO) => {
    if (!attachment.id || !document || !contract) return;

    try {
      const file: Blob = (await DocumentService.downloadDocument(
        organizationId,
        contract.teamId,
        attachment.id,
        document.id
      )) as Blob;
      return file;
    } catch (error: unknown) {
      enqueueSnackbar(
        t("pages.contracts.messages.errorOnDocumentMigration", {
          documentName: document.filename,
        }),
        { variant: "error" }
      );

      throw error;
    }
  };

  const uploadDocument = async (documentFile: Blob, documentName: string) => {
    if (!attachment.id || !document || !contract) return;

    try {
      await addDocument({
        organizationId: organizationId,
        teamId: contract.teamId,
        contractId: attachment.parentId || "",
        file: documentFile,
      });
    } catch (error: unknown) {
      enqueueSnackbar(
        t("pages.contracts.messages.errorOnDocumentMigration", {
          documentName: documentName,
        }),
        { variant: "error" }
      );

      throw error;
    }
  };

  const updateDataLayer = () => {
    if (window.dataLayer) {
      window.dataLayer.push({
        event: "migrateAttachmentToDocument",
        action: "migrateAttachmentToDocument",
      });
    }
  };

  const handleOnMigrate = async () => {
    if (!documents || !attachment.id) return;

    setIsLoading(true);
    try {
      for (const document of documents) {
        const documentFileBlob = await downloadDocument(document);
        if (documentFileBlob) {
          const file = new File([documentFileBlob], document.filename, {
            type: document.mimetype,
          });
          await uploadDocument(file, document.filename);
        }
      }

      await ContractService.deleteContract(attachment.id);

      if (refetchOnDelete) refetchOnDelete();

      navigate(getNavigationToContractViewById(attachment.parentId));

      enqueueSnackbar(
        t("pages.contracts.messages.successfullDocumentMigration", {
          mainContractName,
        }),
        { variant: "success" }
      );
    } catch (e) {
      Sentry.captureException(e);
    } finally {
      handleOnClose();
      setIsLoading(false);
      updateDataLayer();
    }
  };

  const hasAccess = hasWriteAccess(attachment?.categoryId, contract?.teamId);

  if (!hasAccess || !attachment.id || !categories || !contract || !datapoints)
    return null;

  const contractCategory = getCategory(categories, contract.categoryId);

  const fieldsCount = getFieldsCount(contract.fields, datapoints);
  const tasksCount = contract?.tasksNumber;
  const documentsCount = documents?.length;

  const fieldsTranslation = t(
    "pages.contractDetails.documents.migrateModal.fields",
    {
      count: fieldsCount,
    }
  );
  const tasksTranslation = t(
    "pages.contractDetails.documents.migrateModal.tasks",
    {
      count: tasksCount,
    }
  );
  const documentsTranslation = t(
    "pages.contractDetails.documents.migrateModal.documents",
    {
      count: documentsCount,
    }
  );
  const attachmentName = attachment.name;
  const contractCategoryTranslation = contractCategory?.name[locale];

  return (
    <>
      <Tooltip placement="top" title={t("common.buttons.migrateRelated")}>
        <CTAButton
          variant="tertiary"
          icon={<MigrateDocumentIcon />}
          iconSize="medium"
          label="migrateRelatedBtn"
          onClick={() => setShowDeleteContractModal(true)}
        />
      </Tooltip>

      <NewModal
        open={showDeleteContractModal}
        handleClose={() => setShowDeleteContractModal(false)}
        title={t("pages.contractDetails.documents.migrateModal.title", {
          mainContractName,
        })}
        fullWidth
        body={
          <ModalBody aria-label="migrateModalBody">
            <BorderedBox>
              <Info>
                <Trans
                  i18nKey="pages.contractDetails.documents.migrateModal.attachmentInfo"
                  values={{
                    fieldsTranslation,
                    tasksTranslation,
                    documentsTranslation,
                    attachmentName,
                    contractCategoryTranslation,
                  }}
                  components={{
                    name: <Name />,
                    tag: contractCategory ? (
                      <Tag
                        variant="custom"
                        backgroundColor={
                          getSoftenedColor(contractCategory?.color) ??
                          theme.color.blue[200]
                        }
                        color={
                          getDarkenedColor(contractCategory?.color) ??
                          theme.color.blue[600]
                        }
                      >
                        {contractCategoryTranslation}
                      </Tag>
                    ) : (
                      <span />
                    ),
                    list: <List />,
                    listItem: <li />,
                    break: <br />,
                    span: <span />,
                  }}
                />
              </Info>
              <Type>
                <Tag variant="related">{t("contractType.ATTACHMENT")}</Tag>
                <ArrowRightIcon />
                <TypeLabel>
                  <FilePdfIcon />
                  <span>
                    {t(
                      "pages.contractDetails.documents.migrateModal.documentLabel"
                    )}
                  </span>
                </TypeLabel>
              </Type>
            </BorderedBox>
            <div>
              {t("pages.contractDetails.documents.migrateModal.description")}
            </div>
            <MessageBox
              icon={<AlertIcon fill={theme.color.white} />}
              variation="warning"
              title={t(
                "pages.contractDetails.documents.migrateModal.callout.title"
              )}
              description={t(
                "pages.contractDetails.documents.migrateModal.callout.description"
              )}
            />
          </ModalBody>
        }
        footer={
          <>
            <CTAButton
              onClick={handleOnClose}
              variant="secondary"
              name={t("common.buttons.cancel")}
              disabled={isLoading}
            />
            <CTAButton
              label="move"
              onClick={handleOnMigrate}
              variant="primary"
              name={t("common.buttons.migrateNow")}
              disabled={isLoading}
              loading={isLoading}
            />
          </>
        }
      />
    </>
  );
};

export default MigrateRelated;
