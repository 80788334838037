interface Document {
  id: string;
  order: number;
}

export const getNewOrder = (
  reorderedDocuments: Array<Document>,
  newIndex: number
) => {
  // If dropped at the start of the list
  if (newIndex === 0) {
    const oldTopDocument = reorderedDocuments.at(1);
    if (oldTopDocument) {
      return oldTopDocument.order / 2;
    }
  }
  // If dropped at the end of the list, increment maximum order by 1
  const underDocument = reorderedDocuments.at(newIndex + 1);
  if (!underDocument) {
    return Math.max(...reorderedDocuments.map((doc) => doc.order)) + 1;
  }
  // If it's dropped between two other items
  const topDocument = reorderedDocuments.at(newIndex - 1);
  if (topDocument) {
    return (topDocument.order + underDocument.order) / 2;
  }
};
