import React, { useMemo, useState } from "react";
import { NewModal, CardWrapper, CTAButton } from "components";
import { useTeam } from "contexts/team/hooks";
import InviteSupportUserModalContent from "./InviteSupportUserModalContent";
import { useAddSupportMemberMutation } from "shared/api/team-member-invitation";
import { enqueueSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { setValidationSnackbarErrors } from "shared/service/errorResponseService";
import {
  useRemoveMemberFromOrganizationMutation,
  useTeamMembersQuery,
} from "shared/api";
import CardHeaderWrapper from "components/CardHeaderWrapper/CardHeaderWrapper";
import { FormProvider, useForm } from "react-hook-form";

export type FormValues = {
  allowAccess: boolean;
};

const InviteSupportUserSection = () => {
  const { t } = useTranslation();
  const { isOwner, organizationId, selectedTeamId } = useTeam();

  const { data: teamMembersData } = useTeamMembersQuery(selectedTeamId);
  const supportUser = teamMembersData?.members.find(
    (member) => member.isSupportUser
  );

  const [showInviteSupportUserModal, setShowInviteSupportUserModal] =
    useState(false);

  const [isLoading, setLoading] = useState(false);

  const initialState = useMemo(
    () => ({ allowAccess: Boolean(supportUser) }),
    [supportUser]
  );

  const form = useForm<FormValues>({
    defaultValues: initialState,
  });
  const isUserOwner = isOwner();

  const addSupportMember = useAddSupportMemberMutation(selectedTeamId);
  const removeSupportMember = useRemoveMemberFromOrganizationMutation();

  const locale =
    "pages.settings.organization.overview.sections.inviteSupportUser";

  const handleFormReset = () => {
    form.reset(initialState);
    setShowInviteSupportUserModal(false);
  };

  const handleGrantAccess = async (input: FormValues) => {
    setLoading(true);

    const noChangeInState = input.allowAccess === Boolean(supportUser);

    try {
      if (noChangeInState) {
        handleFormReset();
      }
      if (input.allowAccess) {
        await addSupportMember.mutateAsync({ organizationId });
        enqueueSnackbar(t(`${locale}.snackbar.accessGranted`), {
          variant: "success",
        });
      } else {
        await removeSupportMember.mutateAsync({
          memberId: supportUser?.id as string,
          teamId: selectedTeamId,
        });
        enqueueSnackbar(t(`${locale}.snackbar.accessRevoked`), {
          variant: "success",
        });
      }
    } catch (err) {
      setValidationSnackbarErrors(
        err,
        `${locale}.snackbar.error`,
        enqueueSnackbar,
        t
      );
    } finally {
      setLoading(false);
      handleFormReset();
    }
  };

  const buttonText = supportUser
    ? t(`${locale}.button.revoke`)
    : t(`${locale}.button.invite`);

  return (
    <div id="inviteSupportUserSection">
      <FormProvider {...form}>
        <CardWrapper>
          <CardHeaderWrapper
            actions={
              <CTAButton
                name={buttonText}
                onClick={() => setShowInviteSupportUserModal(true)}
                variant="secondary"
                disabled={!isUserOwner}
              />
            }
            title={t(`${locale}.title`)}
          />
          <p>{t(`${locale}.description`)}</p>
        </CardWrapper>

        <NewModal
          body={<InviteSupportUserModalContent />}
          handleClose={handleFormReset}
          maxWidth="sm"
          open={showInviteSupportUserModal}
          footer={
            <>
              <CTAButton
                label="cancelBtn"
                name={t("common.buttons.cancel")}
                onClick={handleFormReset}
                type="reset"
                variant="secondary"
              />
              <CTAButton
                disabled={isLoading}
                label="deleteBtn"
                name={t("common.buttons.confirm")}
                onClick={form.handleSubmit(handleGrantAccess)}
                type="submit"
                variant="primary"
              />
            </>
          }
          title={t(`${locale}.modal.title`)}
        />
      </FormProvider>
    </div>
  );
};

export default InviteSupportUserSection;
