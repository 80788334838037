import styled from "@emotion/styled";
import ArrowLeftIcon from "../../../assets/svg/arrow-left.svg?react";

export const Arrow = styled(ArrowLeftIcon)`
  width: 24px;
  height: 24px;
  fill: var(--color-primary);
  ${({ orientation }: { orientation?: string }) =>
    orientation === "right"
      ? `
      margin-left: var(--spacing-sm);
      transform: rotate(180deg);
    `
      : `
      margin-right: var(--spacing-sm);
    `}
`;
