import React, { KeyboardEvent, useEffect, useState } from "react";
import { TextInputWrapper } from "./styles";

export type BaseTextFieldProps = {
  id?: string;
  ref?: React.RefObject<HTMLInputElement>;
  name: string;
  defaultValue?: string;
  placeholder?: string;
  value?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  error?: boolean;
  helperText?: string;
  size?: "xs" | "sm" | "md";
  isBorderLessInput?: boolean;
  required?: boolean;
  fullWidth?: boolean;
  suggestion?: boolean;
  onKeyPress?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
};

export const TextField = ({
  id,
  name,
  defaultValue,
  placeholder,
  value,
  onChange,
  onBlur,
  disabled,
  error,
  helperText,
  size = "md",
  isBorderLessInput,
  required,
  fullWidth,
  suggestion,
  ...inputProps
}: BaseTextFieldProps) => {
  const inputId = id || `input-${name}`;
  const helperTextId = `${inputId}-helper-text`;
  const errorId = `${inputId}-error`;

  // Local state to handle prop value changes
  const [inputValue, setInputValue] = useState(defaultValue);

  useEffect(() => {
    setInputValue(value !== undefined ? value : defaultValue);
  }, [value, defaultValue]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
    if (onChange) onChange(event);
  };

  return (
    <TextInputWrapper
      fullWidth={fullWidth}
      size={size}
      isBorderLessInput={isBorderLessInput}
      error={error}
      suggestion={suggestion}
    >
      <input
        {...inputProps}
        id={inputId}
        name={name}
        aria-label={name}
        type="text"
        placeholder={placeholder}
        defaultValue={defaultValue}
        value={inputValue}
        onChange={handleChange}
        onBlur={onBlur}
        disabled={disabled}
        required={required}
        aria-invalid={error ? "true" : "false"}
        aria-describedby={`${helperText ? helperTextId : ""} ${
          error ? errorId : ""
        }`}
      />
      {helperText && <span>{helperText}</span>}
    </TextInputWrapper>
  );
};

export default TextField;
