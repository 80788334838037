import { useTeam } from "contexts/team/hooks";
import { useLocale } from "hooks/GlobalStateHooks";
import { StatisticsViewModeType } from "pages/Dashboard/Dashboard";
import { getSelectedData } from "pages/Dashboard/utils";
import { useTranslation } from "react-i18next";
import {
  useFinancialOverviewQuery,
  useStatisticsOverviewQuery,
} from "shared/api/overview";
import { routePaths } from "../../../../../constants";

export const useNumFormatter = (amount: number, locale: string) => {
  if (amount > 1e6 - 1 && amount <= 1e9) {
    const quotient = amount / 1e6;
    const formattedAmount = new Intl.NumberFormat(locale).format(
      +quotient.toFixed(2)
    );
    return formattedAmount + "M";
  } else if (amount >= 1e9) {
    const quotient = amount / 1e9;
    const formattedAmount = new Intl.NumberFormat(locale).format(
      +quotient.toFixed(2)
    );
    return formattedAmount + "B";
  } else {
    const formattedAmount = new Intl.NumberFormat(locale).format(
      Math.round(+amount)
    );
    return formattedAmount;
  }
};

export type StatisticsData = {
  iconName: string;
  id: string;
  isLoading: boolean;
  linkState?: { filter: string };
  linkTo: string | null;
  title: string;
  tooltipText: string;
  value: string;
};

export const useStatisticsData = (
  statisticsTypeWatch: StatisticsViewModeType
) => {
  const { t } = useTranslation();
  const { locale } = useLocale();
  const { selectedTeam } = useTeam();

  const { data: statisticsData, isLoading: isStatisticsLoading } =
    useStatisticsOverviewQuery(selectedTeam?.id);
  const { data: financialsData, isLoading: isFinancialsLoading } =
    useFinancialOverviewQuery(selectedTeam?.id);
  const selectedStatisticsData = getSelectedData(
    statisticsTypeWatch,
    statisticsData
  );
  const selectedFinancialsData = getSelectedData(
    statisticsTypeWatch,
    financialsData
  );

  function getContractCount() {
    const count = selectedStatisticsData?.contractCount ?? 0;

    if (selectedTeam && selectedTeam?.isSubTeam) return `${count}`;

    if (!selectedTeam?.maxContracts || selectedTeam?.maxContracts >= 10000) {
      return `${count} / ∞`;
    }

    return `${count} / ${selectedTeam?.maxContracts}`;
  }

  const income = selectedFinancialsData?.income ?? 0;
  const expense = selectedFinancialsData?.expense ?? 0;

  const incomeFormatted = `${useNumFormatter(income, locale)} €`;
  const expenseFormatted = `${useNumFormatter(expense, locale)} €`;

  const responseFormatted: StatisticsData[] = [
    {
      iconName: "reminders",
      id: "statistic1",
      isLoading: isStatisticsLoading,
      linkState: {
        filter: selectedStatisticsData?.tasksWithDueDate
          ? "task_due_date_view"
          : "",
      },
      linkTo: routePaths.TASKS,
      title: t("pages.dashboard.statistics.titles.reminders"),
      tooltipText: t(
        "pages.dashboard.statistics.counters.numberOfTasksWithDueDate"
      ),
      value: String(selectedStatisticsData?.tasksWithDueDate ?? 0),
    },
    {
      iconName: "contracts",
      id: "statistic2",
      isLoading: isStatisticsLoading,
      linkTo: routePaths.CONTRACTS,
      title: t("pages.dashboard.statistics.titles.contracts"),
      tooltipText: t("pages.dashboard.statistics.counters.numberOfContracts"),
      value: getContractCount(),
    },
    {
      iconName: "arrow-up",
      id: "statistic3",
      isLoading: isFinancialsLoading,
      linkTo: null,
      title: t("pages.dashboard.statistics.titles.income"),
      tooltipText: t("pages.dashboard.statistics.financials.tooltipIncome"),
      value: incomeFormatted,
    },
    {
      iconName: "arrow-down",
      id: "statistic4",
      isLoading: isFinancialsLoading,
      linkTo: null,
      title: t("pages.dashboard.statistics.titles.expense"),
      tooltipText: t("pages.dashboard.statistics.financials.tooltipExpense"),
      value: expenseFormatted,
    },
  ];

  return responseFormatted;
};
