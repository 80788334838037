import React, { useState, useCallback } from "react";
import { useDropzone, DropzoneOptions } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import routesPaths from "constants/routePaths";
import { CTAButton, NewModal } from "components";
import ImportIcon from "assets/svg/import-file.svg?react";
import { ImportButton } from "../Header/components/Import/styles";
import { theme } from "theme";
import { ModalBody, Content, Block, Paragraph } from "./styles";

interface TemplateFile extends File {
  path?: string;
}

const ImportTemplate = ({
  setImportedFile,
}: {
  importedFile?: File | null;
  setImportedFile: (file: TemplateFile | null) => void;
}) => {
  const { t } = useTranslation();
  const [showImportModal, setShowImportModal] = useState<boolean>(false);
  const [templateFile, setTemplateFile] = useState<TemplateFile | null>(null);
  const navigate = useNavigate();

  const onDrop = useCallback((acceptedFiles: TemplateFile[]) => {
    setTemplateFile(acceptedFiles[0]);
  }, []);

  const dropzoneOptions: DropzoneOptions = {
    onDrop,
    accept: {
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        [".docx"],
    },
    multiple: false,
  };

  const { getRootProps, getInputProps } = useDropzone(dropzoneOptions);

  const handleSubmit = () => {
    if (templateFile) {
      setImportedFile(templateFile);
      navigate(`${routesPaths.SETTINGS_CONTRACT_TEMPLATES}/new`);
    }
  };

  const handleOnClose = () => {
    setTemplateFile(null);
    setShowImportModal(false);
  };

  return (
    <>
      <ImportButton
        name={t("pages.settings.tabs.contractTemplates.import")}
        icon={<ImportIcon />}
        hasRoundedBorder={false}
        onClick={() => setShowImportModal(true)}
      />
      <NewModal
        fullWidth
        open={showImportModal}
        handleClose={handleOnClose}
        title={t(
          "pages.settings.tabs.contractTemplates.gallery.modals.import.title"
        )}
        body={
          <ModalBody>
            {!templateFile ? (
              <Content {...getRootProps()}>
                <input {...getInputProps()} />
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: theme.spacing.lg,
                  }}
                >
                  <ImportIcon />
                  <Block>
                    <Paragraph bold>
                      {t(
                        "pages.settings.tabs.contractTemplates.gallery.modals.import.dropableArea.description"
                      )}
                    </Paragraph>
                    <Paragraph>
                      {t(
                        "pages.settings.tabs.contractTemplates.gallery.modals.import.dropableArea.caption"
                      )}
                    </Paragraph>
                  </Block>
                </div>
              </Content>
            ) : (
              <Content highlighted>
                <Block>
                  <Paragraph bold>
                    {t(
                      "pages.settings.tabs.contractTemplates.gallery.modals.import.selected.description"
                    )}
                    :
                  </Paragraph>
                  <Paragraph>
                    {templateFile.path} - {templateFile.size} bytes
                  </Paragraph>
                </Block>
              </Content>
            )}
          </ModalBody>
        }
        footer={
          <>
            <CTAButton
              name={t("common.buttons.cancel")}
              variant="secondary"
              onClick={handleOnClose}
            />
            <CTAButton
              name={t("common.buttons.import")}
              onClick={handleSubmit}
              disabled={!templateFile}
            />
          </>
        }
      />
    </>
  );
};

export default ImportTemplate;
