import React from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Signatory, DragIndicatorIcon } from "./styles";
import globalConfig from "shared/config/global.config";

const SortableSignatory = ({
  id,
  isOver,
  children,
  disabled,
  eid,
}: {
  id: string;
  isOver?: boolean;
  children: React.ReactNode | React.ReactNode[];
  disabled?: boolean;
  eid?: boolean;
}) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id });
  const style = {
    opacity: isDragging ? 0.5 : 1,
    zIndex: isDragging ? 1 : 0,
    transition,
    transform: isDragging ? CSS.Transform.toString(transform) : "",
  };

  return (
    <Signatory
      ref={setNodeRef}
      style={style}
      dragging={isDragging}
      isOver={isOver}
    >
      {globalConfig.REACT_APP_DROPBOX_CLIENT_ID && !disabled && !eid ? (
        <DragIndicatorIcon {...attributes} {...listeners} />
      ) : null}

      {children}
    </Signatory>
  );
};

export default SortableSignatory;
