import React, { useEffect, useState } from "react";
import { ContractDTOV1, ContractAttachmentListItemDTO } from "openapi";
import { useTeam } from "contexts/team/hooks";
import { Features } from "constants/features";
import { Signature, ContractViewSwitcher, Dropdown } from "./components";
import { Wrapper } from "./styles";
import { CTAButton } from "components";
import { AcceptedFileType } from "shared/enums/document.enum";
import { useContractDetails } from "pages/Contract/context";
import { getTemplateObj } from "../../helpers";
import { useTranslation } from "react-i18next";

type Props = {
  contractFile?: Blob | undefined | null;
  //TODO: to be updated from the route documentId
  firstDocumentId?: string;
  fetchPdf: () => Promise<void>;
  fetchData: (loadFromStash?: boolean | undefined) => Promise<void>;
  contract: ContractDTOV1;
  editable: boolean;
  contractGroup: ContractAttachmentListItemDTO[];
};

const Toolbar = ({
  fetchData,
  fetchPdf,
  contract,
  contractFile,
  editable,
  contractGroup,
  firstDocumentId,
}: Props) => {
  const { hasWriteAccess, hasFeature, organizationId } = useTeam();
  const { createPDFDocument, isLoadingCreatePDFDocument, updateDocument } =
    useContractDetails();
  const [templateId, setTemplateId] = useState("");
  const { t } = useTranslation();
  const isESignatureActive = hasFeature(Features.ESIGNATURE);
  const contractHasPDFFile = !!contractFile;
  const isRequestSignatureDisabled =
    (!contractHasPDFFile && isESignatureActive) ||
    contract?.status === ContractDTOV1.status.SIGNING ||
    contract.signature?.status === ContractDTOV1.status.SIGNING ||
    contract.signature?.status === "DECLINED" ||
    contract.signature?.status === "SIGNED";
  const showRequestSignature =
    isESignatureActive && contract.signature?.status !== "completed";
  const isPDFDocument = contractFile?.type === AcceptedFileType.PDF;
  const isLexicalDocument = contractFile?.type === AcceptedFileType.LEXICAL;

  const getTemplateId = async () => {
    if (!contractFile) return;

    const templateData = await getTemplateObj(contractFile);

    if (!templateData) return;

    setTemplateId(templateData.templateId);
  };

  const refetch = () => {
    void fetchPdf();
    void fetchData();
  };

  useEffect(() => {
    void getTemplateId();
  }, [contractFile]);

  return editable ? (
    <>
      <Wrapper>
        {isLexicalDocument && (
          <CTAButton
            data-testid="saveDocumentBtn"
            name={t("pages.contractDetails.buttons.saveDraft")}
            onClick={() =>
              void updateDocument({
                contractId: contract?.id,
                templateId,
                organizationId,
                contractTeamId: contract.teamId,
                documentId: firstDocumentId,
              })
            }
            variant="secondary"
            size="square"
            iconSize="small"
          />
        )}

        {!isPDFDocument && contractHasPDFFile && (
          <CTAButton
            data-testid="createDocumentBtn"
            name={t("pages.contractDetails.buttons.createPDF")}
            onClick={() =>
              void createPDFDocument({
                contractId: contract?.id,
                refetch: refetch,
                organizationId,
                contractTeamId: contract.teamId,
                documentId: firstDocumentId,
              })
            }
            variant="secondary"
            size="square"
            iconSize="small"
            disabled={isLoadingCreatePDFDocument}
          />
        )}
        <ContractViewSwitcher />
      </Wrapper>
    </>
  ) : (
    <Wrapper>
      {showRequestSignature && isPDFDocument && (
        <Signature
          hasPDF={contractHasPDFFile}
          disabled={isRequestSignatureDisabled}
          fetchData={fetchData}
        />
      )}

      {hasWriteAccess(contract.categoryId, contract.teamId) && (
        <>
          <ContractViewSwitcher />
          <Dropdown contract={contract} contractGroup={contractGroup} />
        </>
      )}
    </Wrapper>
  );
};

export default Toolbar;
