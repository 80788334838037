import React, { useState, useEffect, useRef, forwardRef } from "react";
import { enqueueSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { IconButton, Popover } from "@mui/material";
import { MoreHoriz } from "@mui/icons-material";
import { CategoryDTO, ContractTemplateV1DTO } from "openapi";
import { useTeam } from "contexts/team/hooks";
import { useLocale } from "hooks";
import {
  useContractTemplateCreateMutationV1,
  useContractTemplateUpdateMutationV1,
} from "shared/api/contract-templates";
import { DocumentListItem, Tag, TextField } from "new-components";
import { CTAButton, NewModal } from "components";
import { dateFormatter } from "constants/utils";
import { getDarkenedColor, getSoftenedColor } from "utils/color";
import { theme } from "theme";
import { Tags } from "../../../components/Header/components";
import DeleteTemplateModal from "../../../components/DeleteTemplate/DeleteTemplate";
import Preview from "./Preview";
import {
  TemplateInfo,
  TemplateNameWrapper,
  TemplateName,
  TemplateDate,
  BinIcon,
  TemplateIcon,
  CalendarIcon,
  DuplicateIcon,
  TemplateRenameIcon,
  Menu,
  MenuItem,
} from "./styles";
import { useTeamMembersQuery } from "shared/api";
import { useNavigate } from "react-router";
import routePaths from "constants/routePaths";

const TemplateOptionsPopoverMenuButton = forwardRef<
  HTMLButtonElement,
  { onClick: (event: React.MouseEvent<HTMLButtonElement>) => void }
>((props, ref) => {
  return (
    <IconButton ref={ref} onClick={props.onClick} size="small">
      <MoreHoriz fontSize="small" />
    </IconButton>
  );
});
TemplateOptionsPopoverMenuButton.displayName =
  "TemplateOptionsPopoverMenuButton";

type TemplateCardProps = {
  template: ContractTemplateV1DTO;
  category: CategoryDTO;
  onClick: () => void;
  isEditable?: boolean;
  isGridView: boolean;
};

const TemplateCard = ({
  template,
  category,
  onClick,
  isEditable,
  isGridView,
}: TemplateCardProps) => {
  const { t } = useTranslation();
  const { organizationId, selectedTeamId } = useTeam();
  const { locale } = useLocale();
  const navigate = useNavigate();
  const templateRef = useRef(template);
  const anchorRef = useRef<HTMLButtonElement>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null);
  const [newTemplateName, setNewTemplateName] = useState(
    templateRef.current.name
  );
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showRenameModal, setShowRenameModal] = useState(false);

  const createContractTemplate = useContractTemplateCreateMutationV1();
  const updateContractTemplate = useContractTemplateUpdateMutationV1();
  const { data: memberData } = useTeamMembersQuery(selectedTeamId);

  useEffect(() => {
    templateRef.current = template;
  }, [template]);

  const handleOnCreateDuplicate = async () => {
    try {
      const newTemplate = await createContractTemplate.mutateAsync({
        organizationId,
        template: {
          ...templateRef.current,
          name: `${t(
            "pages.settings.organization.contractTemplates.fields.name.duplicatePrefix"
          )}${templateRef.current.name}`,
          content: templateRef.current.content ?? "",
          categoryId: templateRef.current.categoryId,
          tagIds: templateRef.current.tags.map((tag) => tag.id),
        },
      });
      setAnchorEl(null);
      enqueueSnackbar(
        t(
          "pages.settings.tabs.contractTemplates.gallery.snackbars.duplicate.success"
        ),
        { variant: "success" }
      );
      navigate(`${routePaths.SETTINGS_CONTRACT_TEMPLATES}/${newTemplate.id}`);
    } catch (error) {
      enqueueSnackbar(
        t(
          "pages.settings.tabs.contractTemplates.gallery.snackbars.duplicate.failed"
        ),
        { variant: "error" }
      );
    }
  };

  const handleOnRenameTemplate = async () => {
    try {
      await updateContractTemplate.mutateAsync({
        organizationId,
        templateId: templateRef.current.id,
        template: { name: newTemplateName },
        refetchType: "active",
      });
      setAnchorEl(null);
      setShowRenameModal(false);
      enqueueSnackbar(
        t(
          "pages.settings.tabs.contractTemplates.gallery.snackbars.rename.success"
        ),
        { variant: "success" }
      );
    } catch (error) {
      enqueueSnackbar(
        t(
          "pages.settings.tabs.contractTemplates.gallery.snackbars.rename.failed"
        ),
        {
          variant: "error",
        }
      );
    }
  };

  const TagsList = () => (
    <Tags
      isEditable={isEditable}
      isGridView={isGridView}
      maxTagsCount={isGridView ? 3 : 2}
      preselectedTags={templateRef.current.tags}
      templateRef={templateRef}
    />
  );

  const handleOptionsButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const TemplateOptionsPopoverMenu = () => {
    return (
      <Popover
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: isGridView ? "center" : "right",
        }}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        PaperProps={{
          style: {
            padding: "8px",
            borderRadius: "4px",
            maxWidth: "200px",
          },
        }}
      >
        <Menu>
          <MenuItem onClick={handleOnCreateDuplicate}>
            <DuplicateIcon />
            {t("common.buttons.duplicate")}
          </MenuItem>
          <MenuItem
            onClick={() => {
              setShowRenameModal(true);
              setAnchorEl(null);
            }}
          >
            <TemplateRenameIcon />
            {t("common.buttons.rename")}
          </MenuItem>
          <MenuItem onClick={() => setShowDeleteModal(true)} remove>
            <BinIcon />
            {t("common.buttons.delete")}
          </MenuItem>
        </Menu>
      </Popover>
    );
  };

  const author = memberData?.members.find(
    (member) => member.id === templateRef.current.authorId
  );

  const authorName = author
    ? `${author.firstname || ""} ${author.lastname || ""}`
    : "";

  return (
    <>
      {!isGridView ? (
        <DocumentListItem
          listItem={templateRef.current}
          category={category}
          authorName={authorName}
          tags={<TagsList />}
          onClick={onClick}
          style={{ padding: "0" }}
          buttons={
            isEditable ? (
              <>
                <TemplateOptionsPopoverMenuButton
                  ref={anchorRef}
                  onClick={handleOptionsButtonClick}
                />
                <TemplateOptionsPopoverMenu />
              </>
            ) : (
              <></>
            )
          }
        />
      ) : (
        <div data-testid="template-card">
          <Preview
            templateId={templateRef.current.id}
            organizationId={organizationId}
            onClick={onClick}
          />

          <TemplateInfo>
            <TemplateNameWrapper>
              <TemplateName>
                <TemplateIcon />
                <span>{templateRef.current.name}</span>
              </TemplateName>
              {isEditable && (
                <TemplateOptionsPopoverMenuButton
                  ref={anchorRef}
                  onClick={handleOptionsButtonClick}
                />
              )}
            </TemplateNameWrapper>
            <TemplateOptionsPopoverMenu />

            <TemplateDate>
              <CalendarIcon />
              {dateFormatter(
                locale,
                templateRef.current.updatedAt ?? templateRef.current.createdAt
              )}
            </TemplateDate>

            {category && (
              <Tag
                variant="custom"
                backgroundColor={
                  getSoftenedColor(category?.color) ?? theme.color.blue[200]
                }
                color={
                  getDarkenedColor(category?.color) ?? theme.color.blue[600]
                }
              >
                {category?.name[locale]}
              </Tag>
            )}
            <TagsList />
          </TemplateInfo>
        </div>
      )}
      <DeleteTemplateModal
        open={showDeleteModal}
        onClose={() => {
          setShowDeleteModal(false);
          setAnchorEl(null);
        }}
        currentCategory={category}
        data={template}
      />

      <NewModal
        open={showRenameModal}
        handleClose={() => setShowRenameModal(false)}
        title={t(
          "pages.settings.tabs.contractTemplates.gallery.modals.rename.title"
        )}
        body={
          <>
            <p>
              {t(
                "pages.settings.tabs.contractTemplates.gallery.modals.rename.description"
              )}
            </p>
            <TextField
              name={t(
                "pages.settings.organization.contractTemplates.fields.name.label"
              )}
              defaultValue={templateRef.current.name}
              onChange={(e) =>
                setNewTemplateName(e.target.value.toString().trim())
              }
              size="sm"
              fullWidth
            />
          </>
        }
        footer={
          <>
            <CTAButton
              name={t("common.buttons.cancel")}
              variant="secondary"
              onClick={() => setShowRenameModal(false)}
            />
            <CTAButton
              name={t("common.buttons.rename")}
              variant="primary"
              onClick={handleOnRenameTemplate}
            />
          </>
        }
      />
    </>
  );
};

export default React.memo(TemplateCard);
