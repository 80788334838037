import styled from "@emotion/styled";
import { keyframes } from "@emotion/react";
import {
  Card,
  IconButton as MUIIconButton,
  Button,
  Divider as MUIDivider,
} from "@mui/material";
import { theme } from "theme";
import { Link } from "react-router-dom";

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const Wrapper = styled.div`
  opacity: 0;
  animation-name: ${fadeIn};
  animation-duration: 1s;
  animation-fill-mode: forwards;
`;

export const FormWrapper = styled.div`
  margin-top: var(--spacing-lg);
`;

export const Columns = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media screen and (min-width: 768px) {
    flex-direction: row;
    position: relative;
  }
`;

export const Column = styled.div<{ isSticky?: boolean }>`
  flex: 1;

  :nth-of-type(1) {
    margin-right: var(--spacing-lg);
    position: relative;
  }

  :nth-of-type(2) {
    position: sticky;
    top: 0;
    align-self: flex-start;
    height: calc(100vh - 10rem);
  }
`;

export const Container = styled.div`
  height: 100%;
  background-color: ${theme.color.white};
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.sm};
  color: ${theme.color.gray[700]};
`;

export const Title = styled.h1`
  margin: 0;
  font-size: ${theme.font.size.h6};
  font-weight: ${theme.font.weight.semiBold};
  line-height: 2.25rem;
  word-break: break-word;
  display: flex;
`;

export const Divider = styled(MUIDivider)`
  background-color: ${theme.color.gray[100]};
  margin: ${theme.spacing.md} 0;
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: ${theme.spacing.sm};
`;

export const ChipContainer = styled.div`
  min-height: 40px;
  display: flex;
  align-items: center;
`;

export const ToolButtons = styled.div`
  display: flex;
  gap: ${theme.spacing.sm};
  justify-content: flex-end;
`;

export const IconButton = styled(MUIIconButton)`
  .preview,
  .delete,
  .edit {
    font-size: 20px;
    transition: color 0.2s ease-in-out;
  }

  :hover {
    .preview {
      color: ${theme.color.blue[600]};
    }

    .delete {
      color: ${theme.color.red[600]};
    }

    .edit {
      color: ${theme.color.blue[600]};
    }
  }
`;

export const SaveButton = styled(Button)`
  padding: ${theme.spacing.md};
  background-color: ${theme.color.blue[600]};
  width: 100%;
  color: ${theme.color.white};
  text-transform: none;
  font-weight: ${theme.font.weight.bold};
  font-size: ${theme.font.size.subheading};

  :hover {
    background-color: ${theme.color.blue[400]};
  }
`;

export const SmallButton = styled(Button)`
  padding: ${theme.spacing.md};
  background-color: ${theme.color.blue[600]};
  width: 100%;
  color: ${theme.color.white};
  text-transform: none;
  font-weight: ${theme.font.weight.bold};
  font-size: ${theme.font.size.caption};
  border-radius: ${theme.borderRadius};

  :hover {
    background-color: ${theme.color.blue[500]};
  }
`;

export const SmallButtonOutline = styled(Button)`
  padding: ${theme.spacing.md};
  border: 1px solid ${theme.color.blue[600]};
  width: 100%;
  color: ${theme.color.blue[600]};
  text-transform: none;
  font-weight: ${theme.font.weight.bold};
  font-size: ${theme.font.size.caption};
  border-radius: ${theme.borderRadius};

  :hover {
    background-color: ${theme.color.blue[100]};
  }
`;

export const StickyCard = styled(Card)`
  position: sticky;
  bottom: 0;
  border-radius: ${theme.borderRadius};
  background-color: transparent;
  z-index: 2;
`;

export const StyledLink = styled(Link)`
  color: ${theme.color.blue[600]} !important;
  text-decoration: underline !important;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  gap: ${theme.spacing.md};
`;
