import type { Klass, LexicalNode } from "lexical";
import { AutoLinkNode } from "@lexical/link";
import { ListNode, ListItemNode } from "@lexical/list";
import { HeadingNode } from "@lexical/rich-text";
import { TableCellNode, TableNode, TableRowNode } from "@lexical/table";
import { ImageNode } from "./nodes/ImageNode";
import { PageBreakNode } from "./nodes/PageBreakNode";
import { PlaceholderTextNode } from "./nodes/PlaceholderTextNode";

const SupportedLexicalNodes: Array<Klass<LexicalNode>> = [
  HeadingNode,
  ListNode,
  ListItemNode,
  PlaceholderTextNode,
  AutoLinkNode,
  ImageNode,
  TableCellNode,
  TableNode,
  TableRowNode,
  PageBreakNode,
];

export default SupportedLexicalNodes;
