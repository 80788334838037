import React from "react";
import { useTranslation } from "react-i18next";
import { NewModal, CTAButton } from "components";

type DeleteDocumentModalProps = {
  open: boolean;
  handleClose: () => void;
  handleOnDeleteDocument: () => void;
};

const DeleteDocumentModal = ({
  open,
  handleClose,
  handleOnDeleteDocument,
}: DeleteDocumentModalProps) => {
  const { t } = useTranslation();

  return (
    <NewModal
      open={open}
      handleClose={handleClose}
      title={t("pages.contractEdit.modals.deleteContractDocument.title")}
      body={t("pages.contractEdit.modals.deleteContractDocument.description")}
      footer={
        <>
          <CTAButton
            name={t("common.buttons.cancel")}
            onClick={handleClose}
            variant="secondary"
          />
          <CTAButton
            label="deleteDocumentConfirmationButton"
            name={t("common.buttons.delete")}
            onClick={handleOnDeleteDocument}
            color="red"
          />
        </>
      }
    />
  );
};

export default DeleteDocumentModal;
