import React from "react";
import { MessageBox } from "components";
import { MessageBoxWrapper } from "../../Webhooks/Webhook/styles";
import LampIcon from "assets/svg/bulb-icon.svg?react";
import { theme } from "theme";
import { useTranslation } from "react-i18next";

const SupportActiveMessageBox = () => {
  const { t } = useTranslation();

  return (
    <MessageBoxWrapper>
      <a href="#inviteSupportUserSection">
        <MessageBox
          icon={<LampIcon fill={theme.color.white} />}
          variation="standard"
          title={t("pages.settings.organization.general.supportActive")}
        />
      </a>
    </MessageBoxWrapper>
  );
};

export default SupportActiveMessageBox;
