import {
  $applyNodeReplacement,
  EditorConfig,
  LexicalNode,
  SerializedTextNode,
  Spread,
  TextNode,
} from "lexical";
import { addClassNamesToElement } from "@lexical/utils";

export const $isPlaceholderTextNode = (
  node: LexicalNode | null | undefined
): node is PlaceholderTextNode => node instanceof PlaceholderTextNode;

export type SerializedPlaceholderNode = Spread<
  {
    slug: string;
    fieldKey: string;
  },
  SerializedTextNode
>;

export class PlaceholderTextNode extends TextNode {
  /**
   * Stores a temporary (ephemeral) value for the placeholder node.
   * This value is used for syncing the content with external forms.
   * It does not immediately update the persisted text content.
   */
  private __ephemeralValue: string | null = null;

  static getType(): string {
    return "placeholder-text";
  }

  static clone(node: PlaceholderTextNode): PlaceholderTextNode {
    return new PlaceholderTextNode(
      node.__slug,
      node.__fieldKey,
      node.__text,
      node.__key
    );
  }

  static getDefaultText(slug: string, fieldKey: string): string {
    const renderedSlug = slug ? ` (${slug})` : "";

    return `${fieldKey}${renderedSlug}`;
  }

  constructor(
    private readonly __slug: string,
    private readonly __fieldKey: string,
    text?: string,
    key?: string
  ) {
    super(text ?? PlaceholderTextNode.getDefaultText(__slug, __fieldKey), key);
  }

  createDOM(config: EditorConfig): HTMLElement {
    const element = super.createDOM(config);
    addClassNamesToElement(element, "placeholder-text");
    return element;
  }

  static importJSON(
    serializedNode: SerializedPlaceholderNode
  ): PlaceholderTextNode {
    const node = $createPlaceholderTextNode(
      serializedNode.slug,
      serializedNode.fieldKey,
      serializedNode.text
    );
    node.setFormat(serializedNode.format);
    node.setDetail(serializedNode.detail);
    node.setMode(serializedNode.mode);
    node.setStyle(serializedNode.style);

    return node;
  }

  getSlug(): string {
    return this.__slug;
  }

  getFieldKey(): string {
    return this.__fieldKey;
  }

  isTextEntity(): boolean {
    return true;
  }

  canInsertTextBefore(): boolean {
    return false;
  }

  canInsertTextAfter(): boolean {
    return false;
  }

  /**
   * Checks if the node has an ephemeral value.
   *
   * This method determines whether a placeholder node should display a default label
   * or a label based on its ephemeral value. If an ephemeral value is set, this helps the TemplatePlugin
   * to generate a label based on the ephemeral value or, otherwise, it generate a default label based on slug and fieldKey
   *
   * @returns {boolean} True if the node has an ephemeral value, indicating that
   * a dynamic label should be displayed. False if no ephemeral value is set, in which
   * case a default label will be generated.
   */
  hasEphemeralValue(): boolean {
    return this.__ephemeralValue !== null;
  }

  /**
   * Retrieves the current ephemeral value of the node.
   * The ephemeral value is a temporary value used for syncing purposes
   * with external data, such as contract form fields.
   *
   * @returns {string | null} The ephemeral value of the node, or null if not set.
   */
  getEphemeralValue(): string | null {
    return this.__ephemeralValue;
  }

  /**
   * Sets a new ephemeral value for the node.
   * This method updates the internal state without modifying the
   * actual text content of the node.
   *
   * @param {string | null} value - The ephemeral value to set.
   * @returns {this} The updated PlaceholderTextNode instance.
   */
  setEphemeralValue(value: string | null): this {
    if (value === this.__ephemeralValue) {
      return this;
    }

    const self = this.getWritable();
    self.__ephemeralValue = value;
    return self;
  }

  exportJSON(): SerializedPlaceholderNode {
    return {
      ...super.exportJSON(),
      slug: this.__slug,
      fieldKey: this.__fieldKey,
      type: "placeholder-text",
      version: 1,
    };
  }
}

export function $createPlaceholderTextNode(
  slug: string,
  fieldKey: string,
  text?: string
): PlaceholderTextNode {
  const placeholderTextNode = new PlaceholderTextNode(slug, fieldKey, text);
  // Set the node to "token" mode to prevent direct modifications by the user
  placeholderTextNode.setMode("token");
  return $applyNodeReplacement(placeholderTextNode);
}

export function $isPlaceholderNode(
  node: LexicalNode | null | undefined
): node is PlaceholderTextNode {
  return node instanceof PlaceholderTextNode;
}
