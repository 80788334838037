import React, { useCallback, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import CrossIcon from "assets/svg/cross-icon.svg?react";
import CTAButton, { CTAButtonProps } from "components/CTAButton/CTAButton";
import IconWrapper from "new-components/IconWrapper/IconWrapper";
import { useTranslation } from "react-i18next";
import { theme } from "theme";
import {
  FloatingActionNumberSelectedText,
  FloatingActionToolbarButton,
  FloatingActionToolbarButtonGroup,
  FloatingActionToolbarDivider,
  FloatingActionToolbarAnchor,
  FloatingActionToolbarWrapper,
} from "./styles";

type Button = React.ReactElement<CTAButtonProps>;

interface FloatingActionToolbarBaseProps {
  /** Action buttons to be used in the toolbar */
  buttons?: Button | Button[];
  /** Number to be displayed in the toolbar */
  numberSelected?: number;
  /** Callback to close the toolbar
   * Should deslect all items */
  onClose?: React.MouseEventHandler<HTMLButtonElement>;
  /** Ref of the element the toolbar will float over */
  anchorRef?: React.RefObject<HTMLDivElement>;
  /** Position of the toolbar, overrides using the passed ref for positioning */
  position?: FloatingActionToolbarPosition;
}
interface FloatingActionToolbarWithRef extends FloatingActionToolbarBaseProps {
  /** Ref of the element the toolbar will float over */
  anchorRef: React.RefObject<HTMLDivElement>;
  /** Position of the toolbar */
  position?: never; // Cannot use position
}
interface FloatingActionToolbarWithPosition
  extends FloatingActionToolbarBaseProps {
  /** Ref of the element the toolbar will float over */
  anchorRef?: never; // Cannot use anchorRef
  /** Position of the toolbar */
  position: FloatingActionToolbarPosition; // Required if anchorRef is not provided
}

export type FloatingActionToolbarProps =
  | FloatingActionToolbarWithRef
  | FloatingActionToolbarWithPosition;

export type FloatingActionToolbarPosition = {
  top: number;
  left: number;
  width: number;
  height: number;
};

export const floatingActionToolbarInitialPosition = {
  top: 0,
  left: 0,
  width: 0,
  height: 0,
};
/** Floating toolbar to used to display actions for selected items */
const FloatingActionToolbar = ({
  buttons,
  numberSelected,
  onClose,
  anchorRef,
  position = floatingActionToolbarInitialPosition,
}: FloatingActionToolbarProps) => {
  const { t } = useTranslation();

  const [wrapperStyle, setWrapperStyle] =
    useState<FloatingActionToolbarPosition>(position);

  const handleClose = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      if (onClose) {
        onClose(event);
      }
    },
    [onClose]
  );

  const calculatePosition = useCallback(() => {
    if (anchorRef?.current) {
      const anchorRect = anchorRef.current.getBoundingClientRect();

      const top = anchorRect.top + window.scrollY + anchorRect.height / 2;
      const left = anchorRect.left + window.scrollX + anchorRect.width / 2;

      setWrapperStyle({
        top: top,
        left: left,
        width: anchorRect.width,
        height: anchorRect.height,
      });
    } else {
      setWrapperStyle(position);
    }
  }, [anchorRef, position]);

  useEffect(() => {
    calculatePosition();

    window.addEventListener("resize", calculatePosition);
    return () => {
      window.removeEventListener("resize", calculatePosition);
    };
  }, [anchorRef, position]);

  if (!numberSelected) {
    return null;
  }

  const buttonsArray =
    React.isValidElement(buttons) && buttons.props.children
      ? React.Children.toArray(buttons.props.children)
      : [buttons];

  return ReactDOM.createPortal(
    <FloatingActionToolbarAnchor role="dialog" $position={wrapperStyle}>
      <FloatingActionToolbarWrapper>
        <CTAButton label="Close" onClick={handleClose} variant="tertiary">
          <IconWrapper size={20} color="var(--color-primary)">
            <CrossIcon fontVariant={theme.font.size.icon} />
          </IconWrapper>
        </CTAButton>
        <FloatingActionNumberSelectedText>
          {t("common.components.floatingActionToolbar.selected", {
            numberSelected,
          })}
        </FloatingActionNumberSelectedText>
        {buttons && buttonsArray.length && (
          <FloatingActionToolbarButtonGroup>
            {buttonsArray.map((button) => {
              if (!React.isValidElement(button)) {
                return null;
              }

              return (
                <FloatingActionToolbarButton key={button.key}>
                  <FloatingActionToolbarDivider
                    flexItem
                    orientation="vertical"
                  />
                  {button}
                </FloatingActionToolbarButton>
              );
            })}
          </FloatingActionToolbarButtonGroup>
        )}
      </FloatingActionToolbarWrapper>
    </FloatingActionToolbarAnchor>,
    document.body
  );
};

export default FloatingActionToolbar;
