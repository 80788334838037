import styled from "@emotion/styled";
import { theme } from "theme";

export const TemplateComponentWrapper = styled.div<{ isDragging?: boolean }>`
  display: flex;
  gap: ${theme.spacing.sm};
  align-items: center;
  border: 1px solid ${theme.color.gray[300]};
  padding: ${theme.spacing.sm};
  border-radius: calc(${theme.labelBorderRadius} * 2);
  user-select: none;
  color: ${theme.color.gray[700]};
  max-width: 100%;
  cursor: ${({ isDragging }) => (isDragging ? "grabbing" : "grab")};
  transition: all 0.3s;

  &:hover {
    color: white;
    background-color: var(--color-primary-hover);
    border-color: var(--color-primary-hover);
  }
  &:active {
    background-color: var(--color-primary-active);
    border-color: var(--color-primary-active);
  }
`;

export const Input = styled.input`
  outline: none;
  border: 1px solid ${theme.color.gray[500]};
  border-radius: ${theme.labelBorderRadius};
  padding: ${theme.spacing.xs} ${theme.spacing.xs};
  width: 100%;
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-items: center;
  svg {
    fill: currentColor;
    max-width: 16px;
    max-height: 16px;
  }
`;

export const Name = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
