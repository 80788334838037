import {
  ContractBulkDownloadDTO,
  OrganizationNewService,
  OrganizationService,
} from "openapi";
import { useMutation, useQuery } from "@tanstack/react-query";

export const useOrganizationTeamsQuery = (id: string) => {
  return useQuery({
    queryKey: ["organizationTeams", id],
    queryFn: async () => {
      return await OrganizationService.getOrganizationById(id);
    },
  });
};

export const useOrganizationCurrentTeamQuery = (
  organizationId: string,
  currentTeamId: string
) => {
  return useQuery({
    queryKey: ["organizationCurrentTeam", organizationId, currentTeamId],
    queryFn: async () => {
      const currentTeam = await OrganizationNewService.getTeam(
        organizationId,
        currentTeamId
      );
      return currentTeam;
    },
    enabled: !!organizationId && !!currentTeamId,
  });
};

export const useOrganizationAllTeamsQuery = (organizationId: string) => {
  return useQuery({
    queryKey: ["organizationAllTeams", organizationId],
    queryFn: async () => {
      const teams = await OrganizationNewService.getTeams(organizationId);
      return teams;
    },
    enabled: !!organizationId,
  });
};

export const useOrganizationPermissionChangeLog = (organizationId: string) => {
  return useQuery({
    queryKey: ["permissionHistory", organizationId],
    queryFn: async () => {
      const permissionChangeLog =
        await OrganizationNewService.getOrganizationPermissionHistory(
          organizationId
        );
      return permissionChangeLog;
    },
    enabled: !!organizationId,
  });
};

export const useBulkDownloadDocuments = () => {
  return useMutation({
    mutationFn: async (data: {
      organizationId: string;
      requestBody: ContractBulkDownloadDTO;
    }) => {
      return OrganizationNewService.generateDownloadProcess(
        data.organizationId,
        data.requestBody
      );
    },
    onSuccess: (data, _variables) => {
      const downloadLink = document.createElement("a");
      downloadLink.href = `${window.location.origin}/api/organizations/download-documents/${data.id}`;
      downloadLink.download = import.meta.env.MODE;

      document.body.appendChild(downloadLink);

      downloadLink.click();

      document.body.removeChild(downloadLink);
    },
  });
};
