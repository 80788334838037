import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { People, FindInPage, BusinessOutlined } from "@mui/icons-material";
import { Wrapper } from "../styles";
import styled from "@emotion/styled";
import { useUserInfo } from "hooks";

export const StyledNavLink = styled(NavLink)`
  font-family: inherit; // TODO This is stupid, somehow the styled.button reset the font-family so I have to set it. Someone make it go away
`;

const setActiveLink = ({ isActive }: { isActive: boolean }) => {
  return isActive ? "active" : "";
};

const AdminMenu = () => {
  const { t } = useTranslation();
  const { userInfo } = useUserInfo();
  const isGerry = userInfo?.email === "gerry.koch@contracthero.com";
  const isWizard =
    userInfo &&
    [
      "jaqueline.winter@contracthero.com",
      "tobias.wilken@contracthero.com",
      "gerry.koch@contracthero.com",
    ].includes(userInfo.email);

  return (
    <Wrapper>
      {isWizard && (
        <StyledNavLink to="/kiosk/contracts" className={setActiveLink}>
          <>
            <FindInPage fontSize="small" />
            {t("pages.admin.tabTitles.pendingContracts")}
          </>
        </StyledNavLink>
      )}

      {isGerry && (
        <StyledNavLink to="/kiosk/users" className={setActiveLink}>
          <>
            <People fontSize="small" />
            {t("pages.admin.tabTitles.users")}
          </>
        </StyledNavLink>
      )}

      <StyledNavLink to="/kiosk/organizations" className={setActiveLink}>
        <>
          <BusinessOutlined fontSize="small" />
          {t("pages.admin.tabTitles.organizations")}
        </>
      </StyledNavLink>
    </Wrapper>
  );
};

export default AdminMenu;
