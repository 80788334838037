/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type ContractFlattenedData = {
  type: ContractFlattenedData.type;
  status: ContractFlattenedData.status;
  id: string;
  name: string;
  categoryId: string;
  teamId: string;
  parentId: string;
  createdBy: string;
  createdAt: string;
  updatedAt: string;
};

export namespace ContractFlattenedData {
  export enum type {
    MAIN_CONTRACT = "MAIN_CONTRACT",
    ATTACHMENT = "ATTACHMENT",
  }

  export enum status {
    DRAFT = "DRAFT",
    SIGNING = "SIGNING",
    PROCESSING = "PROCESSING",
    ACTIVE = "ACTIVE",
    INACTIVE = "INACTIVE",
    CANCELLED = "CANCELLED",
    TERMINATED = "TERMINATED",
  }
}
