import styled from "@emotion/styled";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import { breakpoints, theme } from "theme";
import { topBarHeight } from "layouts/CustomLayout/styles";

export const Wrapper = styled.div<{ open?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: calc(100vh - ${topBarHeight});
  width: ${({ open }) => (open ? "256px" : "68px")};
  background-color: ${({ open }) => (open ? theme.color.white : "transparent")};
  padding: ${theme.spacing.lg} ${theme.spacing.sm};
  transition: width 0.15s ease-in-out;
  border-right: 1px solid ${theme.color.gray[300]};
  z-index: 1;

  ${({ open }) =>
    open &&
    `
    width: 100%;
  `}

  max-width: 260px;
`;

export const ListItem = styled.div`
  display: flex;
`;

export const List = styled.div<{
  isOpen?: boolean;
  onClick?: () => void;
}>`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.xs};
  height: calc(
    100vh - 170px
  ); // header + teamswitcher + collapse button heights

  @media screen and (max-width: ${breakpoints.md}) {
    .MuiButtonBase-root {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
`;

export const ToggleWrapper = styled.div<{ open?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: ${({ open }: { open?: boolean }) =>
    open ? "flex-end" : "center"};
  width: 100%;
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  margin: ${theme.spacing.md} auto;
  background-color: ${theme.color.gray[300]};
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const Button = styled(ListItemButton)`
  border-radius: ${theme.borderRadius};
  gap: ${theme.spacing.sm};
  ${({ active }: { active?: string }) =>
    active === "true" &&
    `
    background-color: ${theme.color.blue[700]}14;
  `}
`;

export const Icon = styled(ListItemIcon)`
  align-items: center;
  justify-content: center;
  min-width: 20px !important;
  svg {
    fill: ${theme.color.gray[700]} !important;
  }

  ${({ active }: { active?: string }) =>
    active === "true" &&
    `
    svg {
      fill: ${theme.color.blue[700]} !important;
    }
  `}
`;

export const Label = styled(ListItemText)<{
  bold?: boolean;
  active?: string;
  visible?: boolean;
}>`
  color: ${theme.color.gray[700]};
  font-size: ${theme.font.size.body};
  opacity: 0;
  width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  transition: opacity 0.5s, max-width 0.5s ease-in-out;

  span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: ${({ bold }) =>
      bold ? theme.font.weight.bold : theme.font.weight.normal};
  }

  ${({ active }) =>
    active === "true" &&
    `
    color: ${theme.color.blue[600]};
  `}

  ${({ visible }) =>
    visible &&
    `
    opacity: 1;
    width: fit-content;
  `}
`;

export const MenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.xs};
`;
