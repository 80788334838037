/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { OverviewService } from "openapi";
import { useQuery } from "@tanstack/react-query";

export const useRecentlyAddedOverviewQuery = (teamId: string | undefined) => {
  return useQuery({
    queryKey: ["overview", "recentlyAdded", teamId],
    queryFn: () => OverviewService.getRecentlyAddedContractsOverview(teamId!),
    enabled: !!teamId,
  });
};

export const useRecentNotificationsOverviewQuery = (
  teamId: string | undefined
) => {
  return useQuery({
    queryKey: ["overview", "notifications", teamId],
    queryFn: () => OverviewService.getRecentNotificationsOverview(teamId!),
    enabled: !!teamId,
  });
};

export const useCategoriesOverviewQuery = (teamId: string | undefined) => {
  return useQuery({
    queryKey: ["overview", "categories", teamId],
    queryFn: () => OverviewService.getCategoriesOverview(teamId!),
    enabled: !!teamId,
  });
};

export const useSignatureOverviewQuery = (teamId: string | undefined) => {
  return useQuery({
    queryKey: ["overview", "signatures", teamId],
    queryFn: () => OverviewService.getSignatureOverview(teamId!),
    enabled: !!teamId,
  });
};

export const useStatisticsOverviewQuery = (teamId: string | undefined) => {
  return useQuery({
    queryKey: ["overview", "statistics", teamId],
    queryFn: () => OverviewService.getStatisticsOverview(teamId!),
    enabled: !!teamId,
  });
};

export const useFinancialOverviewQuery = (teamId: string | undefined) => {
  return useQuery({
    queryKey: ["overview", "financials", teamId],
    queryFn: () => OverviewService.getFinancialsOverview(teamId!),
    enabled: !!teamId,
  });
};
