import styled from "@emotion/styled";
import { CTAButton } from "components";
import { theme } from "theme";
import CustomTemplateIcon from "assets/svg/contract-template-icon.svg?react";
import CustomCalendarIcon from "assets/svg/calendar-icon.svg?react";
import { IconWrapper } from "new-components";

const navBarHeight = 64;
const pageHeaderHeight = 42;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 ${theme.spacing.sm} ${theme.spacing.sm};
`;

export const Container = styled.div`
  display: flex;
  height: calc(
    95vh - ${navBarHeight}px - ${pageHeaderHeight}px - ${theme.spacing.xl}
  );
  overflow: hidden;
`;

export const Filters = styled.div`
  display: flex;
  flex-direction: column;
  width: 200px;
  height: 100%;
  padding: ${theme.spacing.md};
  gap: ${theme.spacing.lg};
`;

export const AllButton = styled(CTAButton)`
  background-color: ${theme.color.gray[300]};
  color: ${theme.color.gray[900]};
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const Divider = styled.div`
  width: 100%;
  border-bottom: 1px solid ${theme.color.gray[300]};
`;

export const FilterSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.sm};
  max-height: 40%;

  button span {
    text-transform: uppercase;
  }

  :hover button span {
    color: ${theme.color.white};
  }
`;

export const FilterTitle = styled.span`
  font-size: ${theme.font.size.caption};
  color: ${theme.color.gray[700]};
`;

export const Description = styled.div`
  font-size: ${theme.font.size.body};
  margin-bottom: ${theme.spacing.lg};
`;

export const NoTemplatesMessage = styled.div`
  font-size: ${theme.font.size.subheading};
`;

export const SearchBlock = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  div:first-child {
    width: 50%;
  }
`;

export const TemplatesContainer = styled.div`
  flex: 1;
  padding: ${theme.spacing.xl};
  overflow: hidden;
`;

export const TemplatesGallery = styled.div<{ $isGridView: boolean }>`
  max-height: 90%;
  overflow: hidden;
  display: flex;
  flex-direction: ${({ $isGridView }) => ($isGridView ? "row" : "column")};
  flex-wrap: wrap;
  gap: ${({ $isGridView }) =>
    $isGridView ? theme.spacing.lg : theme.spacing.sm};
  padding-bottom: 40px;
`;

const commonTemplateCardStyles = `
  border-radius: 2.4px;
  box-shadow: ${theme.shadow.standard};
  width: 170px;
  height: 240px;
  cursor: pointer;
`;

export const CreateTemplateCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  gap: ${theme.spacing.sm};
  padding: ${theme.spacing.md} ${theme.spacing.md} ${theme.spacing.xl};
  background-color: ${theme.color.white};
  ${commonTemplateCardStyles}
`;

export const CreateNewButton = styled.div<{ $isGridView: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ $isGridView }) => {
    if ($isGridView) {
      return `
  width: 44px;
  height: 44px;
  `;
    }

    return `
  width: 22px;
  height: 22px;
`;
  }}
  font-size: ${theme.font.size.subheading};
  color: ${theme.color.blue[600]};
  border: 1px solid ${theme.color.blue[600]};
  border-radius: ${theme.borderRadius};
`;

export const CreateNewCaption = styled.span`
  color: ${theme.color.gray[600]};
  font-size: ${theme.font.size.caption};
  text-align: center;
`;

export const TemplateCard = styled.div<{ imageUrl?: string }>`
  ${commonTemplateCardStyles}
  background: url(${({ imageUrl }) => imageUrl});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;

export const List = styled.div`
  overflow-y: hidden;
`;

export const TagList = styled.div`
  overflow-y: hidden;
  display: flex;
  flex-wrap: wrap;
  gap: ${theme.spacing.xs};

  .tag div {
    cursor: pointer;
    border-radius: 4px;
  }

  .tag :hover {
    background-color: ${theme.color.gray[300]};
  }
`;

export const ListItem = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.sm};
  padding: ${theme.spacing.sm};
  cursor: pointer;
  border-radius: ${theme.borderRadius};
  transition: background-color 0.15s ease-in-out;

  span {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  :hover {
    background-color: ${theme.color.gray[300]};
  }
`;

export const TemplateInfo = styled.div`
  margin: ${theme.spacing.md} 0;
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.xs};
`;

const commonIconStyles = `
  display: flex;
  align-items: center;
  gap: ${theme.spacing.xs};
  color: ${theme.color.gray[700]};
`;

export const TemplateName = styled.div`
  ${commonIconStyles}
  font-weight: ${theme.font.weight.semiBold};
  span {
    max-width: 150px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`;

export const TemplateDate = styled.div`
  ${commonIconStyles}
  font-weight: ${theme.font.weight.normal};
`;

export const TemplateCategory = styled.div<{ color: string }>`
  padding: ${theme.spacing.sm};
  border-radius: ${theme.borderRadius};
  transition: background-color 0.15s ease-in-out;
  display: flex;
  align-items: center;
  gap: ${theme.spacing.sm};

  :hover {
    background-color: ${theme.color.gray[200]};
    cursor: pointer;
  }
`;

export const TemplateTags = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${theme.spacing.xs};
`;

export const TemplateIcon = styled(CustomTemplateIcon)`
  fill: ${theme.color.gray[700]};
`;

export const CalendarIcon = styled(CustomCalendarIcon)`
  fill: ${theme.color.gray[700]};
  width: 20px;
`;

export const ViewIconButton = styled(IconWrapper)`
  border-radius: ${theme.borderRadius};
  cursor: pointer;
  height: min-content;
  padding: ${theme.spacing.xs};

  :hover {
    background-color: ${theme.color.gray[300]};
  }
`;
