import { useCallback, useContext, useMemo } from "react";
import { useTeam } from "contexts/team/hooks";
import { useContractViewsQuery } from "shared/api/views";
import {
  ColumnMovedEvent,
  ColumnResizedEvent,
  ColumnVisibleEvent,
  FilterChangedEvent,
  NewColumnsLoadedEvent,
  SortChangedEvent,
} from "ag-grid-community";
import { OverviewActionContext, OverviewStateContext } from "./grid";
import { retrieveTemporarySearch } from "./storage";

/**
 * The custom views state hook is responsible for exposing the available custom views.
 * Besides that, it also provides AG Grid props that should be forward to the grid component,
 * responsible for handling temporary search inputs. The temporary search input is a feature
 * where the users search query will be persisted in the session storage, meaning the user can
 * navigate to another page and come back to the grid with the search query still present.
 */
const useOverviewState = () => {
  const context = useContext(OverviewStateContext);
  const { selectedTeamId } = useTeam();
  const { data: views } = useContractViewsQuery(selectedTeamId, context.type);

  if (!context) {
    throw new Error("can't use overview outside of OverviewProvider");
  }

  const getViewById = useCallback(
    (id: string) => {
      return views?.items.find((item) => item.id === id);
    },
    [views]
  );

  const gridProps = useMemo(
    () =>
      !context.activePreset
        ? {
            quickFilterText: retrieveTemporarySearch(context.type),
          }
        : {},
    [context]
  );

  return { overview: context, gridStateProps: gridProps, getViewById };
};

/**
 * Exposes AG Grid event handlers that should be forwarded as props to the AG Grid component.
 * It also exposes the reducers dispatch method, allowing to execute certain actions, like changing the
 * active view, or updating / resetting the grid state.
 *
 * The event handlers are responsible for forwarding grid changes that are relevant for
 * the implementation of custom views (e.g. column changes, filter changes, etc).
 */
const useOverviewActions = () => {
  const context = useContext(OverviewActionContext);

  if (!context) {
    throw new Error("can't use overview outside of OverviewContext");
  }

  const onColumnChangedEventHandler = useCallback(
    (
      e:
        | SortChangedEvent
        | ColumnResizedEvent
        | ColumnVisibleEvent
        | ColumnMovedEvent
    ) => {
      context.dispatch({
        type: "columnChanged",
        value: e,
      });
    },
    [context]
  );

  const onColumnDefinitionsLoadedEventHandler = useCallback(
    (e: NewColumnsLoadedEvent) => {
      context.dispatch({
        type: "columnDefinitionsUpdated",
        value: e,
      });
    },
    [context]
  );

  const onFilterChangedEventHandler = useCallback(
    (event: FilterChangedEvent) => {
      event.api.forEachNode((node) => {
        node.setExpanded(false);

        if (node.parent && node.parent.level >= 0) {
          return;
        }

        const childrenAfterFilterCount = node.childrenAfterFilter?.length ?? 0;
        const childrenAfterGroupCount = node.childrenAfterGroup?.length ?? 0;

        const activeColumnFilterCount = Object.keys(
          event.api.getFilterModel()
        ).length;

        if (childrenAfterFilterCount === 0) {
          return;
        }

        //customized behavior, the node should be expanded if
        //the children count after filtering is different from the children count before filtering.
        //Meaning we only expand the node, if the filtering has an effect on the children.
        if (
          childrenAfterFilterCount !== childrenAfterGroupCount ||
          activeColumnFilterCount > 0
        ) {
          node.setExpanded(true);
        }
      });
      context.dispatch({
        type: "filterChanged",
        value: event,
      });
    },
    [context]
  );

  const gridProps = useMemo(
    () => ({
      onSortChanged: onColumnChangedEventHandler,
      onColumnResized: onColumnChangedEventHandler,
      onColumnVisible: onColumnChangedEventHandler,
      onColumnMoved: onColumnChangedEventHandler,
      onNewColumnsLoaded: onColumnDefinitionsLoadedEventHandler,
      onFilterChanged: onFilterChangedEventHandler,
    }),
    [
      onColumnChangedEventHandler,
      onColumnDefinitionsLoadedEventHandler,
      onFilterChangedEventHandler,
    ]
  );

  return {
    overview: context,
    gridProps,
  };
};

export { useOverviewState, useOverviewActions };
