import React from "react";
import { useTranslation } from "react-i18next";
import { Wrapper, Icon, Text } from "./styles";
import Info from "assets/svg/contracthero-info.svg?react";
import DownloadIcon from "assets/svg/download-file-icon-2.svg?react";
import { CTAButton } from "components";
const DocumentEmptyPreview = ({
  onDownloadClick,
  infoText,
}: {
  onDownloadClick?: () => void;
  infoText: string;
}) => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <Icon>
        <Info />
      </Icon>
      <Text>{infoText}</Text>
      <CTAButton
        variant="tertiary"
        label="download-document-button"
        onClick={onDownloadClick}
        icon={<DownloadIcon fontSize="small" />}
        name={t("common.buttons.download")}
        iconSize="medium"
        hoverColor="blue"
      />
    </Wrapper>
  );
};

export default DocumentEmptyPreview;
