import React from "react";
import {
  ChangeLogDataItemType,
  ChangeLogDataSimpleItemType,
} from "../../types";
import EditEntityItem from "../EditEntityItem/EditEntityItem";
import { getFieldIcon } from "../LogEntity/LogEntity";
import { ContractFieldDTOV1 } from "openapi";
import { useTranslation } from "react-i18next";
import { useLocale } from "hooks";

export type SimpleFieldType = {
  fieldData: ChangeLogDataItemType;
  fieldVisibleId: string;
  field: ContractFieldDTOV1 | undefined;
  variant?: "row";
};

const SimpleField = ({
  fieldData,
  fieldVisibleId,
  field,
  variant,
}: SimpleFieldType) => {
  const { t } = useTranslation();
  const { locale } = useLocale();
  let fieldDataUpdated: ChangeLogDataItemType = {};

  let fieldName = field?.name[locale] || fieldVisibleId;

  switch (fieldVisibleId) {
    case "paymentCycle":
    case "paymentMethod":
    case "paymentType": {
      const fromValue = fieldData?.from?.value;
      const toValue = fieldData?.to?.value;
      const fromTranslation = fromValue
        ? t(`enums.${fieldVisibleId}.${fromValue}`)
        : "";
      const toTranslation = toValue
        ? t(`enums.${fieldVisibleId}.${toValue}`)
        : "";
      fieldDataUpdated = {
        from: { value: fromTranslation },
        to: { value: toTranslation },
      };
      break;
    }
    case "type": {
      fieldName = t("pages.contractDetails.general.type");
      const fromValue = (fieldData as unknown as ChangeLogDataSimpleItemType)
        ?.from;
      const toValue = (fieldData as unknown as ChangeLogDataSimpleItemType)?.to;
      const fromTranslation = fromValue ? t(`contractType.${fromValue}`) : "";
      const toTranslation = toValue ? t(`contractType.${toValue}`) : "";
      fieldDataUpdated = {
        from: { value: fromTranslation },
        to: { value: toTranslation },
      };
      break;
    }
    case "status": {
      const fromValue = (fieldData as unknown as ChangeLogDataSimpleItemType)
        ?.from;
      const toValue = (fieldData as unknown as ChangeLogDataSimpleItemType)?.to;
      const fromTranslation = fromValue ? t(`enums.status.${fromValue}`) : "";
      const toTranslation = toValue ? t(`enums.status.${toValue}`) : "";
      fieldDataUpdated = {
        from: { value: fromTranslation },
        to: { value: toTranslation },
      };
      fieldName = t("common.status");
      break;
    }
    case "name": {
      fieldName = t("pages.contractDetails.general.name");
      fieldDataUpdated = fieldData;
      break;
    }
    default: {
      fieldDataUpdated = fieldData;
      break;
    }
  }

  const fieldIcon = getFieldIcon(field?.type);
  return (
    <EditEntityItem
      data={fieldDataUpdated}
      fieldName={fieldName}
      fieldIcon={fieldIcon}
      variant={variant}
    />
  );
};

export default SimpleField;
