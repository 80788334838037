import React from "react";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import { Box, Grid, List, ListItem } from "@mui/material";
import CardCategoryHeaderWrapper from "components/CardCategoryHeaderWrapper/CardCategoryHeaderWrapper";
import CardBodyWrapper from "components/CardCenteredBodyWrapper/CardCenteredBodyWrapper";
import { DoughnutChart } from "components/Chart";
import { useTeam } from "contexts/team/hooks";
import { useLocale } from "hooks";
import { CategoryDTO } from "openapi";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import "overlayscrollbars/overlayscrollbars.css";
import { useEffect, useMemo, useState } from "react";
import { useOrganizationCategoriesQuery } from "shared/api";
import { useCategoriesOverviewQuery } from "shared/api/overview";
import CardWrapper from "../../../components/CardWrapper/CardWrapper";
import { WidgetProps } from "../Dashboard";
import { getSelectedData } from "../utils";
import {
  CategoryColorBlock,
  CategoryStatistics,
  CategoryTitle,
} from "./styles";

const Categories = ({ statisticsViewMode }: WidgetProps) => {
  const { locale } = useLocale();
  const { selectedTeamId, organizationId } = useTeam();
  const [showPercent, setShowPercent] = useState(false);
  const [numberOfContractsWithCategory, setNumberOfContractsWithCategory] =
    useState<number>(0);

  const { data } = useCategoriesOverviewQuery(selectedTeamId);
  const { data: categories } = useOrganizationCategoriesQuery(organizationId);
  const selectedData = getSelectedData(statisticsViewMode, data);

  const overviewCategoriesData = useMemo(() => {
    if (!selectedData || !categories) {
      return [];
    }

    return selectedData?.map((data) => {
      const newCategory = categories?.find((category) => {
        return category.id === data.category.id;
      });

      return {
        ...data,
        category: newCategory as CategoryDTO,
      };
    });
  }, [selectedData, categories]);

  const getStatistics = (counter: number, showPercent: boolean) => {
    if (showPercent) {
      const calculatedPercentage = parseFloat(
        ((counter / numberOfContractsWithCategory) * 100).toFixed(1)
      );
      return `${calculatedPercentage}%`;
    } else {
      return counter;
    }
  };

  useEffect(() => {
    if (selectedData && selectedData.length > 0) {
      [...selectedData].sort((a, b) => b.count - a.count);
      setNumberOfContractsWithCategory(
        selectedData
          .map((item) => item.count)
          .reduce((prev, next) => prev + next, 0)
      );
    }
  }, [selectedData]);

  return (
    <CardWrapper>
      <Box>
        <CardCategoryHeaderWrapper
          titleKey="pages.dashboard.categories.title"
          setShowPercent={setShowPercent}
          numberOfContracts={numberOfContractsWithCategory}
          icon={<LocalOfferIcon sx={{ minWidth: "18px" }} />}
        />
        {selectedData && selectedData.length > 0 ? (
          <Grid container sx={{ marginTop: "2rem" }}>
            <Grid item xs={6} alignItems="baseline">
              <DoughnutChart data={overviewCategoriesData} />
            </Grid>
            <Grid item xs={6} sx={{ position: "relative" }} alignItems="center">
              <OverlayScrollbarsComponent
                defer
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  overflowY: "auto",
                }}
              >
                <List>
                  {overviewCategoriesData.map(({ category, count }, index) => {
                    const statistics = getStatistics(count, showPercent);

                    return (
                      <ListItem
                        key={index}
                        sx={{ justifyContent: "space-between" }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <CategoryColorBlock color={category.color} />
                          <CategoryTitle>{category.name[locale]}</CategoryTitle>
                        </Box>
                        <CategoryStatistics>{statistics}</CategoryStatistics>
                      </ListItem>
                    );
                  })}
                </List>
              </OverlayScrollbarsComponent>
            </Grid>
          </Grid>
        ) : (
          <CardBodyWrapper textKey="pages.dashboard.categories.description" />
        )}
      </Box>
    </CardWrapper>
  );
};

export default Categories;
