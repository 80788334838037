import React, { useState } from "react";
import { getFieldIcon } from "pages/Contract/components/ContractChangeLog/components/LogEntity/LogEntity";
import { IconWrapper, TemplateComponentWrapper, Name } from "./styles";
import { TextEditorField } from "new-components/TextEditor/TextEditor";

export type TemplateComponentProps = {
  draggable?: boolean;
  datapoint: TextEditorField;
};

const PlaceholderComponent = ({
  draggable,
  datapoint,
}: TemplateComponentProps) => {
  const [isDragging, setIsDragging] = useState(false);
  const fieldIcon = getFieldIcon(datapoint.type);

  return (
    <TemplateComponentWrapper
      draggable={draggable}
      onDragStart={(e) => {
        e.dataTransfer.setData(
          "text/plain",
          JSON.stringify({ draggable, datapoint })
        );
        e.dataTransfer.effectAllowed = "copy";

        // @Note: Create a clone with exact dimensions
        const originalElement = e.currentTarget;
        const rect = originalElement.getBoundingClientRect();

        // @Note: Create a drag ghost image to maintain opacity
        const draggableIndicator = e.currentTarget.cloneNode(
          true
        ) as HTMLElement;
        document.body.appendChild(draggableIndicator);
        e.dataTransfer.setDragImage(draggableIndicator, 0, 0);

        draggableIndicator.style.cssText = `
          position: absolute;
          left: -9999px;
          top: -9999px;
          width: ${rect.width}px;
          height: ${rect.height}px;
          opacity: 0.25;
          pointer-events: none;
          margin: 0;
          padding: 0;
        `;

        // @Note: Remove the draggableIndicator element after drag starts
        requestAnimationFrame(() => {
          document.body.removeChild(draggableIndicator);
        });

        setIsDragging(true);
      }}
      contentEditable={false}
      onDrag={() => setIsDragging(true)}
      onDragEnd={() => setIsDragging(false)}
      isDragging={isDragging}
    >
      <IconWrapper>{fieldIcon}</IconWrapper>
      <Name
        draggable={draggable}
        className={isDragging ? "dragging" : ""}
        aria-label={`placeholder-name-${datapoint.id}`}
      >
        {datapoint.display}
      </Name>
    </TemplateComponentWrapper>
  );
};
export default PlaceholderComponent;
