import styled from "@emotion/styled";
import { breakpoints, theme } from "theme";

export const EditorInner = styled.div<{ zoom?: number; compact?: boolean }>`
  background-color: var(--color-white);
  position: relative;
  size: A4;
  box-shadow: ${theme.shadow.contractTemplate};

  ${({ compact }) =>
    compact &&
    `
    margin: ${theme.spacing.md} 0;
    left: 50%;
    transform: translateX(-50%);
    width: 210mm;
  `}

  ${({ zoom }) => (zoom != null ? `zoom: ${zoom};` : "")}

  @media (max-width: ${breakpoints.xl}) {
    size: initial;
    width: calc(100% - var(--spacing-xl));
    margin-top: ${theme.spacing.sm};
  }
`;
