import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFormContext, useWatch } from "react-hook-form";
import { Box, Grid } from "@mui/material";
import { CategoryDTO, ContractDTOV1 } from "openapi";
import { FormTextField } from "components";
import {
  FormSelect,
  FormSelectItem,
} from "components/FormItems/FormSelect/FormSelect";
import HeaderWrapper from "./HeaderWrapper";
import Header from "./Header";
import CardWrapper from "components/CardWrapper/CardWrapper";
import { useLocale } from "hooks";

type ContractHeaderFormType = {
  name?: string;
  categoryId?: string | null;
  editable: boolean;
  status?: ContractDTOV1.status;
  reminderAt?: string | null;
  categories?: CategoryDTO[];
  contract: ContractDTOV1;
  category: CategoryDTO;
  fetchData: () => Promise<void>;
  getMainContractCategoryId: () => string | undefined;
  isCategoryDisabled?: boolean;
  setShowSaveButton: (value: boolean) => void;
};

const HeaderSection = ({
  categories,
  editable,
  contract,
  category,
  fetchData,
  getMainContractCategoryId,
  isCategoryDisabled,
  setShowSaveButton,
}: ContractHeaderFormType) => {
  const { locale } = useLocale();
  const { t } = useTranslation();
  const [categoryOptions, setCategoryOptions] = useState<FormSelectItem[]>([]);
  const { control, setError, clearErrors } =
    useFormContext<ContractHeaderFormType>();

  const contractStatus = useWatch({
    name: "status",
    control: control,
  });

  const contractTitle = useWatch({
    name: "name",
    control: control,
  });

  useEffect(() => {
    if (contractTitle) {
      clearErrors("name");
      setShowSaveButton(true);
    } else {
      setError("name", {
        type: "required",
        message: t(
          "pages.contractEdit.forms.generalForm.validation.isNotBlank"
        ),
      });
      setShowSaveButton(false);
    }
  }, [contractTitle]);

  const fillCategories = (categoriesData: CategoryDTO[]) => {
    setCategoryOptions(
      categoriesData.map((item) => {
        return {
          key: item.id,
          value: item.name[locale],
          color: item.color,
        };
      })
    );
  };

  const statusOptions = Object.keys(ContractDTOV1.status).filter((status) => {
    if (status === contractStatus) {
      return true;
    }
    return (
      status !== ContractDTOV1.status.SIGNING &&
      status !== ContractDTOV1.status.PROCESSING
    );
  });

  useEffect(() => {
    if (categories) fillCategories(categories);
  }, [categories]);

  return (
    <CardWrapper>
      {editable ? (
        <>
          <FormTextField
            name="name"
            label={t("pages.contractEdit.forms.generalForm.contractTitle")}
            control={control}
          />

          <Grid container columnSpacing={1.5}>
            <Grid item xs={12} md={6}>
              <Box className="flex-align-center">
                <FormSelect
                  name="categoryId"
                  options={categoryOptions}
                  sx={{ flex: "1", width: "unset" }}
                  label={t("pages.contractEdit.forms.generalForm.category")}
                  control={control}
                  disabled={isCategoryDisabled}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormSelect
                name="status"
                options={statusOptions}
                inputProps={{
                  disabled: contractStatus === ContractDTOV1.status.SIGNING,
                }}
                translationPrefix={"enums.status"}
                label={t("common.status")}
                control={control}
              />
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          <HeaderWrapper
            titleKey="pages.contractDetails.general.cardTitle"
            mainTitle={contract.name}
            isMainContract={contract.parentId === null}
            contractData={contract}
            mainContractCategoryId={getMainContractCategoryId()}
            contractId={contract.id}
            refetchData={fetchData}
          />

          <Header contract={contract} category={category} />
        </>
      )}
    </CardWrapper>
  );
};

export default HeaderSection;
