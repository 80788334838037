import React, {
  createContext,
  useState,
  useContext,
  ReactNode,
  useRef,
} from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import { EditorState, LexicalEditor } from "lexical";
import { ContractTemplateService } from "openapi";
import routePaths from "constants/routePaths";
import { AcceptedFileType } from "../../shared/enums/document.enum";
import { useDeleteDocumentMutation } from "../../shared/api";
import { useUpdateDocumentFileMutation } from "../../shared/api/documents";
import { filterEmptyPlaceholdersInEditorState } from "new-components/TextEditor/utils";

export enum viewModeType {
  VIEW = "view",
  EDIT = "edit",
}

type UpdateDocumentParams = {
  organizationId: string;
  templateId?: string;
  contractId: string;
  contractTeamId: string;
  documentId?: string;
};

interface ContractDetailsContextType {
  viewMode?: viewModeType | null;
  setViewMode: (mode: viewModeType) => void;
  isLoadingCreatePDFDocument: boolean;
  hasCreatedPDF?: boolean;
  createPDFDocument: (
    params: UpdateDocumentParams & { refetch: () => void }
  ) => Promise<void>;
  updateDocument: (params: UpdateDocumentParams) => Promise<void>;
  deleteDocument: (params: UpdateDocumentParams) => Promise<void>;
  editorRef: React.RefObject<LexicalEditor>;
  localEditorState?: EditorState | null;
  setLocalEditorState: (state: EditorState) => void;
}

export const ContractDetailsContext = createContext<
  ContractDetailsContextType | undefined
>(undefined);

export const ContractDetailsProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const { t } = useTranslation();
  const { mode } = useParams();
  const [searchParams] = useSearchParams();
  const modeAsSearchParam = searchParams.get("mode");

  const [viewMode, setViewMode] = useState<viewModeType>(
    mode === viewModeType.EDIT || modeAsSearchParam === viewModeType.EDIT
      ? viewModeType.EDIT
      : viewModeType.VIEW
  );
  const navigate = useNavigate();
  const [localEditorState, setLocalEditorState] =
    useState<EditorState | null>();
  const [isLoadingCreatePDFDocument, setIsLoadingCreatePDFDocument] =
    useState(false);
  const [hasCreatedPDF, setHasCreatedPDF] = useState(false);
  const { mutateAsync: deleteContractDocument } = useDeleteDocumentMutation();
  const { mutateAsync: updateDocumentFile } = useUpdateDocumentFileMutation();
  const editorRef = useRef<LexicalEditor>(null);

  const updateDocument = async ({
    organizationId,
    templateId,
    contractId,
    contractTeamId,
    documentId,
  }: UpdateDocumentParams) => {
    try {
      if (!contractId || !templateId || !documentId || !contractTeamId) return;

      const editorState = editorRef.current?.getEditorState();

      if (!editorState) return;

      const serializedState = editorState.toJSON();
      const file = new File(
        [
          JSON.stringify({
            templateId,
            content: serializedState,
          }),
        ],
        "contracthero/lexical",
        { type: AcceptedFileType.LEXICAL }
      );

      await updateDocumentFile({
        organizationId,
        teamId: contractTeamId,
        documentId,
        contractId,

        file,
      });

      enqueueSnackbar(
        t("pages.contractDetails.notifications.success_update_document"),
        {
          variant: "success",
        }
      );
    } catch (error) {
      enqueueSnackbar(
        t("pages.contractDetails.notifications.error_update_document"),
        {
          variant: "error",
        }
      );
    }
  };

  const createPDFDocument = async ({
    refetch,
    documentId,
    organizationId,
    contractId,
    contractTeamId,
  }: UpdateDocumentParams & { refetch: () => void }) => {
    try {
      setIsLoadingCreatePDFDocument(true);

      const editorState = editorRef.current?.getEditorState().clone();
      if (!editorState) return;

      const filteredEditorState =
        filterEmptyPlaceholdersInEditorState(editorState);

      const pdf = (await ContractTemplateService.renderContractTemplate(
        organizationId,
        {
          editorState: JSON.stringify(filteredEditorState),
        }
      )) as Blob;

      if (!contractId || !pdf || !documentId) return;

      await updateDocumentFile({
        organizationId,
        teamId: contractTeamId,
        documentId,
        contractId,
        file: pdf,
      });

      navigate(`${routePaths.CONTRACTS}/${contractId}`);
      setHasCreatedPDF(true);
      setViewMode(viewModeType.VIEW);
      setIsLoadingCreatePDFDocument(false);
      void refetch();

      enqueueSnackbar(
        t("pages.contractDetails.notifications.success_create_pdf_document"),
        {
          variant: "success",
        }
      );
    } catch (error) {
      enqueueSnackbar(
        t("pages.contractDetails.notifications.error_create_pdf_document"),
        {
          variant: "error",
        }
      );
    }
  };

  const deleteDocument = async ({
    contractId,
    documentId,
    contractTeamId,
    organizationId,
  }: UpdateDocumentParams) => {
    try {
      await deleteContractDocument({
        organizationId,
        teamId: contractTeamId,
        contractId: contractId,
        documentId,
      });

      enqueueSnackbar(
        t("pages.contractDetails.notifications.success_delete_document"),
        {
          variant: "success",
        }
      );
    } catch (error) {
      enqueueSnackbar(
        t("pages.contractDetails.notifications.error_delete_document"),
        {
          variant: "error",
        }
      );
    }
  };

  return (
    <ContractDetailsContext.Provider
      value={{
        viewMode,
        setViewMode,
        isLoadingCreatePDFDocument,
        createPDFDocument,
        updateDocument,
        deleteDocument,
        editorRef,
        localEditorState,
        setLocalEditorState,
        hasCreatedPDF,
      }}
    >
      {children}
    </ContractDetailsContext.Provider>
  );
};

export const useContractDetails = () => {
  const context = useContext(ContractDetailsContext);
  if (context === undefined) {
    throw new Error(
      "useContractDetails must be used within a ContractDetailsProvider"
    );
  }
  return context;
};
