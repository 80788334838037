import {
  TextDatapointDTO,
  ContactDatapointDefinitionDTO,
  ContactTypeDTO,
} from "openapi";
import { ContactDataDTO } from "../Contacts/ContactDataDTO";

export const formatContactName = (contact?: ContactDataDTO) => {
  if (!contact) {
    return "";
  }
  return (contact["name"] as TextDatapointDTO)?.value?.value ?? "";
};

export const initFormFields = (
  contactDataPointDefinitions: ContactDatapointDefinitionDTO[],
  contactType: ContactTypeDTO
) => {
  const newObj = {};

  contactType?.sections?.forEach((section) => {
    section.definitions.forEach((definition) => {
      const fieldName = contactDataPointDefinitions.find(
        (d) => d.id === definition.id
      )?.visibleId;

      Object.assign(newObj, {
        [fieldName as string]: {
          value: {
            value: "",
          },
        },
      });
    });
  });

  return newObj;
};

export const removeEmptyValues = (requestBody: Partial<ContactDataDTO>) => {
  const data = requestBody;
  for (const key in data) {
    const field = data[key];
    if (
      field &&
      typeof field === "object" &&
      "value" in field &&
      "value" in field.value &&
      field.value.value === ""
    ) {
      // Remove the field if `value.value` is an empty string
      delete data[key];
    }
  }
  return data;
};
