import { ColumnResizedEvent, ColumnState } from "ag-grid-community";
import { ContractViewItemDto } from "openapi";
import { AvailableTemplates, availableTemplates } from "./default";
import {
  areUnsavedFilterChangesPresent,
  areUnsavedColumnChangesPresent,
} from "./helpers";
import {
  retrieveTemporaryState,
  retrieveTemporaryFilter,
  retrieveActiveView,
  persistActiveView,
  persistTemporaryFilter,
  persistTemporaryState,
  clearTemporaryState,
} from "./storage";
import {
  ColumnChangedAction,
  FilterChangedAction,
  NotifyUnmountAction,
  OverviewAction,
  OverviewInitAction,
  ResetAction,
  SetActiveViewAction,
} from "./types";

/**
 * The `initializeOverview` function is an action handler for initializing the AG Grid state.
 * It retrieves the initial column state from the AG Grid instance and determines the active view
 * based on the current state and action.
 *
 * If an active preset is available, it initializes the grid with the preset's column state
 * and filter model. If no active preset is available but an active view ID is present,
 * it checks if the active view ID corresponds to a template key and initializes the grid
 * accordingly. If no active view or preset is found, it initializes the grid with the
 * state and filters stored in the session storage.
 *
 * Finally, it updates the state with the initialized grid state,
 * including column state, filter model, and active view, and sets flags for
 * unsaved filter and column changes.
 */
export const initializeOverview: OverviewAction<OverviewInitAction> = (
  prevState,
  action
) => {
  let columnState: ColumnState[];
  let filterModel: Record<string, unknown>;

  const columnApi = action.value.gridRef.current?.columnApi;
  if (!columnApi) {
    return prevState;
  }

  const initialColumnState = columnApi.getColumnState();
  const activePreset = prevState.activePreset;

  const activeViewId = retrieveActiveView(prevState.type);
  let activeView = action.value.determineActiveView(activeViewId);

  const getGridStateWithTemplate = (template: AvailableTemplates) => {
    const presetFunction = availableTemplates[template];
    if (presetFunction) {
      const template = presetFunction(initialColumnState);
      return {
        activeView: template,
        columnState: JSON.parse(template.data) as ColumnState[],
        filterModel: template.filter
          ? (JSON.parse(template.filter) as Record<string, unknown>)
          : {},
      };
    }
  };

  if (initialColumnState.length > 0) {
    let gridState: ReturnType<typeof getGridStateWithTemplate> | null = null;
    if (activePreset) {
      // if there is an active preset, we need to initialize the grid with the preset
      // a preset is for example the `task_due_date_view` or `contracts_without_reminders_view`
      gridState = getGridStateWithTemplate(activePreset);
    } else if (!activeView && activeViewId) {
      // if there is no active preset, we need to check if the activeViewId is actually a template key
      // this can happen if the user navigates away from the page and comes back
      // in this case the activeViewId present in the session storage is actually a template key
      gridState = getGridStateWithTemplate(activeViewId as AvailableTemplates);
      // most of the templates should not be persisted
      // e.g. if the user navigates away from the `task_due_date_view` and navigates back,
      // the grid should not be initialized with the `task_due_date_view` again.
      // in that case, we need to ignore the session storage state and set the gridState to null
      if (!gridState?.activeView.persist) {
        clearTemporaryState(prevState.type);
        gridState = null;
      }
    }

    if (gridState) {
      persistActiveView(prevState.type, gridState.activeView.id);
      activeView = gridState.activeView;
      columnState = gridState.columnState;
      filterModel = gridState.filterModel;
    }
  }

  // if there is no active view or preset, we initialize the grid with the state and filters
  // stored in the session storage
  columnState ??= retrieveTemporaryState(prevState.type) ?? [];
  filterModel ??= retrieveTemporaryFilter(prevState.type) ?? {};

  return {
    ...prevState,
    agGrid: {
      initialized: true,
      gridRef: action.value.gridRef,
      filterModel: filterModel,
      columnState: columnState,
      initialColumnState: initialColumnState,
    },
    activeView: activeView,
    unsavedFilterChangePresent: areUnsavedFilterChangesPresent(
      filterModel,
      activeView
    ),
    unsavedColumnChangePresent: areUnsavedColumnChangesPresent(
      prevState,
      columnState,
      activeView
    ),
  };
};
/**
 * The `resetOverview` function is an action handler for resetting the AG Grid state.
 * It clears any temporary state associated with the grid type and returns a new state
 * object with the AG Grid's filter model and column state reset to their initial values.
 */
export const resetOverview: OverviewAction<
  ResetAction | NotifyUnmountAction
> = (prevState, action) => {
  if (!prevState.agGrid.initialized) {
    return prevState;
  }

  /** Todo: If we are navigating bw same prevState.type i.e contracts main/sub pages, and we
   * already have data in prevState or in session there is no need to clearState below on notifyUnmount.
   **/

  clearTemporaryState(prevState.type);

  return {
    ...prevState,
    agGrid: {
      ...prevState.agGrid,
      filterModel: {},
      columnState: [],
    },
    unsavedColumnChangePresent: false,
    unsavedFilterChangePresent: false,
    activeView: undefined,
    activePreset: undefined,
  };
};

/**
 * The `setActiveView` function is an action handler for setting the active view in the AG Grid state.
 * It first determines if the provided view ID corresponds to a template.
 * If it is a template and the initial column state is not set,
 * it updates the state with the active preset.
 *
 * If the initial column state is set, it retrieves the template and sets it as the
 * active view.
 *
 * If the view ID does not correspond to a template,it determines the active view
 * using the provided `determineActiveView` method.
 * The function then merges the persisted view's column state with the initial column state to ensure consistency.
 * It also parses the view's filter model if available.
 *
 * The active view, column state, and filter model are persisted using `persistActiveView`, `persistTemporaryFilter`, and `persistTemporaryState` functions.
 * Finally, the state is updated with the new active view, column state, filter model, and flags for unsaved column and filter changes are set to false.
 */
export const setActiveView: OverviewAction<SetActiveViewAction> = (
  prevState,
  action
) => {
  let view: ContractViewItemDto | undefined;
  const isTemplate = action.value.id in availableTemplates;
  if (isTemplate) {
    // If the initial column state is not set, update the state with the active preset
    // applying the template to the initial column state will be handled by the
    // `initializeOverview` action handler once its called.
    if (!prevState.agGrid.initialColumnState) {
      return {
        ...prevState,
        activePreset: action.value.id as AvailableTemplates,
      };
    }
    const template = availableTemplates[action.value.id as AvailableTemplates](
      prevState.agGrid.initialColumnState
    );
    view = template;
  } else {
    view = action.value.determineActiveView(action.value.id);
  }

  if (!view) {
    return prevState;
  }

  let viewColumnState = JSON.parse(view.data) as ColumnState[];

  if (prevState.agGrid.initialColumnState) {
    // the persisted user view might not contain all columns that are present in the initial column state
    // to make sure the view is consistent, we need to merge the persisted view with the initial column state
    // otherwise columns that the customer enabled on his view, that were not present when the custom view was created
    // would still be shown after the view was switched
    viewColumnState = prevState.agGrid.initialColumnState
      .map((initialState) => {
        return (
          viewColumnState.find((state) => state.colId === initialState.colId) ??
          initialState
        );
      })
      .sort((a, b) => {
        const aIndex = viewColumnState.findIndex(
          (state) => state.colId === a.colId
        );
        const bIndex = viewColumnState.findIndex(
          (state) => state.colId === b.colId
        );
        return aIndex - bIndex;
      });
  }

  const viewFilterModel = view.filter
    ? (JSON.parse(view.filter) as Record<string, unknown>)
    : {};

  persistActiveView(prevState.type, view.id);
  persistTemporaryFilter(prevState.type, viewFilterModel);
  persistTemporaryState(prevState.type, viewColumnState);

  return {
    ...prevState,
    activeView: view,
    activePreset: isTemplate
      ? (action.value.id as AvailableTemplates)
      : undefined,
    agGrid: {
      ...prevState.agGrid,
      columnState: viewColumnState,
      filterModel: viewFilterModel,
    },
    unsavedColumnChangePresent: false,
    unsavedFilterChangePresent: false,
  };
};

/**
 * Whenever the user changes the filter, we persist the filter model in the session storage.
 * We also check if there are any unsaved changes present, by comparing the current filter model
 * with the active view or the initial filter state.
 */
export const filterChanged: OverviewAction<FilterChangedAction> = (
  prevState,
  action
) => {
  if (!prevState.agGrid.initialized) {
    return prevState;
  }

  const gridApi = prevState.agGrid.gridRef.current;
  if (!gridApi) {
    return prevState;
  }

  const filterModel = gridApi.api.getFilterModel();
  // persist the filter model in the session storage, in case the user navigates away from the page
  // and we need to restore it when the user comes back
  persistTemporaryFilter(prevState.type, filterModel);

  if (action.value.columns.length < 1) {
    return prevState;
  }

  return {
    ...prevState,
    agGrid: {
      ...prevState.agGrid,
      filterModel: filterModel,
    },
    unsavedColumnChangePresent: areUnsavedColumnChangesPresent(
      prevState,
      prevState.agGrid.columnState,
      prevState.activeView
    ),
    unsavedFilterChangePresent: areUnsavedFilterChangesPresent(
      filterModel,
      prevState.activeView
    ),
  };
};

export const columnChanged: OverviewAction<ColumnChangedAction> = (
  prevState,
  action
) => {
  if (!prevState.agGrid.initialized) {
    return prevState;
  }

  const columnApi = prevState.agGrid.gridRef.current?.columnApi;
  if (!columnApi) {
    return prevState;
  }

  if (
    action.value.type === "columnResized" &&
    !(action.value as ColumnResizedEvent).finished
  ) {
    // we only want to persist the column state if the user has finished resizing the column,
    // otherwise the column state would be updated on every resize event which will
    // cause performance issues.
    return prevState;
  }

  const columnState = columnApi.getColumnState();
  // persist the column state in the session storage, in case the user navigates away from the page
  // and we need to restore it when the user comes back
  persistTemporaryState(prevState.type, columnState);

  return {
    ...prevState,
    agGrid: {
      ...prevState.agGrid,
      columnState: columnState,
    },
    unsavedColumnChangePresent: areUnsavedColumnChangesPresent(
      prevState,
      columnState,
      prevState.activeView
    ),
    unsavedFilterChangePresent: areUnsavedFilterChangesPresent(
      prevState.agGrid.filterModel,
      prevState.activeView
    ),
  };
};
