import { makeStyles } from "@mui/styles";
import { theme } from "theme";

const sharedStyles = {
  "&.MuiButtonBase-root": {
    fontSize: ".8rem",
    fontWeight: "500",
    border: "1px solid #e2e8f0",
    borderRadius: "0.375rem",
    padding: "0.5rem 1rem 0.5rem 1rem",
    boxShadow: "0 .125rem .25rem rgba(0,0,0,.075)!important",
    lineHeight: "inherit",
    color: "#4299e1",
    backgroundColor: "#e9f3ff",
    textTransform: "none",
    "&:hover": {
      cursor: "pointer",
      background: "#e9f3ff",
    },
  },
};

export const useStyles = makeStyles({
  baseButton: {
    "&.MuiButtonBase-root": {
      padding: "0.5rem 3rem",
      backgroundColor: "#e9f3ff",
      marginTop: "5px",
      color: "#4299e1",
      fontWeight: "700",
      textTransform: "none",
      borderRadius: "0.25rem",
      border: "0px",
      fontSize: ".92rem",
      minWidth: "180px",
      "&:hover": {
        background: "#e9f3ff",
      },
    },
  },
  baseButtonRed: {
    "&.MuiButtonBase-root": {
      padding: "0.5rem 3rem",
      backgroundColor: "#e53e3e99",
      marginTop: "5px",
      color: "#fff",
      fontWeight: "700",
      textTransform: "none",
      borderRadius: "0.25rem",
      border: "0px",
      fontSize: ".92rem",
      "&:hover": {
        background: "#e53e3eaa",
      },
    },
  },
  baseButtonGray: {
    "&.MuiButtonBase-root": {
      padding: "0.5rem 3rem",
      backgroundColor: "#77777788",
      marginTop: "5px",
      color: "#fff",
      fontWeight: "700",
      textTransform: "none",
      borderRadius: "0.25rem",
      border: "0px",
      fontSize: ".92rem",
      minWidth: "180px",
      "&:hover": {
        background: "#777777aa",
      },
    },
  },
  baseButtonBlue: {
    "&.MuiButtonBase-root": {
      padding: "0.5rem 3rem",
      backgroundColor: theme.color.blue[600],
      textTransform: "none",
      fontWeight: "700",
      fontSize: ".92rem",
      color: "#fff",
      "&:hover": {
        background: theme.color.blue[600],
      },
    },
  },
  baseButtonNoBackground: {
    "&.MuiButtonBase-root": {
      padding: `${theme.spacing.sm} ${theme.spacing.lg}`,
      marginTop: "5px",
      color: `${theme.color.blue[600]}`,
      fontWeight: "700",
      textTransform: "none",
      border: "0px",
      fontSize: ".92rem",
    },
  },
  previewHeaderButton: {
    "&.MuiButtonBase-root": {
      marginRight: "0.25rem",
      marginTop: "0.25rem",
      fontSize: "0.75rem",
      fontWeight: "500",
      border: "1px solid #e2e8f0",
      borderRadius: "0.25rem",
      backgroundColor: "#fff",
      padding: "0.375rem 0.625rem",
      textOpacity: "1",
      color: "#4a5568",
      textTransform: "none",
      minWidth: "50px",
      "&:hover": {
        cursor: "pointer",
        textDecoration: "underline",
        backgroundColor: "f7fafc",
        color: "#3182ce",
      },
      "&:disabled": {
        opacity: "0.5",
      },
    },
  },
  previewHeaderDeleteButton: {
    "&.MuiButtonBase-root": {
      marginRight: "0.25rem",
      marginTop: "0.25rem",
      fontSize: "0.75rem",
      fontWeight: "500",
      border: `1px solid ${theme.color.gray[300]}`,
      borderRadius: "0.25rem",
      backgroundColor: theme.color.white,
      padding: "0.375rem 0.625rem",
      textOpacity: "1",
      color: theme.color.gray[700],
      minWidth: "50px",
      "&:hover": {
        cursor: "pointer",
        textDecoration: "underline",
        color: theme.color.red[600],
      },
    },
  },
  previewHeaderToggleButton: {
    "&.MuiButtonBase-root": {
      marginRight: theme.spacing.xxs,
      marginTop: theme.spacing.xs,
      fontSize: theme.font.size.subheading,
      fontWeight: theme.font.weight.semiBold,
      border: `1px solid ${theme.color.gray[300]};`,
      borderRadius: theme.spacing.xs,
      backgroundColor: "#fff",
      padding: `${theme.spacing.xs} ${theme.spacing.xs}`,
      color: theme.color.gray[600],
      minWidth: theme.spacing.lg,
      "&:hover": {
        cursor: "pointer",
        color: theme.color.blue[600],
      },
    },
  },
  previewHeaderDropdownButton: {
    "&.MuiButtonBase-root": {
      fontSize: theme.font.size.caption,
      fontWeight: theme.font.weight.semiBold,
      padding: "0.375rem 0.5rem",
      color: theme.color.gray[700],
      textTransform: "none",
      width: "100%",
      "&:hover": {
        cursor: "pointer",
        background: "transparent",
        color: theme.color.blue[600],
        textDecoration: "underline",
      },
    },
  },
  editHeaderDeleteButton: {
    "&.MuiButtonBase-root": {
      marginRight: "0.25rem",
      marginTop: "0.25rem",
      fontSize: "0.75rem",
      fontWeight: "500",
      border: "1px solid #feb2b2;",
      borderRadius: "0.25rem",
      backgroundColor: "#fff",
      padding: "0.375rem 0.625rem",
      textOpacity: "1",
      color: "#e53e3e",
      minWidth: "50px",
      "&:hover": {
        cursor: "pointer",
        textDecoration: "underline",
        backgroundColor: "#fed7d7",
      },
    },
  },
  baseLinkButton: {
    color: "#4a5568",
  },
  navButton: {
    "&.MuiButtonBase-root": {
      borderRadius: "0.375rem",
    },
  },
  navBlueButton: {
    "&.MuiButtonBase-root": {
      marginTop: "0.75rem",
      borderRadius: "0.375rem",
      backgroundColor: `var(--color-primary)`,
      color: "white",
      "& .MuiListItemIcon-root": {
        color: "white !important",
      },
      "&:hover": {
        background: `var(--color-primary-hover)`,
      },
    },
  },
  deleteButton: {
    "&.MuiButtonBase-root": {
      fontSize: ".8rem",
      fontWeight: "500",
      border: "1px solid #e2e8f0",
      borderRadius: "0.375rem",
      padding: "0.5rem 1rem 0.5rem 1rem",
      boxShadow: "0 .125rem .25rem rgba(0,0,0,.075)!important",
      lineHeight: "inherit",
      backgroundColor: "#e53e3e",
      color: "#fff",
      textTransform: "none",
      "&:hover": {
        cursor: "pointer",
        background: "#dc3545",
      },
      "&.Mui-disabled": {
        opacity: 0.6,
        pointerEvents: "none",
      },
    },
  },
  warnButton: {
    "&.MuiButtonBase-root": {
      fontSize: ".8rem",
      fontWeight: "500",
      border: "1px solid #e2e8f0",
      borderRadius: "0.375rem",
      padding: "0.5rem 1rem 0.5rem 1rem",
      boxShadow: "0 .125rem .25rem rgba(0,0,0,.075)!important",
      lineHeight: "inherit",
      backgroundColor: "#e5763e",
      color: "#fff",
      textTransform: "none",
      "&:hover": {
        cursor: "pointer",
        background: "#ec7940",
      },
    },
  },
  cancelButton: {
    "&.MuiButtonBase-root": {
      fontSize: ".8rem",
      fontWeight: "500",
      borderRadius: "0.375rem",
      padding: "0.5rem 1rem 0.5rem 1rem",
      lineHeight: "inherit",
      color: "#4a5568",
      backgroundColor: "#fff",
      textTransform: "none",
      "&:hover": {
        cursor: "pointer",
        background: "#f8f9fa",
      },
    },
  },
  saveButton: sharedStyles,
  submitButton: sharedStyles,
  successButton: {
    "&.MuiButtonBase-root": {
      fontSize: ".8rem",
      fontWeight: "500",
      lineHeight: "inherit",
      color: theme.color.white,
      backgroundColor: theme.color.green[400],
      padding: "0.5rem 1rem",
      border: "1px solid #e2e8f0",
      borderRadius: "0.375rem",
      boxShadow: "0 .125rem .25rem rgba(0,0,0,.075)!important",
      textTransform: "none",
    },
  },
  addFieldButton: {
    "&.MuiButtonBase-root": {
      color: "#1a202c",
      textTransform: "none",
      backgroundColor: "transparent",
      "&:hover": {
        cursor: "pointer",
        color: "#3182ce",
        textDecoration: "underline",
        backgroundColor: "transparent",
      },
    },
  },
  whiteButtonWithBlueLabel: {
    "&.MuiButtonBase-root": {
      color: "#4299e1",
      textTransform: "none",
      backgroundColor: "transparent",
      border: "1px solid #e2e8f0",
    },
  },
  whiteBorderAndColorButton: {
    "&.MuiButtonBase-root": {
      color: "white",
      textTransform: "none",
      backgroundColor: "transparent",
      border: "1px solid white",
    },
  },
  disabledButton: {
    opacity: 0.25,
    ...sharedStyles,
  },
  outlineButton: {
    "&.MuiButtonBase-root": {
      fontSize: "0.8rem",
      fontWeight: `${theme.font.weight.semiBold}`,
      border: `1px solid ${theme.color.blue[600]}`,
      borderRadius: "0.375rem",
      padding: `${theme.spacing.sm} ${theme.spacing.lg}`,
      lineHeight: "inherit",
      color: `${theme.color.blue[600]}`,
      textTransform: "none",
      "& svg": {
        marginRight: `${theme.spacing.sm}`,
        fill: `${theme.color.blue[600]}`,
        width: "20px",
        height: "20px",
      },
      "&:hover": {
        cursor: "pointer",
      },
    },
  },
  deleteOutlineButton: {
    "&.MuiButtonBase-root": {
      fontSize: "0.8rem",
      fontWeight: `${theme.font.weight.semiBold}`,
      border: `1px solid ${theme.color.red[600]}`,
      borderRadius: "0.375rem",
      padding: `${theme.spacing.sm} ${theme.spacing.lg}`,
      lineHeight: "inherit",
      color: `${theme.color.red[600]}`,
      textTransform: "none",
      "&.Mui-disabled": {
        opacity: 0.6,
        pointerEvents: "none",
      },
      "&:hover": {
        cursor: "pointer",
        background: `${theme.color.red[100]}`,
      },
      "& svg": {
        marginRight: `${theme.spacing.sm}`,
        fill: `${theme.color.red[600]}`,
        width: "18px",
        height: "18px",
      },
    },
  },
});
