import React, { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useForm, FieldErrors, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import { TFunction } from "i18next";
import { PersonAdd as PersonAddIcon } from "@mui/icons-material";
import { useTeam } from "contexts/team/hooks";
import {
  ApiError,
  TeamMembersDto,
  InvitationDTO,
  InvitationCreateDTO,
  TeamDTO,
} from "openapi";
import { Header, Row } from "components/BasicTable/types";
import BasicTable from "components/BasicTable/BasicTable";
import CardWrapper from "components/CardWrapper/CardWrapper";
import CardHeaderWrapper from "components/CardHeaderWrapper/CardHeaderWrapper";
import { CTAButton, MessageBox, NewModal } from "components";
import TeamInvitationForm from "./TeamInvitationForm";
import { theme } from "theme";
import { ForbiddenResponse } from "shared/types/ErrorResponse.type";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { setValidationErrors } from "shared/service/errorResponseService";
import { useLocale } from "hooks";
import { Language } from "shared/i18n/i18n";
import {
  useOrganizationTeamsQuery,
  useSendInvitationMutation,
} from "shared/api";
import TeamMemberRoles from "./TeamMemberRoles";
import { Link as RouterLink } from "react-router-dom";
import { Link } from "@mui/material";
import routePaths from "constants/routePaths";
import { Features } from "constants/features";
import LampIcon from "assets/svg/bulb-icon.svg?react";
import { Description, ModalContent } from "./styles";

export type FormErrors = {
  email?: string;
  roles?: FieldErrors;
};

const validationSchema = () =>
  Yup.object({
    email: Yup.array().of(Yup.string().email()).required(),
    roles: Yup.array().when("owner", {
      is: false,
      then: Yup.array().min(1).required(),
    }),
    owner: Yup.boolean().required(),
  });

export type InvitationType = {
  email: string[];
  language: string;
  roles: string[];
  owner: boolean;
};

export type TeamMembersProps = {
  teamInfoData?: TeamDTO;
  teamMembersData?: TeamMembersDto;
  teamPendingInvitationsData?: InvitationDTO[];
};

const TeamMembers = ({
  teamInfoData,
  teamMembersData,
  teamPendingInvitationsData,
}: TeamMembersProps) => {
  const [teamMembersCount, setTeamMembersCount] = useState<number>();
  const { t } = useTranslation();
  const { locale } = useLocale();
  const { isOwner, selectedTeam, organizationId, hasFeature } = useTeam();
  const { enqueueSnackbar } = useSnackbar();
  const [isMemberModalOpen, setIsMemberModalOpen] = useState(false);
  const [data, setData] = useState<Row[]>([]);

  const taskAutomationEnabled = hasFeature(Features.TASK_AUTOMATION);

  const currentOrganizationId =
    selectedTeam?.parentTeamId ?? (selectedTeam?.id as string);

  const pendingInvitations = teamPendingInvitationsData?.map(
    (item) => item.email
  );

  const { data: organizationData, refetch } = useOrganizationTeamsQuery(
    currentOrganizationId
  );

  const { mutateAsync: sendInvitation } = useSendInvitationMutation();

  const getInitialValues = (locale: Language): InvitationType => {
    return {
      email: [],
      language: locale,
      roles: [],
      owner: false,
    };
  };

  const {
    control,
    handleSubmit,
    setError,
    reset,
    setValue,
    formState: { errors, isValid },
    trigger,
  } = useForm<InvitationType>({
    mode: "onBlur",
    defaultValues: getInitialValues(locale),
    resolver: yupResolver(validationSchema()),
  });

  useEffect(() => void refetch(), [teamInfoData]);

  const ownerFlag = useWatch({ control: control, name: "owner" });

  useEffect(() => {
    if (ownerFlag) {
      setValue("roles", [], {
        shouldValidate: true,
        shouldDirty: true,
      });
    }
  }, [ownerFlag]);

  const convertData = (
    teamMembers: TeamMembersDto,
    t: TFunction<"translation">
  ) => {
    return teamMembers.members
      .sort((member) => {
        return member.id === teamMembers.memberId ? -1 : 0;
      })
      .filter((member) => !member.isSupportUser)
      .map((member) => {
        const fullName = `${member.firstname ?? ""} ${member.lastname ?? ""} ${
          teamMembers.memberId === member.id
            ? `(${t("pages.settings.tabs.team.teamMembers.you")})`
            : ""
        }`;
        const memberDetailsLink =
          member.id !== teamMembers.memberId && isOwner() ? (
            <Link
              component={RouterLink}
              to={`${routePaths.SETTINGS_MEMBERS}/${member.id}`}
              className="hover-underline"
              aria-label="teammember"
            >
              {fullName}
            </Link>
          ) : (
            fullName
          );
        const data: Row = {
          id: member.id,
          fullName: memberDetailsLink,
          email: member.email,
          role: member.owner ? (
            ""
          ) : (
            <TeamMemberRoles
              memberId={member.id}
              organizationId={currentOrganizationId}
            />
          ),
          administrationConfiguration:
            member.categoryOwner || member.owner ? (
              <CheckIcon
                data-order-key="1"
                sx={{ color: theme.color.green[600] }}
              />
            ) : (
              <CloseIcon data-order-key="0" />
            ),
          organizationOwner: member.owner ? (
            <CheckIcon
              data-order-key="1"
              sx={{ color: theme.color.green[600] }}
            />
          ) : (
            <CloseIcon data-order-key="0" />
          ),
          contractTemplateAdmin:
            member.contractTemplateAdmin || member.owner ? (
              <CheckIcon
                data-order-key="1"
                sx={{ color: theme.color.green[600] }}
              />
            ) : (
              <CloseIcon data-order-key="0" />
            ),
          taskAutomation: taskAutomationEnabled ? (
            member.taskAutomationAdmin || member.owner ? (
              <CheckIcon
                data-order-key="1"
                sx={{ color: theme.color.green[600] }}
              />
            ) : (
              <CloseIcon data-order-key="0" />
            )
          ) : (
            <></>
          ),
        };
        return data;
      });
  };

  useEffect(() => {
    if (teamMembersData) {
      setData(convertData(teamMembersData, t));
      if (teamMembersData.members.length > 0) {
        setTeamMembersCount(teamMembersData.members.length);
      }
    }
  }, [teamMembersData]);

  useEffect(() => reset(getInitialValues(locale)), []);

  const headers: Header[] = [
    {
      key: "fullName",
      name: t("pages.settings.tabs.team.teamMembers.name").toString(),
    },
    {
      key: "email",
      name: t("pages.settings.tabs.team.teamMembers.email").toString(),
    },
    {
      key: "organizationOwner",
      name: t(
        "pages.settings.tabs.team.teamMembers.organizationOwner"
      ).toString(),
      tooltipText: t(
        "pages.settings.tabs.team.teamMembers.tooltips.organizationOwner"
      ),
    },
    {
      key: "role",
      name: t("pages.settings.tabs.team.teamMembers.role").toString(),
      notSortable: true,
    },
    {
      key: "administrationConfiguration",
      name: t(
        "pages.settings.tabs.team.teamMembers.details.categoryAdministrator"
      ).toString(),
      tooltipText: t(
        "pages.settings.tabs.team.teamMembers.tooltips.categoryOwner"
      ),
    },
  ];

  if (taskAutomationEnabled) {
    headers.push({
      key: "taskAutomation",
      name: t(
        "pages.settings.tabs.team.teamMembers.details.taskAutomation"
      ).toString(),
      tooltipText: t(
        "pages.settings.tabs.team.teamMembers.tooltips.taskAutomation"
      ),
    });
  }

  if (hasFeature(Features.CONTRACT_TEMPLATES)) {
    headers.push({
      key: "contractTemplateAdmin",
      name: t(
        "pages.settings.tabs.team.teamMembers.details.contractTemplateAdmin"
      ),
      tooltipText: t(
        "pages.settings.tabs.team.teamMembers.tooltips.contractTemplateAdmin"
      ),
    });
  }

  if (isOwner()) {
    headers.push({
      key: "action",
      name: "",
      notSortable: true,
    });
  }

  const onSubmit = async (values: InvitationType) => {
    const formEmailAddresses = values.email;
    const emailAddresses = formEmailAddresses.filter(
      (email) => pendingInvitations && !pendingInvitations.includes(email)
    );

    if (!emailAddresses?.length) {
      setError("email", { type: "validationType" });
      return;
    }

    if (errors.roles) {
      setError("roles", { type: "validationType" });
      return;
    }

    try {
      for (const emailRecipient of emailAddresses) {
        const invitationBody = {
          email: emailRecipient,
          language: values.language as InvitationCreateDTO.language,
          owner: values.owner,
          categoryAdmin: false,
          roles: values.roles.map((roleId) => ({ id: roleId })),
        } satisfies InvitationCreateDTO;

        await sendInvitation({
          organizationId,
          invitationBody,
        });
      }

      enqueueSnackbar(
        t("pages.settings.tabs.team.teamInvitationForm.information.emailSend"),
        { variant: "success" }
      );
      void reset();
      setIsMemberModalOpen(false);
    } catch (e) {
      if (!(e instanceof ApiError)) return;

      setValidationErrors(
        e,
        setError,
        "pages.settings.tabs.team.teamInvitationForm"
      );
      if (
        e.status === 403 &&
        (e.body as ForbiddenResponse).message === "team member limit reached"
      ) {
        enqueueSnackbar(
          t(
            "pages.settings.tabs.team.teamInvitationForm.validation.maxTeamMember"
          ),
          { variant: "error" }
        );
      }
    }
  };

  const handleClose = () => {
    setIsMemberModalOpen(false);
    reset();
  };

  return (
    <CardWrapper data-testid="team-members-table">
      <CardHeaderWrapper
        title={t("pages.settings.tabs.team.teamMembers.title")}
        titleInformation={
          teamMembersCount ? `(${teamMembersCount})` : undefined
        }
        actions={
          isOwner() ? (
            <CTAButton
              variant="secondary"
              name={t("pages.settings.organization.teams.members.addNewMember")}
              onClick={() => setIsMemberModalOpen(true)}
              data-cy="openInvitationModalButton"
            />
          ) : (
            <></>
          )
        }
      />
      <BasicTable
        id="teamMembersTable"
        headers={headers}
        data={data}
        isLastCellStyled
      />
      <NewModal
        fullWidth
        title={t("pages.settings.tabs.team.teamInvitationForm.title")}
        icon={
          <PersonAddIcon
            fontSize="small"
            sx={{ marginLeft: theme.spacing.sm }}
          />
        }
        open={isMemberModalOpen}
        handleClose={handleClose}
        body={
          <>
            {isOwner() && (
              <ModalContent>
                <div>
                  {t(
                    "pages.settings.tabs.team.teamInvitationForm.description.main"
                  )}
                </div>
                <MessageBox
                  description={
                    <>
                      <div>
                        <Trans
                          i18nKey="pages.settings.tabs.team.teamInvitationForm.description.ownerInfo"
                          components={{
                            strong: <Description />,
                          }}
                        />
                      </div>
                      <div>
                        <Trans
                          i18nKey="pages.settings.tabs.team.teamInvitationForm.description.rolesInfo"
                          components={{
                            strong: <Description />,
                          }}
                        />
                      </div>
                    </>
                  }
                  icon={<LampIcon fill={theme.color.white} />}
                  variation="info"
                />
                {organizationData?.teams?.length && (
                  <TeamInvitationForm
                    control={control}
                    teams={organizationData?.teams}
                    errors={errors as FormErrors}
                    trigger={trigger}
                  />
                )}
              </ModalContent>
            )}
          </>
        }
        footer={
          <>
            <CTAButton
              data-cy="cancelInvitation"
              type="button"
              variant="secondary"
              onClick={handleClose}
              name={t("common.buttons.cancel")}
            />
            <CTAButton
              onClick={handleSubmit(onSubmit)}
              data-cy="submitInvitation"
              type="submit"
              name={t("pages.settings.tabs.team.teamInvitationForm.submit")}
              disabled={!isValid}
            />
          </>
        }
      />
    </CardWrapper>
  );
};

export default TeamMembers;
