import React, { useEffect, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import FormDatePickerField from "../FormDatePicker/FormDatePicker";
import {
  ContractFieldDTOV1,
  ContractFieldLimitedDTOV1,
  DurationFieldTypeDtoV1,
} from "openapi";
import { FormSelect } from "components/FormItems/FormSelect/FormSelect";
import { FormNumericField } from "components/FormItems/FormNumeric/FormNumericField";
import { AnalysisHookData } from "components/Datapoints/hooks/useAnalysis";
import { AnalysisWrapper } from "components/Datapoints/components/AnalysisWrapper";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { Suggestions } from "components/Datapoints/hooks/types";
import { DatapointFieldInputAdornment } from "components/Datapoints/components/DatapointFieldInputAdornment";
dayjs.extend(utc);

interface DurationFormType {
  fields: Record<string, DurationFieldTypeDtoV1>;
}

type ContractDurationFormProps = {
  field: ContractFieldLimitedDTOV1;
  analysis?: AnalysisHookData<DurationFieldTypeDtoV1>;
  suggestions?: Record<string, Suggestions> | null;
};

export const FormDurationField: React.FC<ContractDurationFormProps> = ({
  field,
  analysis,
  suggestions,
}) => {
  const { t } = useTranslation();
  const { control, getValues, setValue } = useFormContext<DurationFormType>();

  const [, setFocusedFieldId] = useState<keyof DurationFieldTypeDtoV1 | null>(
    null
  );

  const [updatedWhileFocused, setUpdatedWhileFocused] = useState<
    keyof DurationFieldTypeDtoV1 | null
  >(null);

  const startDate = useWatch({
    name: `fields.${field.id}.startDate`,
    control: control,
  });
  const endDate = useWatch({
    name: `fields.${field.id}.endDate`,
    control: control,
  });
  const interval = useWatch({
    name: `fields.${field.id}.interval`,
    control: control,
  });
  const automaticRenewal = useWatch({
    name: `fields.${field.id}.automaticRenewal`,
    control: control,
  });
  const noticePeriod = useWatch({
    name: `fields.${field.id}.noticePeriod`,
    control: control,
  });

  const terminationDate = useWatch({
    name: `fields.${field.id}.terminationDate`,
    control: control,
  });

  useEffect(() => {
    fieldChanged("startDate");
  }, [startDate]);

  useEffect(() => {
    fieldChanged("endDate");
  }, [endDate]);

  useEffect(() => {
    fieldChanged("interval");
  }, [interval]);

  useEffect(() => {
    fieldChanged("noticePeriod");
  }, [noticePeriod]);

  useEffect(() => {
    fieldChanged("terminationDate");
  }, [terminationDate]);

  const fieldChanged = (changed: keyof DurationFieldTypeDtoV1) => {
    if (!analysis) return;

    const fields = {
      startDate: {
        value: startDate,
        calculate: calculateStartDate,
      },
      endDate: {
        value: endDate,
        calculate: calculateEndDate,
      },
      interval: {
        value: interval,
        calculate: calculateInterval,
      },
      terminationDate: {
        value: terminationDate,
        calculate: calculateTerminationDate,
      },
    };

    for (const [key, field] of Object.entries(fields)) {
      if (
        changed !== key &&
        ((updatedWhileFocused !== key && !field.value) ||
          updatedWhileFocused === key)
      ) {
        if (field.calculate()) {
          setUpdatedWhileFocused(key as keyof DurationFieldTypeDtoV1);
        }
      }
    }
  };

  const calculateStartDate = () => {
    if (!interval || !endDate) {
      return false;
    }

    const startDate = dayjs
      .utc(endDate)
      .subtract(interval, "month")
      .toISOString();
    setValue(`fields.${field.id}.startDate`, startDate);
    return true;
  };

  const calculateEndDate = () => {
    if (!interval || !startDate) {
      return false;
    }

    const endDate = dayjs.utc(startDate).add(interval, "month").toISOString();
    setValue(`fields.${field.id}.endDate`, endDate);
    return true;
  };

  const calculateInterval = () => {
    if (!startDate || !endDate) {
      return false;
    }
    const interval = Math.round(
      dayjs.utc(endDate).diff(dayjs.utc(startDate), "month", true)
    );
    if (interval > 0) {
      setValue(`fields.${field.id}.interval`, interval);
      return true;
    }
    return false;
  };

  const calculateTerminationDate = () => {
    if (!noticePeriod || !endDate || noticePeriod < 0) {
      return false;
    }
    const terminationDate = dayjs
      .utc(endDate)
      .subtract(noticePeriod, "month")
      .toISOString();

    setValue(`fields.${field.id}.terminationDate`, terminationDate);
    return true;
  };

  const blurHandler = () => {
    setFocusedFieldId(null);
    setUpdatedWhileFocused(null);
  };

  const focusHandler = (
    key: keyof DurationFieldTypeDtoV1
  ): React.FocusEventHandler<HTMLInputElement> => {
    return () => {
      setFocusedFieldId(key);
    };
  };

  useEffect(() => {
    if (!analysis) return;
    if (automaticRenewal) {
      setValue(
        `fields.${field.id}.type`,
        DurationFieldTypeDtoV1.type.AUTOMATIC_RENEWAL
      );
      return;
    }
    if (endDate) {
      setValue(
        `fields.${field.id}.type`,
        DurationFieldTypeDtoV1.type.FIXED_TERM
      );
      return;
    }
    if (startDate && noticePeriod) {
      setValue(
        `fields.${field.id}.type`,
        DurationFieldTypeDtoV1.type.INDEFINITE_DURATION
      );
      return;
    }
  }, [startDate, endDate, automaticRenewal, noticePeriod]);

  return (
    <>
      <FormSelect
        control={control}
        name={`fields.${field.id}.type`}
        options={Object.keys(DurationFieldTypeDtoV1.type)}
        emptyOptionText="–"
        label={t("pages.contractEdit.forms.durationForm.durationType")}
        translationPrefix="pages.contractEdit.forms.durationForm.durationTypeOptions"
        data-testid={"durationType"}
      />
      <Grid container spacing={1.25}>
        <Grid item xs={12} md={6}>
          <AnalysisWrapper
            analysis={analysis}
            fieldKey="startDate"
            definitionType={ContractFieldDTOV1.type.DATE}
            renderField={(data, selector) => {
              return (
                <FormDatePickerField
                  control={control}
                  name={`fields.${field.id}.startDate`}
                  label={t("pages.contractEdit.forms.durationForm.startDate")}
                  inputProps={{
                    InputProps: {
                      startAdornment: selector ?? (
                        <DatapointFieldInputAdornment
                          definition={field}
                          fieldKey="startDate"
                          suggestions={suggestions}
                          setValue={setValue}
                          data={getValues().fields[field.id]}
                        />
                      ),
                    },
                    suggestion: data?.selectedSuggestion.startDate != null,
                    onFocus: focusHandler("startDate"),
                    onBlur: blurHandler,
                  }}
                />
              );
            }}
          />
        </Grid>
        <>
          <Grid item xs={12} md={6}>
            <AnalysisWrapper
              analysis={analysis}
              fieldKey="interval"
              definitionType={ContractFieldDTOV1.type.NUMBER}
              renderField={(data, selector) => {
                return (
                  <FormNumericField
                    control={control}
                    name={`fields.${field.id}.interval`}
                    label={t(
                      "pages.contractEdit.forms.durationForm.durationInMonths"
                    )}
                    onFocus={focusHandler("interval")}
                    onBlur={blurHandler}
                    decimalScale={0}
                    InputProps={{
                      startAdornment: selector ?? (
                        <DatapointFieldInputAdornment
                          definition={field}
                          fieldKey="interval"
                          suggestions={suggestions}
                          setValue={setValue}
                          data={getValues().fields[field.id]}
                        />
                      ),
                    }}
                    inputProps={{
                      suggestion: data?.selectedSuggestion.interval != null,
                    }}
                    allowNegative={false}
                  />
                );
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <AnalysisWrapper
              analysis={analysis}
              fieldKey="endDate"
              definitionType={ContractFieldDTOV1.type.DATE}
              renderField={(data, selector) => {
                return (
                  <FormDatePickerField
                    control={control}
                    name={`fields.${field.id}.endDate`}
                    label={t("pages.contractEdit.forms.durationForm.endDate")}
                    inputProps={{
                      InputProps: {
                        startAdornment: selector ?? (
                          <DatapointFieldInputAdornment
                            definition={field}
                            fieldKey="endDate"
                            suggestions={suggestions}
                            setValue={setValue}
                            data={getValues().fields[field.id]}
                          />
                        ),
                      },
                      onFocus: focusHandler("endDate"),
                      onBlur: blurHandler,
                      suggestion: data?.selectedSuggestion.endDate != null,
                    }}
                  />
                );
              }}
            />
          </Grid>
        </>
        <Grid item xs={12} md={6}>
          <AnalysisWrapper
            analysis={analysis}
            fieldKey="noticePeriod"
            definitionType={ContractFieldDTOV1.type.NUMBER}
            renderField={(data, selector) => {
              return (
                <FormNumericField
                  testId={"noticePeriod"}
                  control={control}
                  name={`fields.${field.id}.noticePeriod`}
                  label={t(
                    "pages.contractEdit.forms.durationForm.noticeInMonths"
                  )}
                  InputProps={{
                    startAdornment: selector ?? (
                      <DatapointFieldInputAdornment
                        definition={field}
                        fieldKey="noticePeriod"
                        suggestions={suggestions}
                        setValue={setValue}
                        data={getValues().fields[field.id]}
                      />
                    ),
                  }}
                  inputProps={{
                    suggestion: data?.selectedSuggestion.noticePeriod != null,
                  }}
                  onFocus={focusHandler("noticePeriod")}
                  onBlur={blurHandler}
                  fixedDecimalScale={false}
                  allowNegative={false}
                />
              );
            }}
          />
        </Grid>
        <>
          <Grid item xs={12} md={6}>
            <AnalysisWrapper
              analysis={analysis}
              fieldKey="terminationDate"
              definitionType={ContractFieldDTOV1.type.DATE}
              renderField={(data, selector) => {
                return (
                  <FormDatePickerField
                    control={control}
                    name={`fields.${field.id}.terminationDate`}
                    label={t(
                      "pages.contractEdit.forms.durationForm.lastTerminationDate"
                    )}
                    inputProps={{
                      InputProps: {
                        startAdornment: selector ?? (
                          <DatapointFieldInputAdornment
                            definition={field}
                            fieldKey="terminationDate"
                            suggestions={suggestions}
                            setValue={setValue}
                            data={getValues().fields[field.id]}
                          />
                        ),
                      },
                      onFocus: focusHandler("terminationDate"),
                      onBlur: blurHandler,
                      suggestion:
                        data?.selectedSuggestion.terminationDate != null,
                    }}
                  />
                );
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <AnalysisWrapper
              analysis={analysis}
              fieldKey="automaticRenewal"
              definitionType={ContractFieldDTOV1.type.NUMBER}
              renderField={(data, selector) => {
                return (
                  <FormNumericField
                    control={control}
                    name={`fields.${field.id}.automaticRenewal`}
                    allowNegative={false}
                    label={t(
                      "pages.contractEdit.forms.durationForm.autoRenewalInMonths"
                    )}
                    decimalScale={0}
                    InputProps={{
                      startAdornment: selector ?? (
                        <DatapointFieldInputAdornment
                          definition={field}
                          fieldKey="automaticRenewal"
                          suggestions={suggestions}
                          setValue={setValue}
                          data={getValues().fields[field.id]}
                        />
                      ),
                    }}
                    inputProps={{
                      suggestion:
                        data?.selectedSuggestion.automaticRenewal != null,
                    }}
                  />
                );
              }}
            />
          </Grid>
        </>
      </Grid>
    </>
  );
};

export default FormDurationField;
