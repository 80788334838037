import { theme } from "theme";

export const paperStyles = {
  display: "flex",
  alignItems: "center",
  backgroundColor: theme.color.white,
  padding: theme.spacing.lg,
  marginTop: theme.spacing.md,
  borderRadius: theme.borderRadius,
  boxShadow: "var(--dropdown-shadow)",
};
