import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import {
  OrganizationNewService,
  WebhookLogListDTO,
  WebhookLogDTO,
} from "openapi";
import { BasicTable, NewModal, CTAButton, CodeSnippet } from "components";
import { useNavigate, useParams } from "react-router-dom";
import { Row } from "components/BasicTable/types";
import { useTeam } from "contexts/team/hooks";
import { routePaths } from "constants/";
import { theme } from "theme";
import {
  TableWrapper,
  DateLog,
  SectionTitle,
  EventOptionLabel,
  Status,
  SnippetTitle,
} from "../styles";
import { getRequestData, getResponseData } from "./helpers";

const sortWebhookLogsByDate = (array: WebhookLogListDTO[]) => {
  return array.sort((a, b) => {
    const timestampA = new Date(a.createdAt).getTime();
    const timestampB = new Date(b.createdAt).getTime();
    return timestampA - timestampB;
  });
};

const getStatusType = (status: string) => {
  switch (status) {
    case "SUCCESS":
      return "success";
      break;
    case "FAILED":
      return "error";
      break;
    case "RETRYING":
      return "retry";
      break;
    default:
      return "";
  }
};

const HistoryLog = ({ id: webhookId }: { id: string }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { organizationId } = useTeam();
  const { eventId } = useParams();
  const [data, setData] = useState<WebhookLogListDTO[]>();
  const [showLogDetailsModal, setShowLogDetailsModal] = useState(false);
  const [eventInfo, setEventInfo] = useState<WebhookLogDTO>();

  const formattedDate = (date: string) =>
    dayjs(date).format("HH:mm:ss DD/MM/YYYY");

  const getStatusMessage = (status: unknown) => {
    if (typeof status !== "string") return;

    switch (status) {
      case "SUCCESS":
        return t(
          "pages.settings.organization.webhooks.common.events.logs.table.header.successful"
        );
      case "FAILED":
        return t(
          "pages.settings.organization.webhooks.common.events.logs.table.header.unsuccessful"
        );
      case "RETRYING":
      default:
        return t(
          "pages.settings.organization.webhooks.common.events.logs.table.header.retrying"
        );
    }
  };

  const headers = [
    {
      key: "createdAt",
      name: t(
        "pages.settings.organization.webhooks.common.events.logs.table.header.triggeredLast"
      ),
    },
    {
      key: "event",
      name: t(
        "pages.settings.organization.webhooks.common.events.logs.table.header.event"
      ),
    },
    {
      key: "status",
      name: t("common.status"),
    },
  ];

  const handleOnOpenDetailsModal = async (logId: string) => {
    try {
      const eventHoodDetails = await OrganizationNewService.getWebhookLog(
        webhookId,
        logId,
        organizationId
      );
      setEventInfo(eventHoodDetails);
      setShowLogDetailsModal(true);
    } catch (error) {
      console.error(error);
    }
  };

  const handleOnCloseDetailsModal = () => {
    navigate(`${routePaths.SETTINGS_WEBHOOK}/${webhookId}`);
    setShowLogDetailsModal(false);
  };

  const rows =
    data &&
    data.map((item) => {
      const variation =
        typeof item?.state === "string" ? getStatusType(item.state) : "";
      return {
        createdAt: (
          <DateLog
            to={`${routePaths.SETTINGS_WEBHOOK}/${webhookId}/events/${item.id}`}
            className="hover-underline"
          >
            {formattedDate(item?.createdAt)}
          </DateLog>
        ),
        event: <EventOptionLabel>{item?.event}</EventOptionLabel>,
        status: (
          <Status variation={variation}>{getStatusMessage(item?.state)}</Status>
        ),
      };
    });

  const fetchHistoryLog = async () => {
    try {
      const historyLog = await OrganizationNewService.getWebhookLogs(
        webhookId,
        organizationId
      );
      setData(sortWebhookLogsByDate(historyLog).slice(-5).reverse());
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => void fetchHistoryLog(), []);

  useEffect(() => {
    if (eventId) void handleOnOpenDetailsModal(eventId);
  }, [eventId]);

  if (!data?.length) return null;

  return (
    <>
      {data && (
        <TableWrapper>
          <SectionTitle style={{ margin: `${theme.spacing.xxl} 0` }}>
            {t("pages.settings.organization.webhooks.common.events.logs.title")}
          </SectionTitle>
          <BasicTable headers={headers} data={rows as Row[]} />
        </TableWrapper>
      )}

      <NewModal
        open={showLogDetailsModal}
        handleClose={handleOnCloseDetailsModal}
        fullWidth
        maxWidth="md"
        title={t(
          "pages.settings.organization.webhooks.common.events.details.modal.title"
        )}
        body={
          <>
            <SnippetTitle>
              {/* eslint-disable-next-line @typescript-eslint/restrict-template-expressions */}
              Request: State {`"${eventInfo?.state}"`} - {eventInfo?.createdAt}
            </SnippetTitle>

            <CodeSnippet title="Data" content={getRequestData(eventInfo)} />
            {eventInfo?.responses.map((response, index) => (
              <div key={`response-snippet-#${index}`}>
                <SnippetTitle>
                  Response: Status {response?.responseData?.statusCode ?? ""}{" "}
                  {response?.responseData?.statusText ?? ""} -{" "}
                  {response?.createdAt}
                </SnippetTitle>
                <CodeSnippet title="Data" content={getResponseData(response)} />
              </div>
            ))}
          </>
        }
        footer={
          <CTAButton
            name={t("common.buttons.close")}
            type="button"
            variant="primary"
            onClick={handleOnCloseDetailsModal}
            label={"eventInfo.id"}
          />
        }
      />
    </>
  );
};

export default HistoryLog;
