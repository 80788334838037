import React from "react";
import { useTranslation } from "react-i18next";
import { Collapse } from "@mui/material";
import { DurationFieldsList } from "./styles";

type DurationFieldDetailsType = {
  visible: boolean;
};

const DurationFieldDetails = ({ visible }: DurationFieldDetailsType) => {
  const { t } = useTranslation();
  return (
    <Collapse in={visible}>
      <DurationFieldsList>
        <li>{t("enums.duration.TYPE")}</li>
        <li>{t("pages.contractDetails.duration.startDate")}</li>
        <li>{t("pages.contractDetails.duration.endDate")}</li>
        <li>{t("pages.contractDetails.duration.durationInMonths")}</li>
        <li>{t("pages.contractDetails.duration.noticeInMonths")}</li>
        <li>{t("pages.contractDetails.duration.autoRenewalInMonths")}</li>
        <li>{t("pages.contractDetails.duration.lastTerminationDate")}</li>
      </DurationFieldsList>
    </Collapse>
  );
};

export default DurationFieldDetails;
