/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { InvitationLogDTO } from "./InvitationLogDTO";
import type { MemberDeletedDTO } from "./MemberDeletedDTO";
import type { MemberPermissionChangeDTO } from "./MemberPermissionChangeDTO";
import type { PermissionLogInitialLogDTO } from "./PermissionLogInitialLogDTO";
import type { RoleDTO } from "./RoleDTO";
import type { RoleUpdatedDataDTO } from "./RoleUpdatedDataDTO";
import type { TeamLogDataDTO } from "./TeamLogDataDTO";
import type { TeamMemberLogDTO } from "./TeamMemberLogDTO";
import type { TeamUpdateLogDTO } from "./TeamUpdateLogDTO";

export type PermissionChangeLogDTO = {
  data:
    | InvitationLogDTO
    | RoleDTO
    | RoleUpdatedDataDTO
    | MemberPermissionChangeDTO
    | MemberDeletedDTO
    | TeamLogDataDTO
    | TeamUpdateLogDTO
    | PermissionLogInitialLogDTO
    | TeamMemberLogDTO;
  id: string;
  event: PermissionChangeLogDTO.event;
  authorId: string | null;
  organizationId: string;
  createdAt: string;
};

export namespace PermissionChangeLogDTO {
  export enum event {
    INVITATION_SENT = "INVITATION_SENT",
    INVITATION_ACCEPTED = "INVITATION_ACCEPTED",
    INVITATION_DELETED = "INVITATION_DELETED",
    ROLE_CREATED = "ROLE_CREATED",
    ROLE_UPDATED = "ROLE_UPDATED",
    ROLE_DELETED = "ROLE_DELETED",
    MEMBER_UPDATED = "MEMBER_UPDATED",
    MEMBER_DELETED = "MEMBER_DELETED",
    TEAM_CREATED = "TEAM_CREATED",
    TEAM_DELETED = "TEAM_DELETED",
    TEAM_UPDATED = "TEAM_UPDATED",
    INITIAL_STATE = "INITIAL_STATE",
    SUPPORT_USER_INVITED = "SUPPORT_USER_INVITED",
  }
}
