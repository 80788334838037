import React, { FC } from "react";
import { ToolbarButton } from "./ToolbarButton";
import { ToolbarSpecificButtonProps } from "./types";
import { RotateDirection } from "@react-pdf-viewer/core";
import RotateLeftIcon from "assets/svg/rotate-left-icon.svg?react";
import { useTranslation } from "react-i18next";
export const RotateButton: FC<ToolbarSpecificButtonProps<"Rotate">> = ({
  Slot,
}) => {
  const { t } = useTranslation();

  return (
    <Slot direction={RotateDirection.Backward}>
      {(props) => {
        return (
          <ToolbarButton
            tooltip={t("pdfViewer.toolbar.rotate")}
            onClick={() => {
              props.onClick();
            }}
          >
            <RotateLeftIcon />
          </ToolbarButton>
        );
      }}
    </Slot>
  );
};
