import React from "react";
import { Arrow } from "./styles";

export type ArrowIconProps = { orientation?: string; onClick?: () => void };

const ArrowIcon = ({ orientation, onClick, ...props }: ArrowIconProps) => (
  <Arrow orientation={orientation} />
);

export default ArrowIcon;
