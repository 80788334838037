// should be synced with BE https://github.com/ContractHero/common/blob/main/src/document/accepted-mimetype-extension-map.constant.ts
export const acceptedMimetypeExtensionMap: Record<string, string> = {
  // Microsoft Office (Word, Excel, PowerPoint)
  "application/msword": ".doc",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
    ".docx",
  "application/vnd.ms-powerpoint": ".ppt",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation":
    ".pptx",
  "application/vnd.ms-excel": ".xls",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": ".xlsx",

  // PDF
  "application/pdf": ".pdf",

  // Images
  "image/jpeg": ".jpeg",
  "image/jpg": ".jpg",
  "image/png": ".png",
  "image/gif": ".gif",
  "image/bmp": ".bmp",
  "image/webp": ".webp",
  "image/tiff": ".tiff",
  "image/svg+xml": ".svg",

  // OpenDocument formats
  "application/vnd.oasis.opendocument.text": ".odt",
  "application/vnd.oasis.opendocument.spreadsheet": ".ods",
  "application/vnd.oasis.opendocument.presentation": ".odp",

  // Plain text
  "text/plain": ".txt",

  // Lexical
  "contracthero/lexical": ".template",
  "application/lexical": ".template",
  "contracthero/template": ".template",

  // EML (Email Message):
  "message/rfc822": ".eml",

  // Microsoft Outlook Email Message
  "application/vnd.ms-outlook": "msg",
};
