import React from "react";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { useTranslation } from "react-i18next";
import { SwitchBar } from "new-components";
import { routePaths } from "constants/";
import { BorderColorVariants } from "new-components/SwitchBar/SwitchBar";
import { useContractDetails, viewModeType } from "pages/Contract/context";

const ContractViewSwitcher = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { id: contractId } = useParams();
  const { viewMode, setViewMode } = useContractDetails();
  const [, setSearchParams] = useSearchParams();

  const handleOnChangeViewMode = (value: string) => {
    if (value !== viewModeType.EDIT) {
      // keep this block until we support '/edit' links also, later remove
      if (location.pathname.includes("/edit")) {
        const contractViewPath = `${routePaths.CONTRACTS}/${contractId ?? ""}`;
        navigate(contractViewPath, { replace: true });
        return;
      }
      setViewMode(viewModeType.VIEW);
      // Remove only the 'mode' param by passing undefined
      setSearchParams((prevParams) => {
        prevParams.delete("mode");
        return prevParams;
      });
      return;
    }

    setViewMode(viewModeType.EDIT);
    setSearchParams({ mode: viewModeType.EDIT });
  };

  return (
    <SwitchBar
      activeKey={viewMode as viewModeType}
      setActiveKey={handleOnChangeViewMode}
      options={[
        {
          key: viewModeType.EDIT,
          label: t("pages.contractDetails.buttons.editing"),
          active: viewMode === viewModeType.EDIT,
        },
        {
          key: viewModeType.VIEW,
          label: t("pages.contractDetails.buttons.viewing"),
          active: viewMode === viewModeType.VIEW,
        },
      ]}
      borderColor={BorderColorVariants.PRIMARY}
    />
  );
};

export default ContractViewSwitcher;
