import styled from "@emotion/styled";
import { theme } from "theme";

export const Wrapper = styled.div`
  display: flex;
  gap: ${theme.spacing.xs};
  align-items: center;
  justify-content: space-between;
  padding: ${theme.spacing.sm};
  background-color: ${theme.color.white};
  border-top: 1px solid ${theme.color.gray[300]};
  border-bottom: 1px solid ${theme.color.gray[300]};
`;

export const paperStyles = {
  display: "flex",
  alignItems: "center",
  width: "auto",
  maxWidth: "100%",
  maxHeight: "36px",
  padding: theme.spacing.sm,
  marginTop: theme.spacing.md,
  borderRadius: theme.borderRadius,
  backgroundColor: theme.color.white,
  boxShadow: "var(--dropdown-shadow)",
};

export const MoreMenuWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-evenly;
  gap: ${theme.spacing.xs};
`;
