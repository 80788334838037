import React, { useEffect, useState } from "react";
import { useSearchParams, useParams } from "react-router-dom";
import { motion } from "framer-motion";
import Tasks from "pages/Tasks/Tasks";
import StickyMenu from "./StickyMenu/StickyMenu";
import Contracts from "pages/Contracts/Contracts";
import { t } from "i18next";
import { Wrapper } from "./styles";
import { OverviewProvider } from "contexts/grid/grid";
import { ContractViewItemDto } from "openapi";
import { ContentWrapper } from "pages/Contract/components/RightSide/styles";

export type RightSideProps = {
  contactId: string;
  canCreateContract?: boolean;
};

export const RightSide = ({ contactId, canCreateContract }: RightSideProps) => {
  const [selectedTab, setSelectedTab] = useState({ id: 1, key: "documents" });
  const { id, mode } = useParams();
  const [searchParams] = useSearchParams();
  const taskId = searchParams.get("task") ?? undefined;
  const [hasContracts, setHasContracts] = useState(true);

  const handleSelectedTab = (id: number, key: string) =>
    setSelectedTab({ id, key });

  useEffect(() => {
    if (taskId || mode === "tasks") setSelectedTab({ id: 2, key: "tasks" });
  }, [id]);

  const motionProps = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    transition: { duration: 0.25 },
  };

  return (
    <Wrapper data-testid="contract-right-side">
      {selectedTab.key === "documents" && (
        <ContentWrapper>
          <OverviewProvider
            key={ContractViewItemDto.type.CONTRACT}
            type={ContractViewItemDto.type.CONTRACT}
          >
            <Contracts
              contactId={contactId}
              title={t("pages.contacts.title")}
              template="contact_contracts_view"
              canCreateContract={canCreateContract}
              setHasContracts={setHasContracts}
            />
          </OverviewProvider>
        </ContentWrapper>
      )}
      {selectedTab.key === "tasks" && (
        <ContentWrapper>
          <motion.div
            {...motionProps}
            style={{
              display: "flex",
              flexDirection: "column",
              height: "80vh",
            }}
          >
            <OverviewProvider
              key={ContractViewItemDto.type.TASK}
              type={ContractViewItemDto.type.TASK}
            >
              <Tasks
                title={t("pages.tasks.title")}
                contactId={contactId}
                isNewTaskButtonHidden={!hasContracts}
                viewColumns={["title", "status", "dueDate", "contract"]}
                style={{
                  borderTopRightRadius: 0,
                  borderBottomRightRadius: 0,
                  boxShadow: "none",
                }}
              />
            </OverviewProvider>
          </motion.div>
        </ContentWrapper>
      )}
      <StickyMenu
        currentSelectedTab={selectedTab}
        handleSelectedTab={handleSelectedTab}
      />
    </Wrapper>
  );
};

export default RightSide;
