import React, { useEffect, useState } from "react";
import * as Styled from "./styles";
import { useTranslation } from "react-i18next";
import {
  FormSelect,
  FormSelectItem,
} from "../../../../components/FormItems/FormSelect/FormSelect";
import { InfoTooltip } from "components/InfoTooltip/InfoTooltip";
import { useFetchDocumentsQuery } from "shared/api/documents";
import { useContractQuery } from "shared/api/contracts";
import { useParams } from "react-router-dom";
import { useTeam } from "contexts/team/hooks";
import { Features } from "constants/features";
import { useFormContext } from "react-hook-form";

const DocumentListPreview = () => {
  const [documentList, setDocumentList] = useState<FormSelectItem[]>([]);
  const [showDocumentList, setShowDocumentList] = useState(false);
  const { t } = useTranslation();
  const { hasFeature, selectedTeamId, organizationId } = useTeam();
  const { id } = useParams();
  const { data: contract } = useContractQuery(selectedTeamId, id);
  const { data: documents } = useFetchDocumentsQuery(
    organizationId,
    contract?.teamId,
    id
  );

  const { control, setValue } = useFormContext<{ documentId: string }>();

  useEffect(() => {
    fetchContractAndDocuments();
  }, [contract?.teamId, documents, id]);

  const fetchContractAndDocuments = () => {
    if (
      hasFeature(Features.MULTIPLE_DOCUMENT) &&
      id &&
      contract?.teamId &&
      documents?.length
    ) {
      const documentItems = documents
        .filter((doc) => doc.mimetype === "application/pdf")
        .map((doc) => ({
          key: doc.id,
          value: doc.filename,
        }));

      setDocumentList(documentItems);
      if (documentItems.length > 1) {
        setShowDocumentList(true);
      }
      setValue("documentId", documentItems[0]?.key || "");
    }
  };

  if (!showDocumentList) {
    return null;
  }

  return (
    showDocumentList && (
      <div>
        <Styled.DocumentHeader>
          <Styled.DocumentTitle>
            {t(
              "pages.contractDetails.modals.requestSignature.fields.documentId"
            )}
          </Styled.DocumentTitle>
          <InfoTooltip>
            {t(
              "pages.contractDetails.modals.requestSignature.tooltip.document"
            )}
          </InfoTooltip>
        </Styled.DocumentHeader>
        <FormSelect
          required
          name="documentId"
          options={documentList}
          control={control}
          sx={{ margin: "0" }}
        />
      </div>
    )
  );
};

export default DocumentListPreview;
