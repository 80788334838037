import React, { useState } from "react";
import { usePrintContext } from "contexts/contract/context";
import { t } from "i18next";
import { DropdownWrapper, DropdownMenu, Divider } from "./styles";
import PrintIcon from "assets/svg/print-icon.svg?react";
import { useOutsideClick } from "hooks";
import { ContractAttachmentListItemDTO, ContractDTOV1 } from "openapi";
import DeleteContract from "pages/Contract/components/Related/components/DeleteContract/DeleteContract";
import { CTAButton } from "components";
import DotsHorizontalIcon from "assets/svg/dots-horizontal.svg?react";

type DropdownProps = {
  contract: ContractDTOV1;
  contractGroup?: ContractAttachmentListItemDTO[];
};

const Dropdown = ({ contract, contractGroup }: DropdownProps) => {
  const [isDropdownOpened, setIsDropdownOpened] = useState(false);
  const { handlePrint } = usePrintContext();

  const dropdownRef = useOutsideClick(() => {
    if (isDropdownOpened) {
      setIsDropdownOpened(false);
    }
  });

  const onPrintHandler = () => {
    setIsDropdownOpened(false);
    handlePrint();
  };

  return (
    <DropdownWrapper ref={dropdownRef}>
      <CTAButton
        label="toggle-contract-menu-button"
        icon={<DotsHorizontalIcon />}
        onClick={() => setIsDropdownOpened(!isDropdownOpened)}
        iconSize="medium"
        size="square"
        variant="secondary"
      />
      {!!isDropdownOpened && (
        <DropdownMenu>
          <CTAButton
            variant="tertiary"
            label="print-contract-details-button"
            onClick={onPrintHandler}
            icon={<PrintIcon fontSize="small" />}
            name={t("pages.contractDetails.buttons.printContractDetails")}
            iconSize="medium"
            color="gray"
          />
          <Divider />
          <DeleteContract
            listItem={contract}
            contractGroup={contractGroup}
            buttonType="dropdown"
          />
        </DropdownMenu>
      )}
    </DropdownWrapper>
  );
};

export default Dropdown;
