import {
  CategoryDTO,
  ContactCreateDTO,
  ContactTypeDTO,
  ContractAttachmentService,
  ContractDTOV1,
  ContractInputDTOV1,
  ContractService,
  ContractUpdateDto,
  DurationFieldTypeDtoV1,
  ListFieldItem,
  OffsetConfig,
  OrganizationNewService,
  OrganizationService,
  TagDto,
  TaskNewCreateDTO,
  TaskNewService,
  TeamService,
} from "openapi";
import * as Sentry from "@sentry/react";
import dayjs from "dayjs";
import status = TaskNewCreateDTO.status;
import unit = OffsetConfig.unit;
import dueDateType = TaskNewCreateDTO.dueDateType;

type DummyContractData = {
  contract: ContractInputDTOV1 & { name: string };
  categoryName: string;
  fileName: string;
  tagNames: string[];
  tasks?: TaskNewCreateDTO[];
};

export async function createSampleData(
  teamId: string,
  hasAdressbook: boolean,
  parentTeamId: string,
  organizationId: string
) {
  // TODO cleanup after address book is general available (hasAddressbook always true)
  const fields = await OrganizationNewService.getFieldsOfOrganization(
    organizationId
  );

  const categories = new Map<string, CategoryDTO>();
  for (const category of await OrganizationNewService.getCategories(
    organizationId
  )) {
    if (!category.teams.includes(teamId)) {
      continue;
    }
    categories.set(category.name.de, category);
  }

  const getFieldIdByVisibleId = (visibleId: string) => {
    return fields.find((c) => c.visibleId === visibleId || c.id === visibleId)
      ?.id;
  };

  const getFieldIdByName = (name: string) => {
    return fields.find((c) => c.name.de === name)?.id;
  };

  const tags = new Map<string, TagDto>();
  for (const tag of await TeamService.getTags(teamId)) {
    tags.set(tag.name, tag);
  }

  const getListObject = (
    list: Record<string, string> | ListFieldItem[],
    value: string
  ) => {
    return {
      value: value,
      items: Array.isArray(list)
        ? list
        : Object.values(list).map((i) => ({
            id: i,
            value: i,
          })),
    };
  };

  const dummyContracts: DummyContractData[] = [
    {
      fileName: "64dd1efc-71e0-42ae-a8cc-bb990a0bf5fd.pdf",
      categoryName: "Dienstleistervertrag",
      tagNames: [
        "freelancer",
        "projektentwicklung",
        "gärber",
        "beispielvertrag",
      ],
      contract: {
        status: ContractDTOV1.status.ACTIVE,
        name: "Freelancer: Philipp Gärber (Beispielvertrag)",
        fields: {
          internalTeam: {
            value: "Projektentwicklung",
          },
          partnerCompany: {
            value: "Philipp Gärber",
          },
          partnerPerson: {
            value: "Philipp Gärber",
          },
          partnerPhone: {
            value: "01458 3429 3432",
          },
          partnerAddress: {
            value: "Kuhdamm 1",
          },
          partnerCity: {
            value: "Berlin",
          },
          partnerZip: {
            value: "15148",
          },
          partnerCountry: {
            value: "DE",
          },
          duration: {
            type: DurationFieldTypeDtoV1.type.INDEFINITE_DURATION,
            startDate: "2020-11-23",
            automaticRenewal: 3,
            endDate: null,
            interval: null,
            terminationDate: null,
            noticePeriod: null,
          },
          paymentCycle: getListObject(
            ContractUpdateDto.paymentCycle,
            ContractUpdateDto.paymentCycle.ONETIME
          ),
          paymentPriceNet: {
            value: 5000,
            currency: "EUR",
          },
          paymentTax: {
            value: 19,
          },
          paymentType: getListObject(
            ContractUpdateDto.paymentType,
            ContractUpdateDto.paymentType.EXPENSE
          ),
          paymentMethod: getListObject(
            ContractUpdateDto.paymentMethod,
            ContractUpdateDto.paymentMethod.WIRE_TRANSFER
          ),
        },
      },
    },

    {
      fileName: "5b927eee-6e69-4ddb-bab4-67e462a71294.pdf",
      categoryName: "Partnervertrag",
      tagNames: [
        "partner",
        "consulting gmbh",
        "sales & marketing",
        "beispielvertrag",
      ],
      contract: {
        status: ContractUpdateDto.status.ACTIVE,
        name: "Consulting GmbH (Beispielvertrag)",
        fields: {
          internalTeam: {
            value: "Sales & Marketing",
          },
          partnerCompany: {
            value: "Consulting Gmbh",
          },
          partnerPerson: {
            value: "Max Mustermann",
          },
          partnerEmail: {
            value: "muster@example.com",
          },
          partnerPhone: {
            value: "0800 888394",
          },
          partnerAddress: {
            value: "Torstraße 1",
          },
          partnerCity: {
            value: "Berlin",
          },
          partnerZip: {
            value: "10115",
          },
          partnerCountry: {
            value: "DE",
          },
          duration: {
            type: DurationFieldTypeDtoV1.type.INDEFINITE_DURATION,
            automaticRenewal: 3,
            startDate: "2020-01-01",
            endDate: null,
            interval: null,
            terminationDate: null,
            noticePeriod: null,
          },
          paymentCycle: getListObject(
            ContractUpdateDto.paymentCycle,
            ContractUpdateDto.paymentCycle.PROJECT_BASED
          ),
          paymentPriceNet: {
            value: 100,
            currency: "EUR",
          },
          paymentTax: {
            value: 10,
          },
          paymentType: getListObject(
            ContractUpdateDto.paymentType,
            ContractUpdateDto.paymentType.EXPENSE
          ),
          paymentMethod: getListObject(
            ContractUpdateDto.paymentMethod,
            ContractUpdateDto.paymentMethod.WIRE_TRANSFER
          ),
          IBAN: {
            value: "DE57 1111 2222 3333 45",
          },
        },
      },
    },
    {
      fileName: "2b5fecab-1720-4a53-8597-a6fecaa38591.pdf",
      categoryName: "Arbeitsvertrag",
      tagNames: ["von brösel", "mitarbeiter des monats", "beispielvertrag"],
      tasks: [
        {
          title: "Aufgabe mit Erinnerung",
          description: "Eine kleine Beschreibung zur Aufgabe",
          dueDateType: dueDateType.FIXED,
          dueDateConfig: {
            enabled: true,
            dueDate: dayjs().add(7, "day").toDate().toString(),
            repeatConfig: { enabled: true, offset: 1, unit: unit.YEAR },
          },
          reminderConfig: { enabled: true, offset: 5, unit: unit.DAY },
          contractId: "",
          status: status.OPEN,
        },
      ],
      contract: {
        status: ContractUpdateDto.status.ACTIVE,
        name: "Niklas Brösel (Beispielvertrag)",

        fields: {
          duration: {
            type: DurationFieldTypeDtoV1.type.INDEFINITE_DURATION,
            automaticRenewal: 3,
            startDate: "2020-01-08",
            endDate: null,
            interval: null,
            terminationDate: null,
            noticePeriod: null,
          },
          internalTeam: { value: "Produktentwicklung" },
          partnerCompany: { value: "Niklas Brösel" },
          partnerPerson: { value: "Niklas " },
          partnerEmail: { value: "niklas.brösel@contracthero.com" },
          partnerPhone: { value: "013859429343" },
          partnerAddress: { value: "Kölner Str. 22" },
          partnerCity: { value: "Krefeld" },
          partnerZip: { value: "47837" },
          partnerCountry: { value: "DE" },
          paymentCycle: { value: ContractUpdateDto.paymentCycle.MONTHLY },
          paymentPriceNet: { value: 10000, currency: "EUR" },
          paymentTax: { value: 19 },
          paymentType: { value: ContractUpdateDto.paymentType.EXPENSE },
          paymentMethod: {
            value: ContractUpdateDto.paymentMethod.WIRE_TRANSFER,
          },
          Kostenstelle: { value: "AB-7053" },
        },
      },
    },
    {
      fileName: "d25496f7-b8c5-46e1-93a6-566cbbc44cca.pdf",
      categoryName: "Arbeitsvertrag",
      tagNames: [
        "müller",
        "mitarbeiterin",
        "sales & marketing",
        "beispielvertrag",
      ],
      contract: {
        status: ContractUpdateDto.status.ACTIVE,
        name: "Stefanie Müller (Beispielvertrag)",
        fields: {
          internalTeam: { value: "Sales & Marketing" },
          partnerCompany: { value: "Stefanie Müller" },
          partnerPerson: { value: "Stefanie Müller" },
          partnerEmail: { value: "sm@contracthero.com" },
          partnerPhone: { value: "0158392324" },
          partnerAddress: { value: "Wiesenstraße 81" },
          partnerCity: { value: "Filderstadt" },
          partnerZip: { value: "70794" },
          partnerCountry: { value: "DE" },
          duration: {
            type: DurationFieldTypeDtoV1.type.AUTOMATIC_RENEWAL,
            startDate: "2020-01-07",
            endDate: "2023-01-07",
            interval: 12,
            terminationDate: null,
            noticePeriod: null,
            automaticRenewal: null,
          },
          paymentCycle: { value: ContractUpdateDto.paymentCycle.MONTHLY },
          paymentPriceNet: { value: 6000, currency: "EUR" },
          paymentTax: { value: 0 },
          paymentType: { value: ContractUpdateDto.paymentType.EXPENSE },
          paymentMethod: {
            value: ContractUpdateDto.paymentMethod.WIRE_TRANSFER,
          },
          Kostenstelle: { value: "ContractHero -039" },
        },
      },
    },
    {
      // TODO we need a new KFZ Contract for this
      fileName: "c566f042-ce94-46b5-89b2-9b91d0e7369c.pdf",
      categoryName: "KFZ / Leasingvertrag",
      tagNames: ["pflanze", "mohr hydrokultur", "beispielvertrag"],
      tasks: [
        {
          title: "Aufgabe mit monatlicher Erinnerung",
          description: "Eine kleine Beschreibung zur Aufgabe",
          dueDateType: dueDateType.FIXED,
          dueDateConfig: {
            enabled: true,
            dueDate: dayjs().add(7, "day").toDate().toString(),
            repeatConfig: { enabled: true, offset: 1, unit: unit.MONTH },
          },
          reminderConfig: { enabled: true, offset: 5, unit: unit.DAY },
          contractId: "",
          status: status.OPEN,
        },
      ],
      contract: {
        status: ContractUpdateDto.status.ACTIVE,
        name: "Mohr Hydrokultur (Beispielvertrag)",
        fields: {
          internalTeam: { value: "General & Admin" },
          partnerCompany: { value: "Mohr Hydrokultur" },
          partnerPerson: { value: "Günter Mohr" },
          partnerAddress: { value: "Pappelallee 39A" },
          partnerCity: { value: "München" },
          partnerZip: { value: "80995" },
          partnerCountry: { value: "DE" },
          duration: {
            type: null,
            startDate: "2018-08-08",
            endDate: null,
            terminationDate: null,
            automaticRenewal: null,
            interval: null,
            noticePeriod: null,
          },
          paymentCycle: { value: ContractUpdateDto.paymentCycle.MONTHLY },
          paymentPriceNet: { value: 288, currency: "EUR" },
          paymentTax: { value: 19 },
          paymentType: { value: ContractUpdateDto.paymentType.EXPENSE },
          paymentMethod: {
            value: ContractUpdateDto.paymentMethod.DIRECT_DEBIT,
          },
        },
      },
    },
    {
      fileName: "c566f042-ce94-46b5-89b2-9b91d0e7369c.pdf",
      categoryName: "Versicherungsvertrag",
      tagNames: ["versicherung", "hanse merkur", "beispielvertrag"],
      tasks: [
        {
          title: "Aufgabe mit Fälligkeitsdatum",
          description: "Eine kleine Beschreibung zur Aufgabe",
          dueDateType: dueDateType.FIXED,
          dueDateConfig: {
            enabled: true,
            dueDate: dayjs().add(1, "day").toDate().toString(),
          },
          contractId: "",
          status: status.OPEN,
        },
      ],
      contract: {
        status: ContractUpdateDto.status.ACTIVE,
        name: "Hanse Merkur Versicherung (Beispielvertrag)",
        fields: {
          internalTeam: { value: "Personalabteilung" },
          duration: {
            type: DurationFieldTypeDtoV1.type.AUTOMATIC_RENEWAL,
            startDate: "2016-08-01",
            endDate: "2020-08-01",
            terminationDate: "2020-01-05",
            automaticRenewal: 12,
            interval: 3,
            noticePeriod: null,
          },
          partnerCompany: { value: "Hanse Merkur" },
          partnerEmail: { value: "hans.merkur@example.org" },
          partnerPhone: { value: "01578404030" },
          partnerPerson: { value: "Hans Merkur" },
          partnerCity: { value: "Berlin" },
          partnerZip: { value: "14166" },
          partnerCountry: { value: "DE" },
          paymentCycle: { value: ContractUpdateDto.paymentCycle.YEARLY },
          paymentPriceNet: { value: 37.54, currency: "EUR" },
          paymentTax: { value: 19 },
          paymentType: { value: ContractUpdateDto.paymentType.EXPENSE },
          paymentMethod: {
            value: ContractUpdateDto.paymentMethod.DIRECT_DEBIT,
          },
        },
      },
    },
    {
      fileName: "c9028f8a-63a4-4981-81a8-eaceae0b9e69.pdf",
      categoryName: "Software & IT-Vertrag",
      tagNames: [
        "software",
        "helpshift",
        "tech",
        "infrastruktur",
        "beispielvertrag",
      ],
      contract: {
        status: ContractUpdateDto.status.ACTIVE,
        name: "Helpshift (Beispielvertrag)",
        fields: {
          internalTeam: { value: "General & Admin" },
          duration: {
            type: DurationFieldTypeDtoV1.type.AUTOMATIC_RENEWAL,
            startDate: "2018-11-15",
            endDate: "2019-12-14",
            interval: 12,
            terminationDate: null,
            automaticRenewal: null,
            noticePeriod: null,
          },
          partnerCompany: { value: "Helpshift Inc." },
          partnerPerson: { value: "Sandra Sonntag" },
          partnerCity: { value: "Berlin" },
          partnerCountry: { value: "DE" },
          paymentCycle: { value: ContractUpdateDto.paymentCycle.YEARLY },
          paymentPriceNet: { value: 19980, currency: "EUR" },
          paymentTax: { value: 19 },
          paymentType: { value: ContractUpdateDto.paymentType.EXPENSE },
          paymentMethod: { value: ContractUpdateDto.paymentMethod.CREDIT_CARD },
        },
      },
    },
    {
      fileName: "e897f829-c90b-496e-a587-0f626e1bf99a.pdf",
      categoryName: "Kreditvertrag",
      tagNames: ["bank", "darlehen", "manilla", "beispielvertrag"],
      contract: {
        status: ContractUpdateDto.status.ACTIVE,
        name: "Darlehensvertrag (Beispielvertrag)",
        fields: {
          internalTeam: { value: "Vertrieb" },
          duration: {
            type: DurationFieldTypeDtoV1.type.AUTOMATIC_RENEWAL,
            startDate: "2022-01-01",
            endDate: "2022-03-01",
            terminationDate: null,
            automaticRenewal: null,
            interval: 3,
            noticePeriod: null,
          },
          partnerCompany: { value: "Lukas Manilla" },
          partnerPerson: { value: "Lukas Manilla" },
          partnerEmail: { value: "manilla@example.net" },
          partnerPhone: { value: "0157 34215345" },
          partnerAddress: { value: "Manillaweg 17" },
          partnerZip: { value: "03335" },
          partnerCity: { value: "Kiel" },
          partnerCountry: { value: "DE" },
          paymentCycle: { value: ContractUpdateDto.paymentCycle.MONTHLY },
          paymentPriceNet: { value: 1500, currency: "EUR" },
          paymentTax: { value: 0 },
          paymentType: { value: ContractUpdateDto.paymentType.INCOME },
          paymentMethod: {
            value: ContractUpdateDto.paymentMethod.DIRECT_DEBIT,
          },
          IBAN: { value: "DE43 3333 4444 5555 56" },
          BIC: { value: "DENTOAT456KT" },
        },
      },
    },
    {
      fileName: "e897f829-c90b-496e-a587-0f626e1bf99a.pdf",
      categoryName: "Kreditvertrag",
      tagNames: ["dienstleistung", "reinigung", "b worx", "beispielvertrag"],
      contract: {
        status: ContractUpdateDto.status.ACTIVE,
        name: "Reinigungsfirma_Berlin (Beispielvertrag)",
        fields: {
          internalTeam: { value: "General & Admin" },
          duration: {
            type: DurationFieldTypeDtoV1.type.AUTOMATIC_RENEWAL,
            startDate: "2019-05-01",
            endDate: "2023-02-23",
            terminationDate: null,
            automaticRenewal: null,
            interval: null,
            noticePeriod: null,
          },
          partnerCompany: { value: "B worx" },
          partnerPerson: { value: "Alexander Beer" },
          partnerEmail: { value: "office@example.com" },
          partnerPhone: { value: "030 / 43 6025 00" },
          partnerAddress: { value: "Egellsstraße 21" },
          partnerZip: { value: "13507" },
          partnerCity: { value: "Berlin" },
          partnerCountry: { value: "DE" },
          paymentCycle: { value: ContractUpdateDto.paymentCycle.MONTHLY },
          paymentPriceNet: { value: 1348.75, currency: "EUR" },
          paymentTax: { value: 19 },
          paymentType: { value: ContractUpdateDto.paymentType.EXPENSE },
          paymentMethod: {
            value: ContractUpdateDto.paymentMethod.WIRE_TRANSFER,
          },
          IBAN: { value: "DE61 1005 0000 0190 2301 18" },
          BIC: { value: "DELABEBEXXX" },
        },
      },
    },
    {
      fileName: "509b7159-d73b-4161-99c6-5e39218cd9c5.pdf",
      categoryName: "Weiteres",
      tagNames: ["shareholder", "demandgen", "beispielvertrag"],
      contract: {
        status: ContractUpdateDto.status.ACTIVE,
        name: "Shareholder Agreement (Beispielvertrag)",
        fields: {
          internalTeam: { value: "Management" },
          duration: {
            type: DurationFieldTypeDtoV1.type.AUTOMATIC_RENEWAL,
            startDate: "2022-01-01",
            endDate: "2027-01-01",
            terminationDate: null,
            automaticRenewal: null,
            interval: null,
            noticePeriod: null,
          },
          partnerCompany: { value: "Demandgen GmbH" },
          partnerPerson: { value: "Demandgen GmbH" },
          partnerEmail: { value: "demand@example.org" },
          partnerPhone: { value: "0157829322445" },
          partnerAddress: { value: "Torstraße 55" },
          partnerZip: { value: "15165" },
          partnerCity: { value: "Berlin" },
          partnerCountry: { value: "DE" },
          paymentCycle: { value: ContractUpdateDto.paymentCycle.BIMONTHLY },
          paymentPriceNet: { value: 54252, currency: "EUR" },
          paymentTax: { value: 0 },
          paymentType: { value: ContractUpdateDto.paymentType.INCOME },
        },
      },
    },
    {
      fileName: "b9a8a9c0-4728-424d-84a6-95e490817524.pdf",
      categoryName: "Immobilien / Mietvertrag",
      tagNames: ["mietvertrag", "aroundcity", "beispielvertrag"],
      tasks: [
        {
          title: "Demo-Aufgabe",
          description: "Eine kleine Beschreibung zur Aufgabe",
          dueDateType: dueDateType.FIXED,
          dueDateConfig: {
            enabled: true,
            dueDate: dayjs().toDate().toString(),
            repeatConfig: { enabled: true, offset: 1, unit: unit.YEAR },
          },
          reminderConfig: { enabled: true, offset: 2, unit: unit.DAY },
          contractId: "",
          status: status.OPEN,
        },
      ],
      contract: {
        status: ContractUpdateDto.status.ACTIVE,
        name: "Büro Berlin (Beispielvertrag)",
        fields: {
          internalTeam: { value: "General" },
          duration: {
            type: DurationFieldTypeDtoV1.type.AUTOMATIC_RENEWAL,
            startDate: "2022-09-21",
            endDate: "2024-09-21",
            terminationDate: null,
            automaticRenewal: null,
            interval: 24,
            noticePeriod: null,
          },
          partnerCompany: { value: "Aroundcity Immo GmbH" },
        },
      },
    },
    {
      fileName: "b9a8a9c0-4728-424d-84a6-95e490817524.pdf",
      categoryName: "NDA",
      tagNames: ["nda", "immobilienverwaltung", "beispielvertrag"],
      tasks: [
        {
          title: "Immobilienverwaltungs Aufgabe",
          description: "Eine kleine Beschreibung zur Aufgabe",
          dueDateType: dueDateType.FIXED,
          dueDateConfig: {
            enabled: true,
            dueDate: dayjs().add(12, "day").toDate().toString(),
          },
          reminderConfig: { enabled: true, offset: 5, unit: unit.DAY },
          contractId: "",
          status: status.OPEN,
        },
      ],
      contract: {
        status: ContractUpdateDto.status.ACTIVE,
        name: "NDA Krefeld Immobilienverwaltungs GmbH (Beispielvertrag)",
        fields: {
          internalTeam: { value: "Legal" },
          duration: {
            type: DurationFieldTypeDtoV1.type.INDEFINITE_DURATION,
            startDate: "2022-04-03",
            endDate: null,
            terminationDate: null,
            automaticRenewal: null,
            interval: null,
            noticePeriod: 3,
          },
          partnerCompany: { value: "Krefeld Immobilienverwaltung" },
          partnerPerson: { value: "Peter Krefeld" },
          partnerEmail: { value: "peter.k@example.com" },
          partnerPhone: { value: "013353539058" },
          partnerAddress: { value: "Wilhelmshofallee 91" },
          partnerZip: { value: "47800" },
          partnerCity: { value: "Krefeld" },
          partnerCountry: { value: "DE" },
          paymentCycle: { value: ContractUpdateDto.paymentCycle.NO_PAYMENT },
          paymentType: { value: ContractUpdateDto.paymentType.OTHER },
        },
      },
    },
    {
      fileName: "13f1ee01-8962-42f0-b117-997456cdb39f.pdf",
      categoryName: "Kundenvertrag",
      tagNames: ["kunde", "umsatz", "beispielvertrag"],
      contract: {
        status: ContractUpdateDto.status.ACTIVE,
        name: "Kundenvertrag (Christian Reichert) (Beispielvertrag)",
        fields: {
          internalTeam: { value: "Vertrieb" },
          duration: {
            type: DurationFieldTypeDtoV1.type.AUTOMATIC_RENEWAL,
            startDate: "2022-01-01",
            endDate: "2023-01-01",
            terminationDate: null,
            automaticRenewal: null,
            interval: 12,
            noticePeriod: null,
          },
          partnerCompany: { value: "Christian Reichert" },
          partnerPerson: { value: "Christian Reichert" },
          partnerEmail: { value: "cr@example.org" },
          partnerPhone: { value: "+49 224071041" },
          partnerAddress: { value: "Max-Petermann-Platz 1" },
          partnerZip: { value: "47798" },
          partnerCity: { value: "Krefeld" },
          partnerCountry: { value: "DE" },
          paymentCycle: { value: ContractUpdateDto.paymentCycle.YEARLY },
          paymentType: { value: ContractUpdateDto.paymentType.INCOME },
          paymentPriceNet: { value: 2388, currency: "EUR" },
          paymentTax: { value: 19 },
          paymentMethod: {
            value: ContractUpdateDto.paymentMethod.DIRECT_DEBIT,
          },
        },
      },
    },
    {
      fileName: "b7a89d83-60c9-4465-b271-68c7065cab84.pdf",
      categoryName: "Kundenvertrag",
      tagNames: ["avv", "immobilienverwaltung", "beispielvertrag"],
      contract: {
        status: ContractUpdateDto.status.ACTIVE,
        name: "AVV Krefeld Immobilienverwaltungs GmbH (Beispielvertrag)",
        fields: {
          internalTeam: { value: "Vertrieb" },
          duration: {
            type: DurationFieldTypeDtoV1.type.AUTOMATIC_RENEWAL,
            startDate: "2022-01-01",
            endDate: "2023-01-01",
            terminationDate: null,
            automaticRenewal: null,
            interval: 12,
            noticePeriod: null,
          },
          partnerCompany: { value: "Krefeld Immobilienverwaltungs GmbH" },
          partnerPerson: { value: "Giklas Nröbel" },
          partnerEmail: { value: "Gik.las@example.net" },
          partnerPhone: { value: "0134 892334" },
          partnerAddress: { value: "Wilhemshofallee 91" },
          partnerZip: { value: "47800" },
          partnerCity: { value: "Krefeld" },
          partnerCountry: { value: "DE" },
          paymentCycle: { value: ContractUpdateDto.paymentCycle.NO_PAYMENT },
          paymentType: { value: ContractUpdateDto.paymentType.OTHER },
          paymentPriceNet: { value: 0, currency: "EUR" },
          paymentTax: { value: 19 },
        },
      },
    },
  ];

  const tagToContracts = new Map<string, string[]>();

  const getAddressbook = (
    contractData: ContractInputDTOV1 & { name: string },
    contactType: ContactTypeDTO
  ): ContractInputDTOV1 & { name: string } => {
    if (!contractData.fields) return contractData;
    const contactData: ContactCreateDTO = {
      typeId: contactType.id,
    };

    const fields = {
      partnerCompany: "name",
      partnerPerson: "person",
      partnerPhone: "phone",
      partnerAddress: "address",
      partnerCity: "city",
      partnerZip: "postalCode",
      partnerCountry: "country",
    };

    for (const [contractField, contactField] of Object.entries(fields)) {
      if (contractData.fields[contractField]) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        contactData[contactField] = {
          value: contractData.fields[contractField],
        };
        delete contractData.fields[contractField];
      }
    }

    contractData.fields.partnerCompany = { $create: contactData, value: null };
    return contractData;
  };

  const contactTypes = await OrganizationService.getContactTypes(parentTeamId);

  await Promise.all(
    dummyContracts.map(async (dummyContract) => {
      const file = await fetch(`/assets/sample-data/${dummyContract.fileName}`);
      const fileBlob = await file.blob();
      const fileOfBlob = new File(
        [fileBlob],
        `${dummyContract.contract.name}.pdf`,
        {
          type: "application/pdf",
        }
      );
      const formData = {
        file: fileOfBlob,
        categoryId: categories.get(dummyContract.categoryName)?.id,
      };

      const contract = await ContractAttachmentService.createContract(
        true,
        teamId,
        formData
      );

      let updateContractData = dummyContract.contract;

      if (hasAdressbook && dummyContract.contract.fields) {
        updateContractData = getAddressbook(
          dummyContract.contract,
          contactTypes[0]
        );
      }

      const adjustedFields: ContractInputDTOV1["fields"] = {};
      for (const [k, v] of Object.entries(updateContractData.fields ?? {})) {
        const key = getFieldIdByVisibleId(k) ?? getFieldIdByName(k);
        if (!key) {
          Sentry.captureMessage(
            `Couldn't find field id in teams field data for dummy contract key ${k}`
          );
          continue;
        }
        adjustedFields[key] = v;
      }

      updateContractData.fields = adjustedFields;

      await OrganizationService.updateContract(
        teamId,
        contract.id,
        updateContractData
      );

      if (dummyContract.tasks) {
        for (const taskData of dummyContract.tasks) {
          await TaskNewService.createTask(organizationId, teamId, {
            ...taskData,
            contractId: contract.id,
          });
        }
      }

      for (const tagName of dummyContract.tagNames) {
        const tagToContractEntry = tagToContracts.get(tagName);
        if (!tagToContractEntry) {
          tagToContracts.set(tagName, [contract.id]);
          continue;
        }
        tagToContractEntry.push(contract.id);
      }
    })
  );

  for (const [tagName, contractIds] of tagToContracts.entries()) {
    let tag = tags.get(tagName);
    if (!tag) {
      tag = await TeamService.addTag(teamId, {
        name: tagName,
        color: "#2788b2",
      });
      tags.set(tagName, tag);
    }

    void Promise.all(
      contractIds.map((contractId) => {
        if (tag) {
          return ContractService.addTags(contractId, {
            id: tag.id,
          });
        }
        return undefined;
      })
    );
  }
}
