import React from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useSignatureProvider } from "pages/Contract/hooks/useSignature";
import { FormTextField } from "components";
import { SignatureRequestCancelDTO } from "openapi";

const initialValues = { cancelReason: "" };

type Props = {
  handleClose: () => void;
  contractId?: string | null;
  refetchData?: () => void;
  provider?: string;
  setIsCancelling?: (value: boolean) => void;
};

const ContractCancelSignature = ({
  contractId,
  handleClose,
  refetchData,
  provider,
  setIsCancelling,
}: Props) => {
  const { t } = useTranslation();
  const { handleSubmit, control } = useForm({ defaultValues: initialValues });
  const { cancelSignature } = useSignatureProvider();

  const onSubmit = async (body: SignatureRequestCancelDTO) => {
    if (!contractId) return;
    await cancelSignature(
      contractId,
      body,
      provider,
      refetchData,
      setIsCancelling
    );
    handleClose();
  };

  return (
    <>
      <form
        onSubmit={handleSubmit(onSubmit)}
        name="contractCancelRequestSignatureForm"
        noValidate
        id="cancelSignatureForm"
      >
        {provider === "DROPBOX" && (
          <>
            <span>
              {t("pages.contractDetails.modals.cancelSignature.description")}
            </span>
            <FormTextField
              control={control}
              name="cancelReason"
              label={t(
                "pages.contractDetails.modals.cancelSignature.cancelReasonLabel"
              )}
              rows={3}
              multiline
            />
          </>
        )}
      </form>
    </>
  );
};

export default ContractCancelSignature;
