import * as Yup from "yup";
import { Language } from "../shared/i18n/i18n";
import dayjs from "dayjs";
import routePaths from "./routePaths";
import { ContractDTOV1, ContractFieldDto } from "openapi";
import localizedFormat from "dayjs/plugin/localizedFormat";
import freeEmailDomains from "./free-email-domains.json";
import parsePhoneNumber from "libphonenumber-js/max";
import { t, TFunction } from "i18next";
import theme from "theme/theme";
import utc from "dayjs/plugin/utc";
import { AcceptedFileType } from "shared/enums/document.enum";
import { acceptedMimetypeExtensionMap } from "./constants";

dayjs.extend(localizedFormat);
dayjs.extend(utc);

export const dateFormats = {
  EU_DOTS: "DD.MM.YYYY",
  US_DASHES: "MM-DD-YYYY",
  US_SLASHES: "MM/DD/YYYY",
  NORMAL: "YYYY-MM-DD",
};

export function nonFreeEmailVaidator() {
  return Yup.string()
    .trim()
    .email()
    .test("non-free-email", "common.validation.freeEmailForbidden", (value) => {
      const domain = value?.substring(value.lastIndexOf("@") + 1);
      if (!domain) {
        return false;
      }
      return !freeEmailDomains.includes(domain);
    })
    .required();
}

export function phoneValidator() {
  return Yup.string()
    .trim()
    .test("phone-number", "common.validation.invalidPhoneNumber", (value) => {
      if (!value) {
        return false;
      }

      const phoneNumber = parsePhoneNumber(value, "DE");
      if (!phoneNumber) {
        return false;
      }

      return phoneNumber.isValid();
    })
    .required();
}

export function maxDecimalConfigurationValueValidator(maxValue: number) {
  return Yup.string()
    .test(
      "max-decimal-configuration-value",
      t(
        "pages.contractEdit.modals.customField.NumberFieldConfigurationValidation",
        { maxValue }
      ),
      (value) => {
        if (value == null || value.trim() === "") {
          return true;
        }
        const number = parseInt(value, 10);
        return !isNaN(number) && number >= 0 && number <= maxValue;
      }
    )
    .required("Configuration is required");
}

export function addMonthSuffix(t: TFunction, months: number | undefined) {
  return addSuffix(
    t(
      (months ?? 0) > 1
        ? "pages.contractDetails.duration.months"
        : "pages.contractDetails.duration.month"
    ),
    months || undefined
  );
}

export function passwordValidator() {
  return Yup.string().required().min(6, "common.validation.passwordTooShort");
}

export function dateFormatter(
  locale: Language,
  date?: dayjs.Dayjs | string | null,
  userDateFormat?: string
) {
  if (date) {
    const utcDate = dayjs.utc(date);
    return utcDate.locale(locale).format(userDateFormat || dateFormats.EU_DOTS);
  } else {
    return "–";
  }
}

export const paymentFormatter = (
  locale: Language,
  amount: number | null,
  currency: string | null
): string => {
  if (amount == null) {
    return "-";
  }

  return new Intl.NumberFormat(locale, {
    style: "currency",
    currency: currency || "EUR",
  }).format(amount);
};

export const addSuffix = (suffix: string, months?: number): string => {
  if (months) {
    return `${months}${suffix}`;
  } else {
    return "–";
  }
};

export const getNavigationToContractViewById = (id?: string | null) => {
  if (id) return routePaths.CONTRACT_VIEW.replace(":id", id);
  return routePaths.CONTRACTS;
};

export const convertContractName = (contractName: string) => {
  const downloadFileName = contractName
    .replace(/[\\<>:;,?"*|/]/g, "")
    .trim()
    .concat(".pdf");

  return downloadFileName;
};

export const compareCaseInsensitive = (valueA: string, valueB: string) => {
  return valueA.localeCompare(valueB);
};

export type ContractDtoWithResolvedParent = ContractDTOV1 & {
  parent: ContractDtoWithResolvedParent | null;
};

export const resolveParentsOfContractDto = (
  contracts: ContractDTOV1[]
): ContractDtoWithResolvedParent[] => {
  const contractDictionary: Record<string, ContractDtoWithResolvedParent> = {};
  for (const contract of contracts) {
    contractDictionary[contract.id] = {
      ...contract,
      parent: null,
    };
  }

  for (const contract of contracts) {
    if (!contract.parentId) {
      contractDictionary[contract.id].parent = null;
      continue;
    }
    contractDictionary[contract.id].parent =
      contractDictionary[contract.parentId];
  }
  return Object.values(contractDictionary);
};

export const selectableCustomFieldTypes = (
  excludedFields: ContractFieldDto.type[]
) =>
  Object.keys(ContractFieldDto.type).filter((type) => {
    return !excludedFields.includes(type as ContractFieldDto.type);
  });

export const getContractTypeColor = (type: ContractDTOV1.type) => {
  return type === ContractDTOV1.type.MAIN_CONTRACT
    ? theme.color.blue[600]
    : theme.color.green[600];
};

export const addScript = ({
  src,
  id,
  onLoad,
}: {
  src: string;
  id: string;
  onLoad: () => void;
}) => {
  const existing = document.getElementById(id);
  if (existing) {
    onLoad();
  } else {
    const script = document.createElement("script");
    script.src = src;
    script.id = id;
    script.type = "module";
    script.async = true;
    script.onload = (e) => {
      if (onLoad) {
        onLoad();
      }
    };
    document.body.appendChild(script);
  }
};

export const isAcceptedFileType = (
  value: string
): value is AcceptedFileType => {
  return Object.values(AcceptedFileType).includes(value as AcceptedFileType);
};

// removes extension from file name
export const extractFileName = (name: string) => {
  return name ? name.substring(0, name.lastIndexOf(".")) || name : "";
};

export const extractFileExtension = (name?: string, type?: string) => {
  // Check if a valid name is provided and extract extension from it
  const extFromName =
    name && name.includes(".") ? name.substring(name.lastIndexOf(".")) : "";

  // If no valid extension from name, try to extract from type if provided
  if (!extFromName && type) {
    //TODO: migrate template documents mimetype to contracthero/template
    //Clickup ticket: #86c0vz1ap
    return acceptedMimetypeExtensionMap[type] || "";
  }

  return extFromName || "";
};
