import React, { FC } from "react";
import { ToolbarButton } from "./ToolbarButton";
import { ToolbarSpecificButtonProps } from "./types";
import { useTranslation } from "react-i18next";
import ExpandIcon from "assets/svg/expand-icon.svg?react";

export const FullscreenButton: FC<
  ToolbarSpecificButtonProps<"EnterFullScreen"> & { fileUrl: string }
> = ({ Slot, fileUrl }) => {
  const { t } = useTranslation();
  return (
    <Slot>
      {(props) => {
        return (
          <ToolbarButton
            tooltip={t("pdfViewer.toolbar.fullscreen")}
            onClick={() => {
              window.open(fileUrl, "_blank");
            }}
          >
            <ExpandIcon />
          </ToolbarButton>
        );
      }}
    </Slot>
  );
};
