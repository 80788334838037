import React, { useEffect, useState, useRef, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { $isListNode, ListNode } from "@lexical/list";
import { $findMatchingParent, $getNearestNodeOfType } from "@lexical/utils";
import { $isHeadingNode, $isQuoteNode } from "@lexical/rich-text";
import { $isLinkNode } from "@lexical/link";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import {
  $getNearestBlockElementAncestorOrThrow,
  mergeRegister,
} from "@lexical/utils";
import {
  $createParagraphNode,
  $getSelection,
  $isRangeSelection,
  CAN_REDO_COMMAND,
  CAN_UNDO_COMMAND,
  FORMAT_ELEMENT_COMMAND,
  FORMAT_TEXT_COMMAND,
  REDO_COMMAND,
  SELECTION_CHANGE_COMMAND,
  UNDO_COMMAND,
  $isRootOrShadowRoot,
  $isTextNode,
  COMMAND_PRIORITY_LOW,
  ElementFormatType,
  $isElementNode,
} from "lexical";
import {
  $getSelectionStyleValueForProperty,
  $patchStyleText,
} from "@lexical/selection";
import { $isDecoratorBlockNode } from "@lexical/react/LexicalDecoratorBlockNode";
import { $isTableSelection, INSERT_TABLE_COMMAND } from "@lexical/table";

import { Divider, Popover } from "@mui/material";
import UndoIcon from "@mui/icons-material/Undo";
import RedoIcon from "@mui/icons-material/Redo";
import BoldIcon from "assets/svg/editor/Bold.svg?react";
import ItalicIcon from "assets/svg/editor/Italic.svg?react";
import UnderlineIcon from "assets/svg/editor/Underline.svg?react";
import StrikethroughIcon from "assets/svg/editor/Strikethrough.svg?react";
import OrderList from "assets/svg/editor/OrderList.svg?react";
import Order123 from "assets/svg/editor/Order123.svg?react";
import Table from "assets/svg/editor/Table.svg?react";
import PageBreaker from "assets/svg/editor/PageBreaker.svg?react";
import BgColor from "assets/svg/editor/BgColour.svg?react";
import FontColor from "assets/svg/editor/FontColour.svg?react";
import ClearFormatting from "assets/svg/editor/ClearIcon.svg?react";

import AlignLeftIcon from "assets/svg/editor/AlignLeft.svg?react";
import AlignCenterIcon from "assets/svg/editor/AlignCentre.svg?react";
import AlignRightIcon from "assets/svg/editor/AlignRight.svg?react";
import AlignJustifyIcon from "assets/svg/editor/AlignJustify.svg?react";
import ImageIcon from "assets/svg/editor/Image.svg?react";
import DotsHorizontalIcon from "assets/svg/dots-horizontal.svg?react";

import FontSize from "../../components/FontSize";
import { FontSelector } from "../../components/FontSelector";
import { InsertImageModal } from "../../components/InsertImageModal/InsertImageModal";
import EditorToolbarButton from "../../components/EditorToolbarButton/EditorToolbarButton";
import ColorPicker from "../ColorPicker";
import { INSERT_IMAGE_COMMAND } from "../ImagePlugin";
import { INSERT_PAGE_BREAK } from "../PageBreakPlugin";
import {
  BLOCK_TYPES,
  BlockType,
  FormatSelector,
} from "../../components/FormatSelector";
import {
  formatBulletList,
  formatNumberedList,
  getSelectedNode,
} from "../../utils";
import { MoreMenuWrapper, paperStyles, Wrapper } from "./styles";

export const toolbarSizes = {
  xxs: 460,
  xs: 520,
  sm: 660,
  lg: 760,
  xl: 850,
  full: 900,
};

export default function ToolbarPlugin() {
  const { t } = useTranslation();
  const [editor] = useLexicalComposerContext();
  const [showImageModal, setShowImageModal] = useState(false);
  const [canUndo, setCanUndo] = useState(false);
  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  const [isUnderline, setIsUnderline] = useState(false);
  const [isStrikethrough, setIsStrikethrough] = useState(false);
  const [fontSize, setFontSize] = useState<string>("15px");
  const [fontFamily, setFontFamily] = useState<string>("Arial");
  const [fontColor, setFontColor] = useState<string>("#000");
  const [backgroundColor, setBackgroundColor] = useState<string>("");
  const [elementFormat, setElementFormat] = useState<ElementFormatType>("left");

  const [blockType, setBlockType] = useState<BlockType>("paragraph");

  const toolbarRef = useRef<HTMLDivElement | null>(null);
  const [editorWidth, setEditorWidth] = useState<number | undefined>(undefined);

  useEffect(() => {
    const handleResize = () => {
      if (toolbarRef.current) {
        // Needed for waiting until container is available (Find a better way to do this one day).
        setTimeout(() => {
          const toolbarWidth = toolbarRef?.current?.offsetWidth;
          setEditorWidth(toolbarWidth);
        }, 0);
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [toolbarRef]);

  const $updateToolbar = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      // Update text format
      setIsBold(selection.hasFormat("bold"));
      setIsItalic(selection.hasFormat("italic"));
      setIsUnderline(selection.hasFormat("underline"));
      setIsStrikethrough(selection.hasFormat("strikethrough"));

      const node = getSelectedNode(selection);
      const parent = node.getParent();

      const anchorNode = selection.anchor.getNode();
      let element =
        anchorNode.getKey() === "root"
          ? anchorNode
          : $findMatchingParent(anchorNode, (e) => {
              const parent = e.getParent();
              return parent !== null && $isRootOrShadowRoot(parent);
            });

      if (element === null) {
        element = anchorNode.getTopLevelElementOrThrow();
      }

      const elementDOM = editor.getElementByKey(element.getKey());

      if (elementDOM != null) {
        if ($isListNode(element)) {
          const parentList = $getNearestNodeOfType(anchorNode, ListNode);
          const type = parentList
            ? parentList.getListType()
            : element.getListType();
          setBlockType(type);
        } else {
          const type = $isHeadingNode(element)
            ? element.getTag()
            : element.getType();
          if (BLOCK_TYPES.includes(type as BlockType)) {
            setBlockType(type as BlockType);
          }
        }
      }

      setFontFamily(
        $getSelectionStyleValueForProperty(selection, "font-family", "Arial")
      );
      setFontColor(
        $getSelectionStyleValueForProperty(selection, "color", "#000")
      );
      setBackgroundColor(
        $getSelectionStyleValueForProperty(
          selection,
          "background-color",
          backgroundColor
        )
      );

      let matchingParent;
      if ($isLinkNode(parent)) {
        // If node is a link, we need to fetch the parent paragraph node to set format
        matchingParent = $findMatchingParent(
          node,
          (parentNode) => $isElementNode(parentNode) && !parentNode.isInline()
        );
      }

      // If matchingParent is a valid node, pass it's format type
      let elementFormat: ElementFormatType = "";
      if ($isElementNode(matchingParent)) {
        elementFormat = matchingParent.getFormatType();
      } else {
        elementFormat = $isElementNode(node)
          ? node.getFormatType()
          : parent?.getFormatType() || "";
      }
      setElementFormat(elementFormat || "left");

      if ($isRangeSelection(selection) || $isTableSelection(selection)) {
        setFontSize(
          $getSelectionStyleValueForProperty(selection, "font-size", "15px")
        );
      }
    }
  }, [editor]);

  const applyStyleText = useCallback(
    (styles: Record<string, string>, skipHistoryStack?: boolean) => {
      editor.update(
        () => {
          const selection = $getSelection();
          if (selection !== null) {
            $patchStyleText(selection, styles);
          }
        },
        skipHistoryStack ? { tag: "historic" } : {}
      );
    },
    [editor]
  );

  const updateBackgroundColor = useCallback(
    (newColor: string, skipHistoryStack: boolean) => {
      applyStyleText(
        {
          "background-color": newColor,
        },
        skipHistoryStack
      );
    },
    [applyStyleText]
  );

  const updateFontColor = useCallback(
    (newColor: string, skipHistoryStack: boolean) => {
      applyStyleText(
        {
          color: newColor,
        },
        skipHistoryStack
      );
    },
    [applyStyleText]
  );

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          $updateToolbar();
        });
      }),
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        (_payload, _newEditor) => {
          $updateToolbar();
          return false;
        },
        COMMAND_PRIORITY_LOW
      ),
      editor.registerCommand(
        CAN_UNDO_COMMAND,
        (payload) => {
          setCanUndo(payload);
          return false;
        },
        COMMAND_PRIORITY_LOW
      ),
      editor.registerCommand(
        CAN_REDO_COMMAND,
        () => {
          return false;
        },
        COMMAND_PRIORITY_LOW
      )
    );
  }, [editor, $updateToolbar]);

  const clearFormatting = useCallback(() => {
    editor.update(() => {
      const selection = $getSelection();
      if ($isRangeSelection(selection) || $isTableSelection(selection)) {
        const anchor = selection.anchor;
        const focus = selection.focus;
        const nodes = selection.getNodes();
        const extractedNodes = selection.extract();

        if (anchor.key === focus.key && anchor.offset === focus.offset) {
          return;
        }

        nodes.forEach((node, idx) => {
          // We split the first and last node by the selection
          // So that we don't format unselected text inside those nodes
          if ($isTextNode(node)) {
            // Use a separate variable to ensure TS does not lose the refinement
            let textNode = node;
            if (idx === 0 && anchor.offset !== 0) {
              textNode = textNode.splitText(anchor.offset)[1] || textNode;
            }
            if (idx === nodes.length - 1) {
              textNode = textNode.splitText(focus.offset)[0] || textNode;
            }
            /**
             * If the selected text has one format applied
             * selecting a portion of the text, could
             * clear the format to the wrong portion of the text.
             *
             * The cleared text is based on the length of the selected text.
             */
            // We need this in case the selected text only has one format
            const extractedTextNode = extractedNodes[0];
            if (nodes.length === 1 && $isTextNode(extractedTextNode)) {
              textNode = extractedTextNode;
            }

            if (textNode.__style !== "") {
              textNode.setStyle("");
            }
            if (textNode.__format !== 0) {
              textNode.setFormat(0);
              $getNearestBlockElementAncestorOrThrow(textNode).setFormat("");
            }
            node = textNode;
          } else if ($isHeadingNode(node) || $isQuoteNode(node)) {
            node.replace($createParagraphNode(), true);
          } else if ($isDecoratorBlockNode(node)) {
            node.setFormat("");
          }
        });
      }
    });
  }, [editor]);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    if (editorWidth && editorWidth > toolbarSizes.full) {
      handleOnClose();
    }
  }, [editorWidth]);

  const handleOnClose = () => {
    setAnchorEl(null);
  };

  const handleOnClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <>
      <Wrapper ref={toolbarRef}>
        <EditorToolbarButton
          disabled={!canUndo}
          onClick={() => {
            editor.dispatchCommand(UNDO_COMMAND, undefined);
          }}
          label={t("textEditor.plugins.toolbar.undo")}
          icon={<UndoIcon />}
        />
        <EditorToolbarButton
          disabled={!canUndo}
          onClick={() => {
            editor.dispatchCommand(REDO_COMMAND, undefined);
          }}
          label={t("textEditor.plugins.toolbar.redo")}
          icon={<RedoIcon />}
        />
        <Divider orientation="vertical" flexItem />
        <FormatSelector editor={editor} value={blockType} />
        {editorWidth && editorWidth > toolbarSizes.xxs && (
          <>
            <Divider orientation="vertical" flexItem />
            <FontSelector editor={editor} value={fontFamily} />
          </>
        )}
        <Divider orientation="vertical" flexItem />
        {editorWidth && editorWidth > toolbarSizes.xs && (
          <>
            <FontSize
              selectionFontSize={fontSize.slice(0, -2)}
              editor={editor}
              disabled={false}
            />
            <Divider orientation="vertical" flexItem />
          </>
        )}

        {editorWidth && editorWidth > toolbarSizes.sm && (
          <>
            <EditorToolbarButton
              onClick={() => {
                editor.dispatchCommand(FORMAT_TEXT_COMMAND, "bold");
              }}
              label={t("textEditor.plugins.toolbar.textFormat.bold")}
              icon={<BoldIcon />}
              active={isBold}
            />
            <EditorToolbarButton
              onClick={() => {
                editor.dispatchCommand(FORMAT_TEXT_COMMAND, "italic");
              }}
              label={t("textEditor.plugins.toolbar.textFormat.italic")}
              icon={<ItalicIcon />}
              active={isItalic}
            />
            <EditorToolbarButton
              onClick={() => {
                editor.dispatchCommand(FORMAT_TEXT_COMMAND, "underline");
              }}
              label={t("textEditor.plugins.toolbar.textFormat.underline")}
              icon={<UnderlineIcon />}
              active={isUnderline}
            />
            <EditorToolbarButton
              onClick={() => {
                editor.dispatchCommand(FORMAT_TEXT_COMMAND, "strikethrough");
              }}
              label={t("textEditor.plugins.toolbar.textFormat.strikethrough")}
              icon={<StrikethroughIcon />}
              active={isStrikethrough}
            />
            <EditorToolbarButton
              onClick={() => {
                formatBulletList(editor, blockType);
              }}
              label={t("textEditor.plugins.toolbar.blockType.bullet")}
              icon={<OrderList />}
              active={blockType === "bullet"}
            />
            <EditorToolbarButton
              onClick={() => {
                formatNumberedList(editor, blockType);
              }}
              label={t("textEditor.plugins.toolbar.blockType.number")}
              icon={<Order123 />}
              active={blockType === "number"}
            />
          </>
        )}

        {editorWidth && editorWidth > toolbarSizes.lg && (
          <>
            <EditorToolbarButton
              onClick={() => {
                // TODO: add modal
                editor.dispatchCommand(INSERT_TABLE_COMMAND, {
                  columns: String(3),
                  rows: String(2),
                  includeHeaders: true,
                });
              }}
              label={t("textEditor.plugins.toolbar.insertTable")}
              icon={<Table />}
            />
            <ColorPicker
              color={fontColor}
              onChange={updateFontColor}
              ToggleButton={EditorToolbarButton}
              toggleButtonProps={{
                icon: <FontColor />,
                label: t("textEditor.plugins.toolbar.fontColor"),
              }}
            />
            <ColorPicker
              color={backgroundColor}
              onChange={updateBackgroundColor}
              ToggleButton={EditorToolbarButton}
              toggleButtonProps={{
                icon: <BgColor />,
                label: t("textEditor.plugins.toolbar.backgroundColor"),
              }}
            />
            <EditorToolbarButton
              onClick={() => {
                editor.dispatchCommand(INSERT_PAGE_BREAK, undefined);
              }}
              label={t("textEditor.plugins.toolbar.insertPageBreak")}
              icon={<PageBreaker />}
            />
            <Divider orientation="vertical" flexItem />
          </>
        )}
        {editorWidth && editorWidth > toolbarSizes.xl && (
          <>
            <EditorToolbarButton
              onClick={() => {
                editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "left");
              }}
              label={t("textEditor.plugins.toolbar.textAlign.left")}
              icon={<AlignLeftIcon />}
              active={elementFormat === "left"}
            />
            <EditorToolbarButton
              onClick={() => {
                editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "center");
              }}
              label={t("textEditor.plugins.toolbar.textAlign.center")}
              icon={<AlignCenterIcon />}
              active={elementFormat === "center"}
            />
            <EditorToolbarButton
              onClick={() => {
                editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "right");
              }}
              label={t("textEditor.plugins.toolbar.textAlign.right")}
              icon={<AlignRightIcon />}
              active={elementFormat === "right"}
            />
            <EditorToolbarButton
              onClick={() => {
                editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "justify");
              }}
              label={t("textEditor.plugins.toolbar.textAlign.justify")}
              icon={<AlignJustifyIcon />}
              active={elementFormat === "justify"}
            />
          </>
        )}

        {editorWidth && editorWidth > toolbarSizes.full && (
          <>
            <EditorToolbarButton
              onClick={() => {
                setShowImageModal(true);
              }}
              label={t("textEditor.plugins.toolbar.insertImage")}
              icon={<ImageIcon />}
            />

            <Divider orientation="vertical" flexItem />
            <EditorToolbarButton
              onClick={() => {
                clearFormatting();
              }}
              label={t("textEditor.plugins.toolbar.clearFormat")}
              icon={<ClearFormatting />}
            />
          </>
        )}
        {editorWidth && editorWidth < toolbarSizes.full && (
          <EditorToolbarButton
            onClick={(e) => handleOnClick(e)}
            label={t("textEditor.plugins.toolbar.more")}
            icon={<DotsHorizontalIcon />}
            active={open}
          />
        )}
      </Wrapper>

      <Popover
        open={open}
        onClose={handleOnClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        PaperProps={{ style: paperStyles, role: "dialog" }}
      >
        <MoreMenuWrapper>
          {editorWidth && editorWidth < toolbarSizes.xxs && (
            <>
              <FontSelector editor={editor} value={fontFamily} />
              <Divider orientation="vertical" flexItem />
            </>
          )}
          {editorWidth && editorWidth < toolbarSizes.xs && (
            <>
              <FontSize
                selectionFontSize={fontSize.slice(0, -2)}
                editor={editor}
                disabled={false}
              />
              <Divider orientation="vertical" flexItem />
            </>
          )}

          {editorWidth && editorWidth < toolbarSizes.sm && (
            <>
              <EditorToolbarButton
                onClick={() => {
                  editor.dispatchCommand(FORMAT_TEXT_COMMAND, "bold");
                }}
                label={t("textEditor.plugins.toolbar.textFormat.bold")}
                icon={<BoldIcon />}
                active={isBold}
              />
              <EditorToolbarButton
                onClick={() => {
                  editor.dispatchCommand(FORMAT_TEXT_COMMAND, "italic");
                }}
                label={t("textEditor.plugins.toolbar.textFormat.italic")}
                icon={<ItalicIcon />}
                active={isItalic}
              />
              <EditorToolbarButton
                onClick={() => {
                  editor.dispatchCommand(FORMAT_TEXT_COMMAND, "underline");
                }}
                label={t("textEditor.plugins.toolbar.textFormat.underline")}
                icon={<UnderlineIcon />}
                active={isUnderline}
              />
              <EditorToolbarButton
                onClick={() => {
                  editor.dispatchCommand(FORMAT_TEXT_COMMAND, "strikethrough");
                }}
                label={t("textEditor.plugins.toolbar.textFormat.strikethrough")}
                icon={<StrikethroughIcon />}
                active={isStrikethrough}
              />
              <EditorToolbarButton
                onClick={() => {
                  formatBulletList(editor, blockType);
                }}
                label={t("textEditor.plugins.toolbar.blockType.bullet")}
                icon={<OrderList />}
                active={blockType === "bullet"}
              />
              <EditorToolbarButton
                onClick={() => {
                  formatNumberedList(editor, blockType);
                }}
                label={t("textEditor.plugins.toolbar.blockType.number")}
                icon={<Order123 />}
                active={blockType === "number"}
              />
            </>
          )}
          {editorWidth && editorWidth < toolbarSizes.lg && (
            <>
              <EditorToolbarButton
                onClick={() => {
                  // TODO: add modal
                  editor.dispatchCommand(INSERT_TABLE_COMMAND, {
                    columns: String(3),
                    rows: String(2),
                    includeHeaders: true,
                  });
                }}
                label={t("textEditor.plugins.toolbar.insertTable")}
                icon={<Table />}
              />
              <ColorPicker
                color={fontColor}
                onChange={updateFontColor}
                ToggleButton={EditorToolbarButton}
                toggleButtonProps={{
                  icon: <FontColor />,
                  label: t("textEditor.plugins.toolbar.fontColor"),
                }}
              />
              <ColorPicker
                color={backgroundColor}
                onChange={updateBackgroundColor}
                ToggleButton={EditorToolbarButton}
                toggleButtonProps={{
                  icon: <BgColor />,
                  label: t("textEditor.plugins.toolbar.backgroundColor"),
                }}
              />
              <EditorToolbarButton
                onClick={() => {
                  editor.dispatchCommand(INSERT_PAGE_BREAK, undefined);
                }}
                label={t("textEditor.plugins.toolbar.insertPageBreak")}
                icon={<PageBreaker />}
              />
              <Divider orientation="vertical" flexItem />
            </>
          )}
          {editorWidth && editorWidth < toolbarSizes.xl && (
            <>
              <EditorToolbarButton
                onClick={() => {
                  editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "left");
                }}
                label={t("textEditor.plugins.toolbar.textAlign.left")}
                icon={<AlignLeftIcon />}
                active={elementFormat === "left"}
              />
              <EditorToolbarButton
                onClick={() => {
                  editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "center");
                }}
                label={t("textEditor.plugins.toolbar.textAlign.center")}
                icon={<AlignCenterIcon />}
                active={elementFormat === "center"}
              />
              <EditorToolbarButton
                onClick={() => {
                  editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "right");
                }}
                label={t("textEditor.plugins.toolbar.textAlign.right")}
                icon={<AlignRightIcon />}
                active={elementFormat === "right"}
              />
              <EditorToolbarButton
                onClick={() => {
                  editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "justify");
                }}
                label={t("textEditor.plugins.toolbar.textAlign.justify")}
                icon={<AlignJustifyIcon />}
                active={elementFormat === "justify"}
              />
            </>
          )}
          {editorWidth && editorWidth < toolbarSizes.full && (
            <>
              <EditorToolbarButton
                onClick={() => {
                  setShowImageModal(true);
                }}
                label={t("textEditor.plugins.toolbar.insertImage")}
                icon={<ImageIcon />}
              />
              <Divider orientation="vertical" flexItem />
              <EditorToolbarButton
                onClick={() => {
                  clearFormatting();
                }}
                label={t("textEditor.plugins.toolbar.clearFormat")}
                icon={<ClearFormatting />}
              />
            </>
          )}
        </MoreMenuWrapper>
      </Popover>
      {showImageModal && (
        <InsertImageModal
          open={showImageModal}
          onSubmit={(payload) => {
            editor.dispatchCommand(INSERT_IMAGE_COMMAND, payload);
            setShowImageModal(false);
          }}
          onClose={() => {
            setShowImageModal(false);
          }}
        />
      )}
    </>
  );
}
