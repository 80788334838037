import styled from "@emotion/styled";
import { theme } from "theme";

const outlineOffset = "2px";
const outlineSize = "1px";
const borderSize = "1px";
const heightMap = {
  xs: "20px",
  sm: "32px",
  md: "44px",
};

export const TextInputWrapper = styled.div<{
  size: "xs" | "sm" | "md";
  isBorderLessInput?: boolean;
  error?: boolean;
  fullWidth?: boolean;
  suggestion?: boolean;
}>`
  width: ${({ fullWidth }) => (fullWidth ? "100%" : "fit-content")};

  &:hover input {
    color: ${theme.color.black};
    outline: ${outlineSize} solid ${theme.color.blue[600]};
  }

  input {
    width: 100%;
    border: ${borderSize} solid transparent;
    outline-offset: ${outlineOffset};
    outline: ${outlineSize} solid ${theme.color.gray[300]};
    color: ${theme.color.black};
    border-radius: ${theme.borderRadius};
    padding: 0 ${theme.spacing.md};
    transition: outline 0.125s ease-in-out;
    height: ${({ size }) => heightMap[size]};

    ${({ isBorderLessInput }) =>
      isBorderLessInput &&
      `
      outline: ${outlineSize} solid transparent;
    `}

    ${({ error }) =>
      error &&
      `
      outline: ${outlineSize} solid ${theme.color.red[600]};
      color: ${theme.color.red[600]};

      :hover,
      :focus {
        color: ${theme.color.black};
      }

      :active {
        outline: ${outlineSize} solid ${theme.color.red[600]};
      }
    `}

    :active {
      outline: ${outlineSize} solid ${theme.color.blue[900]};
    }

    :focus {
      outline: ${outlineSize} solid ${theme.color.blue[900]};
    }

    :disabled {
      outline: ${outlineSize} solid ${theme.color.gray[400]};
      outline-offset: 0;
      color: ${theme.color.gray[600]};
      background-color: ${theme.color.gray[300]};
      cursor: not-allowed;
    }
  }

  span {
    display: block;
    margin-left: calc(
      ${outlineOffset} + ${outlineSize} + ${borderSize} + ${theme.spacing.md}
    );
    margin-top: ${theme.spacing.sm};
    font-size: ${theme.font.size.caption};
    font-family: ${theme.font.family};
    color: ${theme.color.gray[600]};
    transition: background-color 0.125s ease-in-out, color 0.125s ease-in-out;
  }

  &:hover input:not(:disabled) ~ span {
    color: ${theme.color.blue[600]};
  }

  &:focus-within input:not(:disabled) ~ span {
    color: ${theme.color.blue[900]};
  }

  input:active:not(:disabled) ~ span {
    color: ${theme.color.blue[900]};
  }

  input:disabled ~ span {
    color: ${theme.color.gray[600]};
  }

  ${({ error }) =>
    error &&
    `
    span {
      color: ${theme.color.red[600]};
    }
  `}

  ${({ suggestion }) =>
    suggestion &&
    `
    input {
      color: ${theme.color.orange[600]};
    }
  `};
`;
