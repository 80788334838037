import { useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import { flushSync } from "react-dom";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import {
  GlobalActionType,
  GlobalContext,
  SetUserInfoActionPayload,
} from "../contexts/GlobalState";
import {
  AuthService,
  LoginDto,
  TeamDescriptionDto,
  TokenResponseDto,
  UserService,
} from "../openapi";
import i18n, { Language } from "../shared/i18n/i18n";
import routePaths from "constants/routePaths";
import { useTeam } from "contexts/team/hooks";
import { setInvalidTokenError } from "shared/service/errorResponseService";
import { initCountries } from "utils/internationalizations";
import { useQueryClient } from "@tanstack/react-query";

export function useAuthentication() {
  const { t } = useTranslation();
  const { state, dispatch } = useContext(GlobalContext);
  const { refreshUserInfo, clearUserInfo } = useUserInfo();
  const { setSelectedTeam } = useTeam();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const isAuthenticated = state.authenticated;
  const [searchParams] = useSearchParams();
  const queryClient = useQueryClient();

  const loginWithPassword = async (request: LoginDto) => {
    const tokenResponse = await AuthService.login(request);
    await login(tokenResponse);
  };

  const login = async (
    tokenResponse: TokenResponseDto,
    acceptInvitation = true
  ) => {
    flushSync(() => {
      dispatch({
        type: GlobalActionType.login,
        params: tokenResponse,
      });
    });

    if (acceptInvitation) {
      const invitationToken = searchParams.get("invitationToken") || undefined;
      if (invitationToken) {
        try {
          const { teamName } = await UserService.acceptInvitation(
            invitationToken
          );

          enqueueSnackbar(t("acceptInvitation.successMessage", { teamName }), {
            variant: "success",
          });
        } catch (e) {
          setInvalidTokenError(e, "acceptInvitation", enqueueSnackbar, t);
        }
      }
    }

    await refreshUserInfo();
  };

  const impersonate = async (id: string) => {
    await AuthService.impersonate(id);

    await flushSync(async () => {
      void queryClient.invalidateQueries({ queryKey: ["profile"] });
      const { data } = await refreshUserInfo();
      if (data?.teams && data.teams.length > 0) {
        navigate(routePaths.HOME);
      } else {
        navigate(routePaths.TEAM_CREATION);
      }
    });
  };

  const exitImpersonate = async () => {
    await logout();
  };

  const logout = async () => {
    await AuthService.logout();
    enqueueSnackbar(t("pages.login.messages.successfulLogout"), {
      variant: "success",
    });
    dispatch({
      type: GlobalActionType.logout,
    });
    clearUserInfo();
    await setSelectedTeam();
  };

  return {
    isAuthenticated,
    loginWithPassword,
    login,
    impersonate,
    exitImpersonate,
    logout,
  };
}

export function useUserInfo() {
  const { state, dispatch } = useContext(GlobalContext);
  const { changeLocale } = useLocale();

  const userInfo = state?.userInfo;
  const status = state?.status;
  const error = state?.error;
  const teams = state?.userInfo?.teams;
  const isTeamlessUser =
    !state.userInfo?.defaultTeamId &&
    !(state.userInfo?.teams && state.userInfo?.teams.length > 0);

  const teamMap = useMemo(() => {
    if (!teams) return new Map<string, never>();
    return new Map<string, TeamDescriptionDto>(
      teams
        .flatMap((team) => [team, ...team.children])
        .map((team) => [team.id, team])
    );
  }, [userInfo]);

  const teamById = (teamId?: string): TeamDescriptionDto | null => {
    return (teamId && teamMap.get(teamId)) || null;
  };

  const refreshUserInfo = async () => {
    const actionPayload: SetUserInfoActionPayload = {
      error: null,
    };
    try {
      const userInfoResponse = await UserService.getUserInfo();
      actionPayload.data = userInfoResponse;
    } catch (e) {
      if (e instanceof Error) {
        actionPayload.error = e;
      } else {
        console.error("error refreshing user data", e);
        actionPayload.error = new Error("error refreshing user data");
      }
    }

    dispatch({
      type: GlobalActionType.setUserInfo,
      params: actionPayload,
    });

    if (actionPayload.data) {
      await changeLocale(actionPayload.data.locale as Language);
    }

    return actionPayload;
  };

  const clearUserInfo = () => {
    const actionPayload: SetUserInfoActionPayload = {
      error: null,
      data: undefined,
    };
    dispatch({
      type: GlobalActionType.setUserInfo,
      params: actionPayload,
    });
  };

  return {
    userInfo,
    status,
    error,
    teams,
    teamById,
    isTeamlessUser,
    refreshUserInfo,
    clearUserInfo,
  };
}

export function useLocale() {
  const { state, dispatch } = useContext(GlobalContext);

  const locale = state.locale;
  const changeLocale = async (locale: Language) => {
    dispatch({
      type: GlobalActionType.changeLocale,
      params: locale,
    });
    await i18n.changeLanguage(locale);
    initCountries(locale);
  };

  return { locale, changeLocale };
}

export function useUploadStatus() {
  const { state, dispatch } = useContext(GlobalContext);

  const uploadStatus = state.uploadStatus;

  const setUploadStatus = (value: boolean, duration = 1500): void => {
    dispatch({
      type: GlobalActionType.setUploadStatus,
      params: value,
    });

    // RESET
    setTimeout(() => {
      dispatch({
        type: GlobalActionType.setUploadStatus,
        params: false,
      });
    }, duration);
  };

  return { uploadStatus, setUploadStatus };
}

// @TODO: Remove it later
export function useInvitation() {
  const { state, dispatch } = useContext(GlobalContext);

  const invitationToken = state.invitationToken;
  const setInvitationToken = (invitationToken: string) => {
    dispatch({
      type: GlobalActionType.setInvitationToken,
      params: invitationToken,
    });
  };
  const clearInvitationToken = () => {
    dispatch({
      type: GlobalActionType.setInvitationToken,
      params: undefined,
    });
  };

  return { invitationToken, setInvitationToken, clearInvitationToken };
}

export function useGuideModal() {
  const { state, dispatch } = useContext(GlobalContext);

  const showGuideModal = state.showGuideModal;
  const setShowGuideModal = (showGuideModal: boolean) => {
    dispatch({
      type: GlobalActionType.setShowGuideModal,
      params: showGuideModal,
    });
  };

  return { showGuideModal, setShowGuideModal };
}
