import styled from "@emotion/styled";
import { ListItemButton as MUIListItemButton } from "@mui/material";
import { theme } from "theme";

export const ListItemButtonWrapper = styled.li`
  display: flex;
  align-items: center;
`;

export const ListItemButton = styled(MUIListItemButton)<{
  active?: boolean;
  main?: boolean;
}>`
  margin: ${theme.spacing.xxs} auto;
  border-radius: ${theme.borderRadius};

  ${({ main }) => !main && `margin-left: ${theme.spacing.xl};`}

  ${({ active }) =>
    !active &&
    `
      color: ${theme.color.blue[600]};
      background-color: ${theme.color.gray[300]}80;

      span {
        color: ${theme.color.blue[600]};
      }
    `};
`;
