import styled from "@emotion/styled";
import { theme } from "theme";
import { Alert, ListItemText, TextField } from "@mui/material";

export const ModalBody = styled.div``;

export const ModalBodyUpload = styled.div`
  border: 1px dashed ${theme.color.blue[600]};
  border-radius: ${theme.borderRadius};}
  padding: ${theme.spacing.lg};

  p:nth-child(2) {
    color: ${theme.color.gray[600]};
  }
`;

export const Content = styled.div<{ highlighted?: boolean }>`
  height: 104px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: ${theme.spacing.xl};
  justify-content: center;
  gap: ${theme.spacing.md};
  cursor: default;
  transition: background-color 0.15s ease-in-out;

  ${({ highlighted }) =>
    !highlighted &&
    `
    height: 160px;
    cursor: pointer;
    flex-direction: row;
    align-items: center;

    :hover {
      background-color: ${theme.color.blue[200]}80;
    }
  `}

  svg {
    fill: ${theme.color.black};
  }
`;

export const ContentBlock = styled.div`
  display: flex,
  alignItems: center,
  gap: theme.spacing.lg,
`;

export const Block = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing.md};
`;

export const Paragraph = styled.p<{ bold?: boolean }>`
  ${({ bold }) =>
    bold &&
    `
    font-weight: ${theme.font.weight.semiBold};
  `}

  margin: 0;
  text-align: center;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ListHeader = styled.button`
  margin-bottom: ${theme.spacing.sm};
  font-weight: ${theme.font.weight.semiBold};
`;

export const ErrorAlert = styled(Alert)`
  margin-bottom: ${theme.spacing.lg};
  background-color: var(--color-warning);
`;

export const EditableTextField = styled(TextField)`
  width: calc(100% - 40px);
  height: 32px;
  margin-right: ${theme.spacing.sm};

  input {
    padding: ${theme.spacing.xs} ${theme.spacing.sm};
    height: 100%;
  }
`;

export const HoverableListItemText = styled(ListItemText)`
  margin-right: ${theme.spacing.sm};
  width: calc(100% - 40px);
  height: 32px;
  display: flex;
  align-items: center;

  &:hover {
    outline: 1px solid ${theme.color.blue[600]};
    border-radius: var(--button-border-radius);
    padding: ${theme.spacing.xs} ${theme.spacing.sm};
    box-sizing: border-box;
  }
`;
