/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  ContractTemplateCreateV1DTO,
  ContractTemplateService,
  ContractTemplateUpdateV1DTO,
} from "openapi";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { QueryTypeFilter } from "@tanstack/query-core/src/utils";

export const useContractTemplatesQueryV1 = (organizationId: string) => {
  return useQuery({
    queryKey: ["contract-templates", organizationId],
    queryFn: () => ContractTemplateService.getAll(organizationId),
    enabled: !!organizationId,
  });
};

export const useContractTemplateQueryV1 = ({
  organizationId,
  templateId,
}: {
  organizationId: string;
  templateId?: string;
}) => {
  return useQuery({
    queryKey: ["contract-templates", organizationId, templateId],
    queryFn: () => ContractTemplateService.getById(templateId!, organizationId),
    enabled: !!templateId && templateId !== "new",
    refetchOnWindowFocus: false,
  });
};

export const useContractTemplateCreateMutationV1 = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: {
      organizationId: string;
      template: ContractTemplateCreateV1DTO;
    }) => {
      return ContractTemplateService.create(data.organizationId, data.template);
    },
    onSuccess: (_, variables) => {
      void queryClient.invalidateQueries({
        queryKey: ["contract-templates", variables.organizationId],
      });
    },
  });
};

export const useContractTemplateUpdateMutationV1 = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: {
      organizationId: string;
      templateId: string;
      template: ContractTemplateUpdateV1DTO;
      refetchType?: "none" | QueryTypeFilter;
    }) => {
      return ContractTemplateService.update(
        data.templateId,
        data.organizationId,
        data.template
      );
    },
    onSuccess: (_, variables) => {
      void queryClient.invalidateQueries({
        queryKey: ["contract-templates", variables.organizationId],
        refetchType: variables.refetchType || "inactive",
      });
      void queryClient.removeQueries({
        queryKey: [
          "contract-templates",
          variables.organizationId,
          variables.templateId,
        ],
      });
    },
  });
};

export const useContractTemplateDeleteMutationV1 = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: { organizationId: string; templateId: string }) => {
      return ContractTemplateService.delete(
        data.templateId,
        data.organizationId
      );
    },
    onSuccess: (_, variables) => {
      void queryClient.removeQueries({
        queryKey: [
          "contract-templates",
          variables.organizationId,
          variables.templateId,
        ],
      });
      void queryClient.invalidateQueries({
        queryKey: ["contract-templates", variables.organizationId],
      });
    },
  });
};

export const useGetAllContractTemplateTagsQuery = (organizationId: string) => {
  return useQuery({
    queryKey: ["contract-templates"],
    queryFn: () => ContractTemplateService.getAllTags(organizationId),
  });
};

export const useCreateContractTemplateTagMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: { organizationId: string; tag: { name: string } }) => {
      return ContractTemplateService.createTag(data.organizationId, {
        name: data.tag.name,
      });
    },
    onSuccess: (_, variables) => {
      void queryClient.invalidateQueries({
        queryKey: ["contract-templates"],
      });
    },
  });
};

export const useUpdateContractTemplateTagMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: {
      organizationId: string;
      tagId: string;
      name: string;
    }) => {
      return ContractTemplateService.updateTag(
        data.tagId,
        data.organizationId,
        { name: data.name }
      );
    },
    onSuccess: (_, variables) => {
      void queryClient.invalidateQueries({
        queryKey: ["contract-templates"],
      });
    },
  });
};

export const useDeleteContractTemplateTagMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: { organizationId: string; tagId: string }) => {
      return ContractTemplateService.deleteTag(data.tagId, data.organizationId);
    },
    onSuccess: (_, variables) => {
      void queryClient.invalidateQueries({
        queryKey: ["contract-templates"],
      });
    },
  });
};
