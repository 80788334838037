import styled from "@emotion/styled";
import { keyframes } from "@emotion/react";
import { theme } from "theme";
import { breakpoints } from "constants/breakpoints";

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const Wrapper = styled.div`
  opacity: 0;
  animation-name: ${fadeIn};
  animation-duration: 1s;
  animation-fill-mode: forwards;
`;

export const Columns = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: calc(var(--spacing-lg) * -1);

  @media screen and (min-width: ${breakpoints.md}px) {
    flex-direction: row;
    position: relative;
  }
`;

export const Column = styled.div<{ isSticky?: boolean }>`
  width: 100%;

  @media screen and (min-width: ${breakpoints.md}px) {
    width: 50%;
  }

  :nth-of-type(1) {
    margin-right: var(--spacing-lg);
    position: relative;
  }

  :nth-of-type(2) {
    position: sticky;
    top: 0;
    align-self: flex-start;
    height: calc(100vh - 10rem);
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${theme.spacing.sm};
  color: ${theme.color.gray[700]};
`;

export const Title = styled.h1`
  margin: 0;
  font-size: ${theme.font.size.h6};
  font-weight: ${theme.font.weight.semiBold};
  line-height: 2.25rem;
  word-break: break-word;
  display: flex;
`;
