import React, {
  createContext,
  Dispatch,
  useContext,
  useEffect,
  useReducer,
} from "react";
import { NavigateFunction, useNavigate, useParams } from "react-router-dom";
import { teamReducer } from "./reducer";
import { TeamAction, TeamActionType, TeamState } from "./types";
import { UserInfoDto } from "openapi";
import { useUserInfo } from "hooks/GlobalStateHooks";
import { getSelectedTeamPayload } from "./actions";
import { routePaths } from "../../constants";
import { CanAccessTeam } from "shared/service/authService";

const initialState: TeamState = {
  permissions: undefined,
  drawerOpenState: false,
  status: "idle",
  error: null,
};

// CONTEXT
export const TeamContext = createContext<{
  state: TeamState;
  dispatch: Dispatch<TeamAction>;
}>({
  state: initialState,
  dispatch: () => {
    return;
  },
});

async function onInit(
  dispatch: Dispatch<TeamAction>,
  userInfo: UserInfoDto,
  organizationId: string | undefined,
  navigate: NavigateFunction
) {
  const accessibleTeams = [];
  for (const team of userInfo.teams) {
    if (!userInfo.admin && !CanAccessTeam(userInfo, team)) {
      continue;
    }
    accessibleTeams.push(team.id);
    for (const subteam of team.children) {
      accessibleTeams.push(subteam.id);
    }
  }
  const defaultTeam = accessibleTeams.find(
    (team) => team === userInfo.defaultTeamId
  );
  const teamId = organizationId || defaultTeam || accessibleTeams?.[0];
  if (!teamId) {
    if (userInfo.teams.length > 0) {
      navigate(routePaths.FORBIDDEN);
    }
    return;
  }

  const payload = await getSelectedTeamPayload(teamId, userInfo);

  dispatch({
    type: TeamActionType.setSelectedTeam,
    params: payload,
  });
}

type Props = {
  children: JSX.Element | JSX.Element[];
};

// PROVIDER
export const TeamProvider = ({ children }: Props) => {
  const [state, dispatch] = useReducer(teamReducer, initialState);
  const { userInfo } = useUserInfo();
  const navigate = useNavigate();
  const { organizationId } = useParams();

  useEffect(() => {
    if (!userInfo) {
      return;
    }

    if (!userInfo.defaultTeamId && userInfo.teams.length === 0) {
      navigate(routePaths.TEAM_CREATION);
      return;
    }

    void onInit(dispatch, userInfo, organizationId, navigate);
  }, [userInfo, organizationId]);

  const stateRepresentsCurrentOrganization =
    !organizationId || organizationId === state.selectedTeam?.id;

  const shouldRender =
    state.status === "success" && stateRepresentsCurrentOrganization;

  return (
    <TeamContext.Provider value={{ state, dispatch }}>
      {shouldRender ? children : null}
    </TeamContext.Provider>
  );
};

export const useTeamContext = () => useContext(TeamContext);
