import styled from "@emotion/styled";
import { ContentEditable as LexicalContentEditable } from "@lexical/react/LexicalContentEditable";
import { breakpoints } from "theme";

export const ContentEditable = styled(LexicalContentEditable)`
  min-height: 80vh;
  resize: none;
  caret-color: rgb(5, 5, 5);
  position: relative;
  tab-size: 1;
  outline: 0;
  padding: 1in;
  caret-color: #444;

  @media (max-width: ${breakpoints.xl}) {
    padding: var(--spacing-xxl);
  }
`;
