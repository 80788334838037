/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { useEffect, useState } from "react";
import { ContractSignatureV1Service } from "openapi";
import { useSearchParams } from "react-router-dom";
import HelloSign from "hellosign-embedded";
import CircularLoading from "components/CircularLoading/CircularLoading";
import globalConfig from "shared/config/global.config";
import { isDevelopment } from "utils/helpers";

const Signature = () => {
  const [searchParams] = useSearchParams();
  const signatureId = searchParams.get("id");
  const [spinner, setSpinner] = useState<boolean>(true);
  const domain = navigator.language.startsWith("en")
    ? "en.contracthero.com"
    : "www.contracthero.com";
  const getSigningUrl = async () => {
    if (!signatureId) return;
    try {
      const response = await ContractSignatureV1Service.getSigningPage(
        signatureId
      );

      if (globalConfig.REACT_APP_DROPBOX_CLIENT_ID && response.signURL) {
        const client = new HelloSign();

        client.open(response.signURL, {
          clientId: globalConfig.REACT_APP_DROPBOX_CLIENT_ID,
          skipDomainVerification: isDevelopment(),
          allowCancel: false,
          container: document.getElementById("signing") || undefined,
        });

        setSpinner(false);
        client.on(
          "decline",
          () => (window.location.href = `https://${domain}/signatur/abgelehnt`)
        );
        client.on(
          "sign",
          () =>
            (window.location.href = `https://${domain}/signatur/erfolgreich`)
        );
      }
    } catch (e) {
      window.location.href = `https://${domain}/signatur/nicht-gefunden`;
    }
  };

  useEffect(() => {
    getSigningUrl().catch((e) => console.error(e));
  }, []);

  return (
    <div id="signing" style={{ width: "100vw", height: "99vh" }}>
      {spinner && <CircularLoading isLoading />}
    </div>
  );
};

export default Signature;
