/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type ProfileDto = {
  locale: ProfileDto.locale;
  email: string;
  firstname: string | null;
  lastname: string | null;
  phone: string | null;
  dateFormat?: string | null;
  newEmail?: string | null;
};

export namespace ProfileDto {
  export enum locale {
    EN = "en",
    DE = "de",
  }
}
