/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { OverviewCategoriesDTO } from "../models/OverviewCategoriesDTO";
import type { OverviewFinancialsDTO } from "../models/OverviewFinancialsDTO";
import type { OverviewRecentlyAddedDTO } from "../models/OverviewRecentlyAddedDTO";
import type { OverviewRecentNotificationsDTO } from "../models/OverviewRecentNotificationsDTO";
import type { OverviewSignatureDTO } from "../models/OverviewSignatureDTO";
import type { OverviewStatisticsDTO } from "../models/OverviewStatisticsDTO";

import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";

export class OverviewService {
  /**
   * Get recent contract data for dashboard
   * @param teamId
   * @returns OverviewRecentlyAddedDTO
   * @throws ApiError
   */
  public static getRecentlyAddedContractsOverview(
    teamId: string
  ): CancelablePromise<OverviewRecentlyAddedDTO> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/overview/recent-contracts",
      query: {
        teamId: teamId,
      },
    });
  }

  /**
   * Get recent notification data for dashboard
   * @param teamId
   * @returns OverviewRecentNotificationsDTO
   * @throws ApiError
   */
  public static getRecentNotificationsOverview(
    teamId: string
  ): CancelablePromise<OverviewRecentNotificationsDTO> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/overview/recent-notifications",
      query: {
        teamId: teamId,
      },
    });
  }

  /**
   * Get categories data for dashboard
   * @param teamId
   * @returns OverviewCategoriesDTO
   * @throws ApiError
   */
  public static getCategoriesOverview(
    teamId: string
  ): CancelablePromise<OverviewCategoriesDTO> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/overview/categories",
      query: {
        teamId: teamId,
      },
    });
  }

  /**
   * Get signatures data for dashboard
   * @param teamId
   * @returns OverviewSignatureDTO
   * @throws ApiError
   */
  public static getSignatureOverview(
    teamId: string
  ): CancelablePromise<OverviewSignatureDTO> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/overview/signatures",
      query: {
        teamId: teamId,
      },
    });
  }

  /**
   * Get statistics data for dashboard
   * @param teamId
   * @returns OverviewStatisticsDTO
   * @throws ApiError
   */
  public static getStatisticsOverview(
    teamId: string
  ): CancelablePromise<OverviewStatisticsDTO> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/overview/statistics",
      query: {
        teamId: teamId,
      },
    });
  }

  /**
   * Get financial data for dashboard
   * @param teamId
   * @returns OverviewFinancialsDTO
   * @throws ApiError
   */
  public static getFinancialsOverview(
    teamId: string
  ): CancelablePromise<OverviewFinancialsDTO> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/overview/financials",
      query: {
        teamId: teamId,
      },
    });
  }
}
