/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ProfileDto } from "../models/ProfileDto";

import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";

export class ProfileService {
  /**
   * Get profile
   * @returns ProfileDto
   * @throws ApiError
   */
  public static getProfile(): CancelablePromise<ProfileDto> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/profile",
    });
  }

  /**
   * Update profile
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public static updateProfile(requestBody: ProfileDto): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "PUT",
      url: "/profile",
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * Verify new email
   * @param emailVerificationToken
   * @returns any
   * @throws ApiError
   */
  public static verifyEmail(
    emailVerificationToken: string
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: "PATCH",
      url: "/profile/verify-email",
      query: {
        emailVerificationToken: emailVerificationToken,
      },
    });
  }
}
