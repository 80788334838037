/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable prefer-rest-params */
/* eslint-disable @typescript-eslint/unbound-method */
import React from "react";
import ReactDOM from "react-dom/client";
import { CssBaseline, ThemeProvider } from "@mui/material";
import * as Sentry from "@sentry/react";
import App from "./App";
import { setYupLanguage } from "./shared/config/yup.config";
import { LicenseManager } from "ag-grid-enterprise";
import "./shared/i18n/i18n";
import "./shared/config/openapi.config";
import "navigator.locks";
import "./theme.css";
import "./index.css";
import globalConfig from "./shared/config/global.config";
import { muiTheme } from "theme/muiTheme";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

Sentry.init({
  dsn: globalConfig.REACT_APP_SENTRY_DSN,
  environment: globalConfig.REACT_APP_ENV,
  release: import.meta.env.VITE_COMMIT_ID as string,
  denyUrls: [/www\.clarity\.ms/i, /^chrome-extension:\/\//i],
});

LicenseManager.setLicenseKey(
  "Using_this_{AG_Grid}_Enterprise_key_{AG-066058}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{ContractHero_GmbH}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{ContractHero_GmbH}_only_for_{2}_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_working_on_{ContractHero_GmbH}_need_to_be_licensed___{ContractHero_GmbH}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{28_October_2025}____[v3]_[01]_MTc2MTYwOTYwMDAwMA==b65dd4561b19a8cd26637a7214c7f022"
);

setYupLanguage();

const nonce = (
  document.querySelector('meta[property="csp-nonce"]') as HTMLMetaElement
).content as unknown as string;

const cache = createCache({
  key: "css",
  nonce: nonce,
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

// we have some issues in the frontend that cause the app to fail when DOM manipulation occurs through example Google Translate.
// To track the issues better, we want to log them to Sentry and also make sure application itself doesn't crash by it.
// This SHOULD be removed in the future, as soon as we fixed all of the smaller issues that lead to the issue at hand.
// To make this more apparent this isn't and shouldn't be moved to a function but should be directly visible as something that we want to get rid of in the future
// as it might hide real issues related to the application that will just get logged to Sentry instead of actually handled by the functions themselves.

// source: https://github.com/facebook/react/issues/11538

if (typeof Node === "function" && Node.prototype) {
  const originalRemoveChild = Node.prototype.removeChild;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  Node.prototype.removeChild = function (child) {
    if (child.parentNode !== this) {
      if (console) {
        console.error("Cannot remove a child from a different parent");
      }
      if (Sentry) {
        Sentry.captureMessage(`Cannot remove a child from a different parent`);
      }
      return child;
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return originalRemoveChild.apply(this, arguments);
  };

  const originalInsertBefore = Node.prototype.insertBefore;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  Node.prototype.insertBefore = function (newNode, referenceNode) {
    if (referenceNode && referenceNode.parentNode !== this) {
      if (console) {
        console.error(
          "Cannot insert before a reference node from a different parent"
        );
      }
      if (Sentry) {
        Sentry.captureMessage(
          `Cannot insert before a reference node from a different parent`
        );
      }
      return newNode;
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return originalInsertBefore.apply(this, arguments);
  };
}

root.render(
  <React.StrictMode>
    <CacheProvider value={cache}>
      <ThemeProvider theme={muiTheme}>
        <CssBaseline />
        <App />
      </ThemeProvider>
    </CacheProvider>
  </React.StrictMode>
);
