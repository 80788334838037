import ContactsIcon from "@mui/icons-material/Contacts";
import WarningIcon from "@mui/icons-material/Warning";
import { Grid } from "@mui/material";
import { AlertV2, FormTextField, UnsavedChangesModal } from "components";
import CardWrapper from "components/CardWrapper/CardWrapper";
import CircularLoading from "components/CircularLoading/CircularLoading";
import { useTeam } from "contexts/team/hooks";
import { enqueueSnackbar } from "notistack";
import {
  ContactCreateDTO,
  ContactDTO,
  ContactDatapointDefinitionDTO,
  ContactTypeDTO,
  ContactUpdateDTO,
  TextDatapointDTO,
} from "openapi";
import React, { useEffect, useState } from "react";
import { FormProvider, UseFormSetError, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { unstable_useBlocker, useNavigate } from "react-router-dom";
import {
  useContactQuery,
  useCreateContactMutation,
  useUpdateContactMutation,
} from "shared/api/contacts";
import { setValidationErrors } from "shared/service/errorResponseService";
import { DeleteModal, MergeContactsModal, Section } from "../";
import {
  ContactDataDTO,
  ContactDatapointDTOs,
} from "../../../Contacts/ContactDataDTO";
import { getChangedValues } from "../../../Contract/components/ContractData/helpers";
import { initFormFields } from "../../helpers";
import {
  ButtonsContainer,
  Divider,
  FormWrapper,
  SaveButton,
  SmallButton,
  SmallButtonOutline,
  StickyCard,
  Title,
  TitleWrapper,
} from "./styles";
import { AlertTypeEnum } from "shared/enums/alert.enum";

type Props = {
  contactId: string;
  contactTypes: ContactTypeDTO[];
  contactDefinitions: ContactDatapointDefinitionDTO[];
  isEditable: boolean;
  setIsEditable: (value: boolean) => void;
  showMergeModal?: boolean;
  setShowMergeModal?: (value: boolean) => void;
  onCreateRedirect?: boolean;
  handleModalClose?: () => void;
  getCreatedContactId?: (contactId: string) => Promise<void> | void;
  onSaveCallback?: (value: boolean) => void;
  isCardNested?: boolean;
  hideTitle?: boolean;
  displayFormMessage?: boolean;
  displayFormButtons?: boolean;
  hasProceedCreate?: boolean;
  preselectedName?: string | number | null;
  isInModal?: boolean;
  externalCreateContact?: (data: {
    organizationId: string;
    requestBody: ContactCreateDTO;
  }) => Promise<ContactDTO>;
};

export const ContactData = ({
  showMergeModal,
  setShowMergeModal,
  isEditable,
  setIsEditable,
  contactId = "draft",
  contactTypes,
  contactDefinitions,
  onCreateRedirect = true,
  handleModalClose,
  getCreatedContactId,
  onSaveCallback,
  isCardNested,
  hideTitle,
  displayFormMessage,
  displayFormButtons,
  hasProceedCreate = false,
  preselectedName,
  externalCreateContact,
}: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { parentTeamId: organizationId } = useTeam();
  const [contactType, setContactType] = useState<ContactTypeDTO>();
  const [isFormInitialized, setFormInitialized] = useState(false);
  const { selectedTeamId } = useTeam();

  const {
    mutateAsync: createContact,
    isSuccess: createContractSuccess,
    reset: resetCreate,
  } = useCreateContactMutation(externalCreateContact);
  const {
    mutateAsync: updateContact,
    isSuccess: updateContractSucess,
    reset: resetUpdate,
  } = useUpdateContactMutation();
  const {
    data: contactData,
    refetch: refetchContactData,
    isLoading,
  } = useContactQuery(contactId, organizationId, selectedTeamId, contactTypes);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  useEffect(() => {
    resetCreate();
    resetUpdate();
  }, [isEditable]);

  const methods = useForm({
    defaultValues: { ...contactData },
  });
  const {
    handleSubmit,
    formState: { dirtyFields },
  } = methods;

  useEffect(() => {
    if (!preselectedName && contactId !== "draft") return;
    const name = preselectedName !== undefined ? preselectedName : null;
    methods.setValue("name.value.value", name, {
      shouldDirty: true,
    });
  }, [preselectedName, isFormInitialized]);

  const isBlocked =
    Object.keys(dirtyFields).length > 0 &&
    !createContractSuccess &&
    !updateContractSucess;
  const blocker = unstable_useBlocker(isBlocked);

  const onCreate = async (isCopyContact?: boolean) => {
    let createContactData: ContactCreateDTO;

    if (isCopyContact) {
      const copiedFields = methods.getValues();

      for (const field in copiedFields) {
        if (!methods.control._fields[field]) {
          delete copiedFields[field];
          continue;
        }
        if (!(copiedFields[field] as ContactDatapointDTOs)?.value.value) {
          delete copiedFields[field];
        }
      }
      createContactData = {
        typeId: contactTypes[0].id,
        ...copiedFields,
      };
    } else {
      createContactData = {
        typeId: contactTypes[0].id,
        ...getChangedValues(methods.formState.dirtyFields, methods.getValues()),
      };
    }

    const response = await createContact({
      organizationId,
      requestBody: createContactData,
    });

    if (getCreatedContactId) await getCreatedContactId(response.id);
    if (onCreateRedirect) {
      handleFormInitialization(response);
      // @Todo tech debt. Find a better workaround in future. After improvemnt pls check:
      // - Contact data is saved on clicking "Save" button (no "empty" contact created)
      // - UnsavedChangesModal is not opened when it is not needed (ex: on clickng blue "Save" button)
      setTimeout(() => {
        navigate(`/contacts/${response?.id}`);
      }, 10);
    } else {
      handleModalClose?.();
    }

    enqueueSnackbar(t("pages.contacts.create.successMessage"), {
      variant: "success",
    });
  };

  const onUpdate = async () => {
    const updateContactData = {
      ...getChangedValues(methods.formState.dirtyFields, methods.getValues()),
    } as ContactUpdateDTO;

    await updateContact({
      organizationId,
      contactId,
      requestBody: updateContactData,
    });

    enqueueSnackbar(t("pages.contacts.update.successMessage"), {
      variant: "success",
    });
  };

  const onDiscard = () => {
    blocker.proceed?.();
    methods.reset(contactData as ContactDataDTO);
  };

  const onClose = (shouldResetBlocker?: boolean) => {
    if (shouldResetBlocker) {
      blocker.reset?.();
    }
    handleModalClose?.();
  };

  const onSave = async (isCopyContact?: boolean) => {
    if (!contactId || !contactTypes.length || !contactTypes[0]?.id) return;

    const companyName = methods.getValues("name.value.value");

    if (!companyName) {
      methods.setError("name.value.value", {
        message: "common.validation.required",
      });
      return;
    }

    try {
      if (contactId === "draft" || isCopyContact) {
        await onCreate(isCopyContact);
      } else {
        await onUpdate();
      }

      if (!isCopyContact) {
        await refetchContactData();
      }

      if (blocker?.state === "blocked") {
        blocker.proceed();
      } else {
        setIsEditable(false);
      }

      if (onSaveCallback) onSaveCallback(false);
    } catch (error) {
      const errorConverter: UseFormSetError<never> = (name, error) => {
        methods.setError(name, error);
      };
      const messageKey = contactId === "draft" ? "create" : "update";
      const customErrorMessage = `pages.contacts.${messageKey}.errorMessage`;
      setValidationErrors(
        error,
        errorConverter,
        "common",
        undefined,
        enqueueSnackbar,
        t,
        customErrorMessage
      );
      console.error("cannot set error to form", error);
    }
  };

  useEffect(() => {
    setContactType(contactTypes[0]);
  }, [contactTypes]);

  const setInitialForm = (
    contactData: ContactDataDTO,
    defaultData: ContactDataDTO
  ) => ({
    ...defaultData,
    ...contactData,
  });

  const handleFormInitialization = (contactData: ContactDataDTO) => {
    if (!contactDefinitions || !contactType) return;

    const initializedFormFields = initFormFields(
      contactDefinitions,
      contactType
    );
    const formValues = setInitialForm(
      contactData,
      initializedFormFields as ContactDataDTO
    );

    methods.reset(formValues);
    setFormInitialized(true);
  };

  useEffect(() => {
    if (contactData) {
      handleFormInitialization(contactData);
    }
  }, [contactDefinitions, contactType, contactData]);

  useEffect(() => void refetchContactData(), [contactId]);

  const selectedName =
    (methods.control?._formValues?.name as TextDatapointDTO)?.value?.value ||
    "-";
  const contactTitle = selectedName || t("pages.contact.draft");

  if (isLoading) {
    return <CircularLoading isLoading />;
  }

  return (
    <FormWrapper>
      <FormProvider {...methods}>
        <form
          onSubmit={(e) => {
            e.stopPropagation();
            return handleSubmit(() => onSave())(e);
          }}
        >
          {!hideTitle ? (
            <CardWrapper>
              <Grid container spacing={1.25}>
                <Grid item sm={12} md={12} lg={12}>
                  <TitleWrapper>
                    {isEditable ? (
                      <FormTextField
                        control={methods.control}
                        label={t("pages.contacts.fields.name")}
                        name="name.value.value"
                      />
                    ) : (
                      <>
                        <ContactsIcon fontSize="small" />
                        <Title>{contactTitle}</Title>
                      </>
                    )}
                  </TitleWrapper>
                  {!isEditable && <Divider />}
                </Grid>
              </Grid>
            </CardWrapper>
          ) : null}
          {contactType?.sections?.map((section) => {
            return (
              <Section
                key={section.id}
                editable={isEditable}
                section={section}
                definitions={contactDefinitions}
                values={contactData ?? ({} as ContactDataDTO)}
                isCardNested={isCardNested}
              />
            );
          })}

          {isEditable && Object.keys(dirtyFields).length > 0 && (
            <StickyCard>
              {displayFormMessage && (
                <AlertV2
                  message={t("common.components.alert.updateContact", {
                    numberOfContracts: contactData
                      ? contactData?.contractsNumber
                      : 0,
                  })}
                  icon={<WarningIcon fontSize="small" />}
                  type={AlertTypeEnum.warning}
                />
              )}
              {displayFormButtons ? (
                <ButtonsContainer>
                  <SmallButtonOutline
                    id="editContactBtn"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      return handleSubmit(() => onSave(true))(e);
                    }}
                  >
                    {t("common.buttons.createContactForContract")}
                  </SmallButtonOutline>
                  <SmallButton id="saveContactBtn" type="submit">
                    {t("common.buttons.updateContactForAllContracts")}
                  </SmallButton>
                </ButtonsContainer>
              ) : (
                <SaveButton type="submit" id="saveContactBtn">
                  {t("common.buttons.save")}
                </SaveButton>
              )}
            </StickyCard>
          )}
        </form>
      </FormProvider>

      <DeleteModal
        open={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        contactId={contactId}
      />

      <MergeContactsModal
        open={!!showMergeModal}
        onClose={() => {
          setShowMergeModal?.(false);
          void refetchContactData();
        }}
        contact={contactData as ContactDataDTO}
        contactType={contactType}
        contactDataPointDefinitions={contactDefinitions}
      />

      <UnsavedChangesModal
        title={t("pages.contacts.modal.unsavedChanges.title")}
        description={t("pages.contacts.modal.unsavedChanges.description")}
        showModal={!!blocker && blocker.state === "blocked"}
        handleSaveChanges={onSave}
        handleDiscardChanges={onDiscard}
        handleCloseModal={onClose}
      />
    </FormWrapper>
  );
};
