import styled from "@emotion/styled";
import { breakpoints } from "../../../constants/breakpoints";
import { theme } from "theme";

export const Wrapper = styled.nav`
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${theme.spacing.sm} ${theme.spacing.lg};
  border-bottom: 1px solid ${theme.color.gray[300]};

  @media (max-width: ${breakpoints.sm}px) {
    z-index: 1;
    width: 100%;
  }

  background-color: ${({
    impersonated,
  }: {
    impersonated: boolean | undefined;
  }) => (impersonated ? "lightskyblue" : `var(--color-white)`)};
`;

export const MobileWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${theme.spacing.sm} ${theme.spacing.xl};
`;

export const Container = styled.div`
  display: flex;
  align-items: center;

  & .MuiButton-root {
    color: ${theme.color.gray[700]};
    transition: background 0.2s ease-in-out;

    :hover {
      background-color: transparent;
    }
  }

  & .MuiButtonBase-root {
    margin: 0;
    padding: 0.5rem;
  }

  svg {
    width: 20px;
    height: 20px;
    color: ${theme.color.gray[700]};
  }

  .avatar-icon {
    margin-right: 0;
    background-color: transparent;
    transition: background 0.2s ease-in-out;

    :hover {
      background-color: rgba(0, 0, 0, 0.04);
    }
  }

  .user-name {
    color: ${theme.color.gray[700]};
    margin-left: 0.5rem;
    font-size: 0.8rem;
    text-transform: capitalize;
  }
`;
