import React, { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { useUserInfo } from "hooks";
import { Container, Loader } from "components";
import { Sidebar } from "../components";
import { Wrapper } from "./styles";

const CustomLayout = () => {
  const { status } = useUserInfo();
  const location = useLocation();

  useEffect(() => {
    const mainContent = document.getElementById("main-content");
    if (mainContent) {
      mainContent.scrollTop = 0;
    }
  }, [location.pathname]);

  if (status === "idle") return <Loader />;

  return (
    <Wrapper>
      <Sidebar />
      <Container fullWidth>
        <Outlet />
      </Container>
    </Wrapper>
  );
};

export default CustomLayout;
