import styled from "@emotion/styled";
import { CTAButton } from "components";
import { theme } from "theme";

export const Header = styled.div`
  padding: var(--spacing-xl) var(--spacing-md) 0 var(--spacing-xl);
`;

export const Top = styled.div`
  display: flex;
  margin-bottom: ${theme.spacing.md};
`;

export const Buttons = styled.div`
  display: flex;
  gap: ${theme.spacing.md};
`;

export const Button = styled(CTAButton)`
  flex: 0 0 auto;
`;

export const Bottom = styled.div`
  margin-bottom: ${theme.spacing.md};
`;

export const Title = styled.div`
  position: relative;
  margin-right: ${theme.spacing.sm};
  flex: 1 1 auto;
  span {
    position: relative;
    padding-right: ${theme.spacing.sm};
    font-size: ${theme.font.size.body};
    background: ${theme.color.white};
    z-index: 1;
  }

  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    height: 1px;
    width: 100%;
    background-color: ${theme.color.gray[300]};
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
