import React, { useEffect, useState } from "react";
import FlagIcon from "@mui/icons-material/Flag";
import { List } from "@mui/material";
import CardHeaderWrapperWithButton from "components/CardHeaderWrapperWithButton/CardHeaderWrapperWithButton";
import routePaths from "constants/routePaths";
import { useTeam } from "contexts/team/hooks";
import { TaskNewDTO } from "openapi";
import { useNavigate } from "react-router-dom";
import { StatisticsViewMode, WidgetProps } from "../Dashboard";
import { theme } from "theme";
import CardBodyWrapper from "../../../components/CardCenteredBodyWrapper/CardCenteredBodyWrapper";
import CardWrapper from "../../../components/CardWrapper/CardWrapper";
import { useStyles as useStyledBaseButtons } from "../../../components/StyledComponents/StyledBaseButtons";
import TaskItem, { ExtendedTaskDTO } from "./TaskItem";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import "overlayscrollbars/overlayscrollbars.css";
import { useContractsQuery, useTasksQuery } from "../../../shared/api";
import { getDueDateValue } from "../../Tasks/components/TasksTable/helpers";

const Tasks = ({ statisticsViewMode }: WidgetProps) => {
  const { selectedTeamId, organizationId } = useTeam();
  const { data } = useTasksQuery(selectedTeamId, undefined, organizationId);
  const { data: contracts } = useContractsQuery(selectedTeamId);
  const [tasks, setTasks] = useState<ExtendedTaskDTO[]>([]);

  useEffect(() => {
    if (!data || !contracts) {
      return;
    }
    const unclosedTasks = data.filter(
      (task) =>
        !!task.dueDateType &&
        task.status !== TaskNewDTO.status.CLOSED &&
        (statisticsViewMode === StatisticsViewMode.SHOW_SELECTED_ONLY
          ? task.teamId === selectedTeamId
          : true)
    );

    const mappedTasks = unclosedTasks.map((task) => {
      const contract = contracts.find((item) => item.id === task.contractId);

      const dueDate = contract && getDueDateValue(task, contract);
      return {
        ...task,
        contractName: contract?.name,
        dueDate: dueDate,
      } as ExtendedTaskDTO;
    });

    const filteredTasks = mappedTasks.filter((task) => !!task.dueDate);

    filteredTasks.sort(
      (a, b) =>
        new Date(a.dueDate || 0).getTime() - new Date(b.dueDate || 0).getTime()
    );

    setTasks(filteredTasks);
  }, [data, statisticsViewMode, contracts]);

  const buttonClasses = useStyledBaseButtons();
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate(routePaths.TASKS);
  };

  return (
    <CardWrapper isShowTopAndBottomPaddingOnly={data ? "true" : undefined}>
      <>
        <CardHeaderWrapperWithButton
          titleKey="pages.dashboard.tasks.title"
          icon={<FlagIcon />}
          buttonStyle={buttonClasses.whiteButtonWithBlueLabel}
          colorStyle={theme.color.gray[600]}
          baseButtonTextKey="common.buttons.showAll"
          onButtonClick={handleButtonClick}
        />
        {tasks != null && tasks.length > 0 ? (
          <OverlayScrollbarsComponent
            style={{
              overflowY: "auto",
              maxHeight: "280px",
            }}
          >
            <List>
              {tasks.map((item) => {
                return <TaskItem key={item.id} data={item} />;
              })}
            </List>
          </OverlayScrollbarsComponent>
        ) : (
          <CardBodyWrapper textKey="pages.dashboard.tasks.description" />
        )}
      </>
    </CardWrapper>
  );
};

export default Tasks;
