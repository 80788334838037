import { CTAButton, NewModal } from "components";
import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDropzone } from "react-dropzone";
import { InsertImagePayload } from "../../plugins/ImagePlugin";
import { Wrapper, ImageWrapper, TextWrapper, ImportIcon } from "./styles";

type InsertImageProps = {
  open: boolean;
  onSubmit: (payload: InsertImagePayload) => void;
  onClose: () => void;
};

export const InsertImageModal: FC<InsertImageProps> = ({
  open,
  onSubmit,
  onClose,
}) => {
  const { t } = useTranslation();
  const [image, setImage] = useState<string>();

  const onDrop = (acceptedFiles: File[]) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.result) {
        setImage(reader.result as string);
      }
    };

    reader.readAsDataURL(acceptedFiles[0]);
  };

  const reset = () => {
    if (image) {
      setImage(undefined);
    }
  };

  useEffect(() => {
    if (!open) {
      reset();
    }
  }, [open, image]);

  const dropzone = useDropzone({
    accept: { "image/*": [".png", ".gif", ".jpeg", ".jpg"] },
    onDrop: onDrop,
  });

  const onConfirm = () => {
    const reader = new FileReader();
    reader.onload = () => {
      if (typeof reader.result === "string") {
        onSubmit({
          src: reader.result,
          altText: "",
        });
      }
    };
    reader.readAsDataURL(dropzone.acceptedFiles[0]);
  };

  return (
    <NewModal
      open={open}
      handleClose={onClose}
      title={t("pages.settings.tabs.contractTemplates.imageModal.title")}
      body={
        <Wrapper {...dropzone.getRootProps({ image: !!image })}>
          <input {...dropzone.getInputProps()} data-cy="dropzone" />
          <ImageWrapper>
            {image ? (
              <img src={image} alt="preview" />
            ) : (
              <TextWrapper>
                <ImportIcon />
                <span>
                  {t(
                    "pages.settings.tabs.contractTemplates.imageModal.description"
                  )}
                </span>
              </TextWrapper>
            )}
          </ImageWrapper>
        </Wrapper>
      }
      footer={
        <>
          <CTAButton
            variant="secondary"
            onClick={onClose}
            label="cancel"
            name={t("common.buttons.cancel")}
          />
          <CTAButton
            variant="primary"
            onClick={onConfirm}
            label="addSectionButton"
            name={t("common.buttons.confirm")}
            disabled={!image}
          />
        </>
      }
    />
  );
};
