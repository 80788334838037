import {
  LimitedAuthMethodsDTO,
  LimitedIdentityProviderDTO,
  TeamDescriptionDto,
  UserInfoDto,
} from "openapi";

function CheckAccessByMethods(
  user: UserInfoDto,
  authMethods: LimitedAuthMethodsDTO
) {
  const method = authMethods[user.providerType as keyof LimitedAuthMethodsDTO];

  if (user.providerId) {
    return (
      (method as LimitedIdentityProviderDTO | null)?.id === user.providerId
    );
  }
  return !!method;
}

export function CanAccessTeam(
  userInfo: UserInfoDto | undefined,
  team: TeamDescriptionDto
) {
  if (!userInfo) {
    return false;
  }
  if (CheckAccessByMethods(userInfo, team.authMethods)) return true;
  if (team.delegationAuthMethods) {
    return CheckAccessByMethods(userInfo, team.delegationAuthMethods);
  }
  return false;
}
