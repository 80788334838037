import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { CTAButton, NewModal } from "components";
import { Tooltip } from "@mui/material";
import ContractSwapModalContent from "pages/Contract/Modals/SwapContract";
import { SwitchArrowsIcon } from "../styles";

type Props = {
  contractId?: string;
  refetchData?: (loadFromStash?: boolean | undefined) => Promise<void>;
};

const ChangeRelationType = ({ contractId, refetchData }: Props) => {
  const [showModal, setShowModal] = useState(false);
  const { t } = useTranslation();

  const handleModalOpen = () => setShowModal(true);
  const handleModalClose = () => setShowModal(false);

  return (
    <>
      <Tooltip placement="top" title={t("common.buttons.updateRelation")}>
        <CTAButton
          variant="tertiary"
          icon={<SwitchArrowsIcon />}
          iconSize="medium"
          onClick={handleModalOpen}
        />
      </Tooltip>

      <NewModal
        open={showModal}
        handleClose={handleModalClose}
        title={t("pages.contractDetails.modals.swapMainContract.title")}
        body={
          <ContractSwapModalContent
            handleClose={handleModalClose}
            selectedContractId={contractId}
            refetchData={refetchData}
          />
        }
      />
    </>
  );
};

export default ChangeRelationType;
