/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { OrganizationNewService } from "openapi";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export const useContractVerificationMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: {
      organizationId: string;
      teamId: string;
      contractId: string;
      /**
       * @param {boolean} [shouldNotInvalidate] - Optional - to control query invalidation.
       *        - When `verifyAllC` on VerifyAllBanner, set this to `true` to avoid fetching the
       *          contract list on every update.
       *        - Instead, refresh the query only once after the entire process is complete.
       */
      shouldNotInvalidate?: boolean;
    }) => {
      return OrganizationNewService.verifyContract(
        data.organizationId,
        data.teamId,
        data.contractId
      );
    },
    onSuccess: (_, variables) => {
      if (variables.shouldNotInvalidate) {
        return;
      }

      void queryClient.invalidateQueries({ queryKey: ["contracts"] });
      void queryClient.invalidateQueries({ queryKey: ["overview"] });
    },
  });
};
