import React, { useState, useEffect, useCallback } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import * as Sentry from "@sentry/react";
import CircularProgress from "@mui/material/CircularProgress";
import { LexicalEditor, EditorState } from "lexical";
import { motion, MotionStyle } from "framer-motion";
import isEqual from "lodash/isEqual";
import { ContractDTOV1, DocumentDTO, ContractTemplateService } from "openapi";
import { useContractDetails, viewModeType } from "pages/Contract/context";
import { getTemplateObj } from "pages/Contract/helpers";
import TextEditor from "new-components/TextEditor/TextEditor";
import PDFComponent from "../RightSide/PDFComponent/PDFComponent";
import ImportDocuments from "./ImportDocument";
import { motionProps } from "../RightSide/RightSide";
import { AcceptedFileType } from "shared/enums/document.enum";
import { CardWrapper, Container } from "./styles";
import DocumentHeader from "./components/DocumentHeader/DocumentHeader";
import { useTeam } from "contexts/team/hooks";
import { useContractQuery } from "shared/api/contracts";
import { useDownloadDocumentQuery } from "shared/api/documents";
import { useFetchDocumentQuery } from "shared/api/documents";
import { theme } from "theme";
import { enqueueSnackbar } from "notistack";
import routePaths from "constants/routePaths";
import { ContractFormSyncPlugin } from "new-components/TextEditor/plugins/ContractFormSyncPlugin";
import { useFieldsQuery } from "shared/api";
import { filterEmptyPlaceholdersInEditorState } from "new-components/TextEditor/utils";
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";
import { EditorRefPlugin } from "@lexical/react/LexicalEditorRefPlugin";
import DocumentEmptyPreview from "./components/DocumentEmptyPreview/DocumentEmptyPreview";
import { isAcceptedFileType, extractFileExtension } from "constants/utils";
import { useTranslation } from "react-i18next";

export const motionStyles = {
  display: "flex",
  flexDirection: "column",
  height: "80vh",
  width: "100%",
} as MotionStyle;

type DocumentProps = {
  onBackClick?: () => void;
  onDeleteClick?: () => void;
  onDownloadClick?: () => void;
  onNextClick?: () => void;
  onPreviousClick?: () => void;
  selectedDocument?: DocumentDTO;
};

const MotionWrapper: React.FC<{
  children: React.ReactNode;
  padding?: string;
}> = ({ children, padding = "0" }) => (
  <motion.div
    {...motionProps}
    style={{ ...motionStyles, padding: padding || theme.spacing.md }}
  >
    {children}
  </motion.div>
);

const Document = ({
  onBackClick,
  onDeleteClick,
  onDownloadClick,
  selectedDocument,
  onNextClick,
  onPreviousClick,
}: DocumentProps) => {
  const {
    viewMode,
    setViewMode,
    isLoadingCreatePDFDocument,
    editorRef,
    localEditorState,
    setLocalEditorState,
    hasCreatedPDF,
  } = useContractDetails();
  const navigate = useNavigate();
  const { mode: routeParamMode, id: contractId } = useParams();
  const [searchParams] = useSearchParams();
  const modeAsSearchParam = searchParams.get("mode");
  const mode =
    routeParamMode === viewModeType.EDIT ||
    modeAsSearchParam === viewModeType.EDIT
      ? viewModeType.EDIT
      : viewModeType.VIEW;
  const { selectedTeamId, organizationId } = useTeam();
  const { data: contract = {} as ContractDTOV1 } = useContractQuery(
    selectedTeamId,
    contractId
  );

  const { data: documentFile, isLoading } = useDownloadDocumentQuery(
    organizationId,
    contract.teamId,
    contractId,
    selectedDocument?.id
  );

  const { data: document, isLoading: IsDocumentLoading } =
    useFetchDocumentQuery(
      organizationId,
      contract.teamId,
      contractId,
      selectedDocument?.id
    );
  const { data: fields } = useFieldsQuery(organizationId);

  const [initialEditorState, setInitialEditorState] =
    useState<EditorState | null>(null);
  const [temporaryPDFFile, setTemporaryPDFFile] = useState<Blob | undefined>(
    undefined
  );
  const [isLoadingTemporaryPDFView, setIsLoadingTemporaryPDFView] =
    useState(false);

  const [showEditor, setShowEditor] = useState<boolean>(false);
  const [isLexical, setIsLexical] = useState<boolean>(
    document?.mimetype === AcceptedFileType.LEXICAL
  );
  const { t } = useTranslation();

  const infoText = t("pages.contractDetails.documents.notPreviewingInfo", {
    fileType: extractFileExtension("", selectedDocument?.mimetype),
  });

  // Needed for refreshing editor's content when creating document from template after creating document from template
  useEffect(() => {
    setLocalEditorState(initialEditorState as EditorState);
  }, [selectedDocument?.id, initialEditorState]);

  useEffect(() => {
    setViewMode(
      mode === viewModeType.EDIT ? viewModeType.EDIT : viewModeType.VIEW
    );
  }, [mode]);

  const generateTemporaryPDFFile = useCallback(async () => {
    if (
      !document ||
      viewMode === viewModeType.EDIT ||
      document.mimetype !== AcceptedFileType.LEXICAL
    ) {
      return;
    }
    if (!editorRef.current) return;

    const editorState = editorRef.current.getEditorState().clone();

    if (!editorState) return;

    try {
      setIsLoadingTemporaryPDFView(true);

      const filteredEditorState =
        filterEmptyPlaceholdersInEditorState(editorState);

      const pdf = (await ContractTemplateService.renderContractTemplate(
        organizationId,
        {
          editorState: JSON.stringify(filteredEditorState),
        }
      )) as Blob;

      if (!pdf) return;

      setTemporaryPDFFile(pdf);
    } catch (error) {
      enqueueSnackbar(
        "pages.contractDetails.notifications.error_generate_temporary_pdf"
      );
      Sentry.captureException(error);
    } finally {
      setIsLoadingTemporaryPDFView(false);
    }
  }, [editorRef, document, viewMode]);

  useEffect(() => {
    if (document) {
      const lexical = document?.mimetype === AcceptedFileType.LEXICAL;
      setIsLexical(lexical);
      setShowEditor(lexical && viewMode === viewModeType.EDIT);
    }
  }, [document, viewMode]);

  useEffect(() => {
    if (!selectedDocument) {
      return;
    }
    const currentSearchParams = new URLSearchParams(searchParams);
    // this will explicitly change the mode to edit everywhere, as lexical file needs to be open in edit mode as default
    if (
      selectedDocument?.mimetype === AcceptedFileType.LEXICAL &&
      (!searchParams.has("mode") ||
        searchParams.get("mode") !== viewModeType.EDIT)
    ) {
      currentSearchParams.set("mode", viewModeType.EDIT);
    }
    navigate(
      `${routePaths.CONTRACT_DOCUMENTS.replace(":id", contractId ?? "")}/${
        selectedDocument?.id
      }?${currentSearchParams.toString()}`
    );
  }, [selectedDocument]);

  const handleEditorChange = useCallback(
    (editorState: EditorState) => {
      const currentStateJSON = editorState.toJSON();
      const initialStateJSON = initialEditorState?.toJSON();

      if (!isEqual(currentStateJSON, initialStateJSON)) {
        setLocalEditorState(editorState);
      }
    },
    [initialEditorState, localEditorState]
  );

  useEffect(() => {
    editorRef.current?.setEditable(mode === viewModeType.EDIT);
  }, [viewMode]);

  useEffect(() => {
    if (hasCreatedPDF) {
      return;
    }
    void generateTemporaryPDFFile();
  }, [isLexical, viewMode, document, hasCreatedPDF]);

  const initializeEditorState = async (editor: LexicalEditor) => {
    try {
      if (!documentFile) return;

      const templateData = await getTemplateObj(documentFile);

      if (!templateData) return;

      const draftContent = templateData.content;

      if (!draftContent) return;

      const parsedState = editor.parseEditorState(draftContent);

      if (localEditorState) {
        editor.setEditorState(localEditorState);
      } else {
        editor.setEditorState(parsedState);
        setInitialEditorState(parsedState);
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  if (isLoadingCreatePDFDocument) {
    return (
      <CardWrapper>
        <CircularProgress />
      </CardWrapper>
    );
  }

  if (!selectedDocument) {
    return (
      <MotionWrapper padding={theme.spacing.xl}>
        <ImportDocuments contractData={contract} />
      </MotionWrapper>
    );
  }

  if (isLoading || IsDocumentLoading || isLoadingTemporaryPDFView) {
    return (
      <CardWrapper>
        <CircularProgress />
      </CardWrapper>
    );
  }

  return (
    <>
      <Container>
        <MotionWrapper>
          <DocumentHeader
            contractFile={documentFile}
            selectedDocument={selectedDocument}
            onBackClick={onBackClick}
            onDeleteClick={onDeleteClick}
            onDownloadClick={onDownloadClick}
            onNextClick={onNextClick}
            onPreviousClick={onPreviousClick}
          />
          {showEditor ? (
            <TextEditor initialState={initializeEditorState}>
              <EditorRefPlugin editorRef={editorRef} />
              <OnChangePlugin onChange={handleEditorChange} />
              <ContractFormSyncPlugin fields={fields ?? []} />
            </TextEditor>
          ) : isAcceptedFileType(selectedDocument.mimetype) ? (
            <PDFComponent
              selectedDocument={selectedDocument}
              contractData={contract}
              data-testid="pdf-component"
              temporaryPdf={isLexical ? temporaryPDFFile : undefined}
            />
          ) : (
            <DocumentEmptyPreview
              onDownloadClick={onDownloadClick}
              infoText={infoText}
            />
          )}
        </MotionWrapper>
      </Container>
    </>
  );
};

export default Document;
