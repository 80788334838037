import styled from "@emotion/styled";
import { theme } from "theme";
import { Wrapper } from "../../Team/styles";

export const FormButtons = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-top: ${theme.spacing.lg};
  gap: ${theme.spacing.md};
`;

export const HeaderWrapper = styled(Wrapper)`
  display: flex;
  justify-content: space-between;
`;
