import styled from "@emotion/styled";
import { Tab, Tabs } from "@mui/material";

export const TabsWrapper = styled.div`
  max-width: 520px;
  width: 100%;
`;

export const TabsContainer = styled(Tabs)`
  position: relative;
  min-height: 32px;
  max-height: 32px;

  &:after {
    content: "";
    position: absolute;
    height: 1px;
    width: 100%;
    bottom: 0;
    left: 0;
    background-color: var(--color-primary);
  }

  .MuiTabs-indicator {
    background-color: var(--color-primary-active);
  }

  .MuiTabScrollButton-root {
    color: var(--color-primary);
  }
`;

export const TabItem = styled(Tab)`
  min-height: 32px;
  padding: 0;
  color: var(--color-primary);
  min-width: 0;

  &.Mui-selected {
    color: var(--color-primary-active);
  }
  &:not(:first-of-type) {
    margin-left: var(--spacing-xxxl);
  }
  :active {
    background: none;
  }
`;
