import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { CircularProgress } from "@mui/material";
import { ContractDTOV1 } from "openapi";
import { FileUploadComponent, NewModal } from "components";
import TemplateCollection from "pages/Settings/OrganizationSettings/ContractTemplates/TemplateCollection/TemplateCollection";
import { ContractTemplateMode } from "pages/Settings/OrganizationSettings/ContractTemplates/types";
import { useTeam } from "contexts/team/hooks";
import { Features } from "constants/features";

type ImportDocumentsProps = {
  contractData: ContractDTOV1;
};

const ImportDocuments = ({ contractData }: ImportDocumentsProps) => {
  const { t } = useTranslation();
  const { hasWriteAccess, hasFeature } = useTeam();
  const [showTemplateCollection, setShowTemplateCollection] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const contractId = contractData.id;

  const showSelectTemplateButton =
    hasWriteAccess(contractData?.categoryId, contractData?.teamId) &&
    hasFeature(Features.CONTRACT_TEMPLATES) &&
    contractData?.status === ContractDTOV1.status.DRAFT;

  if (isLoading) return <CircularProgress sx={{ margin: "auto" }} />;

  return (
    <>
      <div style={{ width: "100%", height: "100%" }}>
        <FileUploadComponent
          contractTeamId={contractData.teamId}
          setIsLoading={setIsLoading}
          contractId={contractId}
          showSelectTemplateButton={showSelectTemplateButton}
          showImportFromTemplateModal={() => setShowTemplateCollection(true)}
        />
      </div>

      <NewModal
        fullWidth
        maxWidth="xl"
        open={showTemplateCollection}
        handleClose={() => setShowTemplateCollection(false)}
        title={t("pages.settings.tabs.contractTemplates.title")}
        body={
          <TemplateCollection
            mode={ContractTemplateMode.SELECT}
            contract={contractData}
            handleClose={() => {
              setShowTemplateCollection(false);
            }}
          />
        }
      />
    </>
  );
};

export default ImportDocuments;
