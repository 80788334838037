/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ContractTemplateCreateV1DTO } from "../models/ContractTemplateCreateV1DTO";
import type { ContractTemplateListItemV1DTO } from "../models/ContractTemplateListItemV1DTO";
import type { ContractTemplateTagDTO } from "../models/ContractTemplateTagDTO";
import type { ContractTemplateTagInputDTO } from "../models/ContractTemplateTagInputDTO";
import type { ContractTemplateUpdateV1DTO } from "../models/ContractTemplateUpdateV1DTO";
import type { ContractTemplateV1DTO } from "../models/ContractTemplateV1DTO";
import type { RenderTemplateRequestDTO } from "../models/RenderTemplateRequestDTO";

import type { CancelablePromise } from "../core/CancelablePromise";
import { OpenAPI } from "../core/OpenAPI";
import { request as __request } from "../core/request";

export class ContractTemplateService {
  /**
   * Get the list of contract templates per organization.
   * @param organizationId
   * @returns ContractTemplateListItemV1DTO
   * @throws ApiError
   */
  public static getAll(
    organizationId: string
  ): CancelablePromise<Array<ContractTemplateListItemV1DTO>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/organizations/{organizationId}/contract-template/templates",
      path: {
        organizationId: organizationId,
      },
    });
  }

  /**
   * Create a contract template.
   * @param organizationId
   * @param requestBody
   * @returns ContractTemplateV1DTO
   * @throws ApiError
   */
  public static create(
    organizationId: string,
    requestBody: ContractTemplateCreateV1DTO
  ): CancelablePromise<ContractTemplateV1DTO> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/organizations/{organizationId}/contract-template/templates",
      path: {
        organizationId: organizationId,
      },
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * Get the list of contract templates per organization.
   * @param templateId
   * @param organizationId
   * @returns ContractTemplateV1DTO
   * @throws ApiError
   */
  public static getById(
    templateId: string,
    organizationId: string
  ): CancelablePromise<ContractTemplateV1DTO> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/organizations/{organizationId}/contract-template/templates/{templateId}",
      path: {
        templateId: templateId,
        organizationId: organizationId,
      },
    });
  }

  /**
   * Update a contract template.
   * @param templateId
   * @param organizationId
   * @param requestBody
   * @returns ContractTemplateV1DTO
   * @throws ApiError
   */
  public static update(
    templateId: string,
    organizationId: string,
    requestBody: ContractTemplateUpdateV1DTO
  ): CancelablePromise<ContractTemplateV1DTO> {
    return __request(OpenAPI, {
      method: "PATCH",
      url: "/organizations/{organizationId}/contract-template/templates/{templateId}",
      path: {
        templateId: templateId,
        organizationId: organizationId,
      },
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * Delete a contract template.
   * @param templateId
   * @param organizationId
   * @returns ContractTemplateV1DTO
   * @throws ApiError
   */
  public static delete(
    templateId: string,
    organizationId: string
  ): CancelablePromise<ContractTemplateV1DTO> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/organizations/{organizationId}/contract-template/templates/{templateId}",
      path: {
        templateId: templateId,
        organizationId: organizationId,
      },
    });
  }

  /**
   * Get a contract template preview image.
   * @param templateId
   * @param organizationId
   * @returns any
   * @returns binary
   * @throws ApiError
   */
  public static getImage(
    templateId: string,
    organizationId: string
  ): CancelablePromise<any | Blob> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/organizations/{organizationId}/contract-template/templates/{templateId}/preview",
      path: {
        templateId: templateId,
        organizationId: organizationId,
      },
    });
  }

  /**
   * Get contract template preview image.
   * @param organizationId
   * @param requestBody
   * @returns binary
   * @returns any
   * @throws ApiError
   */
  public static renderContractTemplate(
    organizationId: string,
    requestBody: RenderTemplateRequestDTO
  ): CancelablePromise<Blob | any> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/organizations/{organizationId}/contract-template/render-template",
      path: {
        organizationId: organizationId,
      },
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * Add a contract template tag.
   * @param organizationId
   * @param requestBody
   * @returns ContractTemplateTagDTO
   * @throws ApiError
   */
  public static createTag(
    organizationId: string,
    requestBody: ContractTemplateTagInputDTO
  ): CancelablePromise<ContractTemplateTagDTO> {
    return __request(OpenAPI, {
      method: "POST",
      url: "/organizations/{organizationId}/contract-template/tags",
      path: {
        organizationId: organizationId,
      },
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * Get list of contract template tags.
   * @param organizationId
   * @returns ContractTemplateTagDTO
   * @throws ApiError
   */
  public static getAllTags(
    organizationId: string
  ): CancelablePromise<Array<ContractTemplateTagDTO>> {
    return __request(OpenAPI, {
      method: "GET",
      url: "/organizations/{organizationId}/contract-template/tags",
      path: {
        organizationId: organizationId,
      },
    });
  }

  /**
   * Update a contract template tag.
   * @param tagId
   * @param organizationId
   * @param requestBody
   * @returns ContractTemplateTagDTO
   * @throws ApiError
   */
  public static updateTag(
    tagId: string,
    organizationId: string,
    requestBody: ContractTemplateTagInputDTO
  ): CancelablePromise<ContractTemplateTagDTO> {
    return __request(OpenAPI, {
      method: "PATCH",
      url: "/organizations/{organizationId}/contract-template/tags/{tagId}",
      path: {
        tagId: tagId,
        organizationId: organizationId,
      },
      body: requestBody,
      mediaType: "application/json",
    });
  }

  /**
   * Delete a contract template tag.
   * @param tagId
   * @param organizationId
   * @returns ContractTemplateTagDTO
   * @throws ApiError
   */
  public static deleteTag(
    tagId: string,
    organizationId: string
  ): CancelablePromise<ContractTemplateTagDTO> {
    return __request(OpenAPI, {
      method: "DELETE",
      url: "/organizations/{organizationId}/contract-template/tags/{tagId}",
      path: {
        tagId: tagId,
        organizationId: organizationId,
      },
    });
  }
}
