import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm, useWatch } from "react-hook-form";
import { Box, Grid } from "@mui/material";
import GridOnIcon from "@mui/icons-material/GridOn";
import { useTasksQuery } from "shared/api";
import { useTeam } from "contexts/team/hooks";
import { useGuideModal, useUserInfo } from "hooks/GlobalStateHooks";
import BaseBodyWrapper from "components/BaseBodyWrapper/BaseBodyWrapper";
import { FormSelect } from "components/FormItems/FormSelect/FormSelect";
import { GuideModal } from "containers";
import * as Widgets from "./Widgets";
import { Features } from "../../constants";
import NewModal from "components/Modal/NewModal";
import ImportStatusModal from "components/FlatFileImporter/client/components/ImportStatusModal/ImportStatusModal";
import { ImportDataDTO } from "openapi";

export const StatisticsViewMode = {
  SHOW_TOTAL: "SHOW_TOTAL",
  SHOW_SELECTED_ONLY: "SHOW_SELECTED_ONLY",
} as const;
export type StatisticsViewModeType =
  (typeof StatisticsViewMode)[keyof typeof StatisticsViewMode];

export interface WidgetProps {
  statisticsViewMode: StatisticsViewModeType;
}

const Dashboard = () => {
  const { t } = useTranslation();
  const { selectedTeamId, hasFeature } = useTeam();
  const { teamById } = useUserInfo();
  const { showGuideModal, setShowGuideModal } = useGuideModal();

  const { refetch: refetchTasks } = useTasksQuery(selectedTeamId);
  const [showCSVImportStatus, setShowCSVImportStatus] = useState(false);
  const [importStatus, setImportStatus] = useState<ImportDataDTO>();

  const { control } = useForm({
    defaultValues: {
      statisticsViewMode: StatisticsViewMode.SHOW_TOTAL,
    },
  });

  const statisticsViewMode = useWatch({
    name: "statisticsViewMode",
    control: control,
  });

  const multiTeamMode = Boolean(teamById(selectedTeamId)?.children.length);

  const handleOnCSVData = (status: ImportDataDTO) => {
    setShowGuideModal(false);
    setShowCSVImportStatus(true);
    setImportStatus(status);
  };

  return (
    <>
      <BaseBodyWrapper
        actions={
          <>
            {multiTeamMode && (
              <FormSelect
                name="statisticsViewMode"
                options={Object.keys(StatisticsViewMode)}
                translationPrefix={
                  "pages.dashboard.statisticsTypeSelectOptions"
                }
                control={control}
                margin="none"
              />
            )}
          </>
        }
      >
        <Box sx={{ padding: "1rem 0" }}>
          <Grid container spacing={1.25}>
            <Grid item sm={12} md={6}>
              <Widgets.Tasks statisticsViewMode={statisticsViewMode} />
              <Widgets.Recent statisticsViewMode={statisticsViewMode} />
              <Widgets.Activity statisticsViewMode={statisticsViewMode} />
              {hasFeature(Features.ESIGNATURE) && (
                <Widgets.Signature statisticsViewMode={statisticsViewMode} />
              )}
            </Grid>
            <Grid item sm={12} md={6}>
              <Widgets.Statistics statisticsViewMode={statisticsViewMode} />
              <Widgets.Categories statisticsViewMode={statisticsViewMode} />
            </Grid>
          </Grid>
        </Box>
      </BaseBodyWrapper>

      <GuideModal
        fetchData={async () => {
          await refetchTasks();
        }}
        open={showGuideModal}
        handleClose={() => setShowGuideModal(false)}
        handleOnCSVData={handleOnCSVData}
      />

      <NewModal
        open={showCSVImportStatus}
        handleClose={() => setShowCSVImportStatus(false)}
        title={t("pages.import.importModal.importTitle")}
        icon={<GridOnIcon />}
        body={
          importStatus ? <ImportStatusModal importData={importStatus} /> : <></>
        }
        fullWidth
      />
    </>
  );
};

export default Dashboard;
