import styled from "@emotion/styled";
import { Divider } from "@mui/material";
import { theme } from "theme";
import { FloatingActionToolbarPosition } from "./FloatingActionToolbar";

const bgColor = "#ECEFFA";

export const FloatingActionToolbarAnchor = styled.div<{
  $position: FloatingActionToolbarPosition;
}>`
  ${({ $position }) => `
  top: ${$position.top}px;
  left: ${$position.left}px;
  width: ${$position.width}px;
  height: ${$position.height}px;
`}
  align-items: center;
  display: flex;
  justify-content: center;
  pointer-events: none;
  position: absolute;
  transform: translate(-50%, -50%);
`;

export const FloatingActionToolbarWrapper = styled.div`
  align-items: center;
  background-color: ${bgColor};
  border-radius: ${theme.borderRadius};
  border: 1px solid var(--color-primary);
  box-shadow: ${theme.shadow.contractTemplate};
  display: flex;
  gap: ${theme.spacing.sm};
  justify-content: center;
  width: 70%;
  pointer-events: auto;
  padding: var(--spacing-xs);
`;

export const FloatingActionToolbarButtonGroup = styled.div`
  margin-left: auto;
  display: flex;
`;

export const FloatingActionNumberSelectedText = styled.span`
  font-size: ${theme.font.size.icon};
  color: var(--color-primary);
`;

export const FloatingActionToolbarButton = styled.div`
  position: relative;
  padding-left: var(--spacing-sm);
`;

export const FloatingActionToolbarDivider = styled(Divider)`
  border-color: var(--color-primary);
  position: absolute;
  top: calc(var(--spacing-xs) * -1);
  height: calc(100% + var(--spacing-xs) * 2);
`;
