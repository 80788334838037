import React, { ReactNode } from "react";
import styled from "@emotion/styled";
import { Card } from "@mui/material";
import { theme } from "theme";

export enum CardWrapperBgColorEnum {
  white,
  blue,
}

type WrapperProps = {
  backgroundcolor: CardWrapperBgColorEnum;
  height: string;
  maxheight?: string;
  overflow?: string;
  isshowtopandbottompaddingonly?: string;
  isCardNested?: boolean;
  children: ReactNode;
  style?: React.CSSProperties;
};

const Wrapper = styled(({ isCardNested, children, ...rest }: WrapperProps) => (
  <Card {...rest}>{children}</Card>
))`
  border-radius: ${theme.borderRadius};
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 2px 0 rgb(0 0 0 / 6%);
  margin-bottom: ${theme.spacing.xl};
  background-color: ${(prop: WrapperProps) =>
    prop.backgroundcolor === CardWrapperBgColorEnum.blue ? "#4299e1" : "#fff"};
  padding: ${(prop) =>
    prop.isshowtopandbottompaddingonly
      ? `${theme.spacing.xl} 0`
      : theme.spacing.xl};
  height: ${(prop) => prop.height};
  max-height: ${(prop) => (prop.maxheight ? prop.maxheight : "initial")};
  overflow-y: ${(prop) => (prop.overflow ? prop.overflow : "none")};
  ${(props) =>
    props.isCardNested &&
    `
    box-shadow: none;
    padding: 0;
    &:first-of-type {
      margin-top: ${theme.spacing.sm};
    }
    &:last-of-type {
      margin-bottom: 0;
    }
    .MuiGrid-container {
      margin-top: 0;
    }
  `}

  @media print {
    box-shadow: none;
    border-radius: 0;
    margin-bottom: 0;
    break-inside: avoid;
  }
`;

type Prop = {
  children: ReactNode;
  backgroundcolor?: CardWrapperBgColorEnum;
  height?: string;
  maxheight?: string;
  overflow?: string;
  isShowTopAndBottomPaddingOnly?: string;
  style?: React.CSSProperties;
  isCardNested?: boolean;
};

const CardWrapper = ({
  backgroundcolor = CardWrapperBgColorEnum.white,
  children,
  height = "auto",
  maxheight,
  overflow,
  isShowTopAndBottomPaddingOnly = undefined,
  style,
  isCardNested,
  ...props
}: Prop) => (
  <Wrapper
    {...props}
    style={style}
    backgroundcolor={backgroundcolor}
    height={height}
    maxheight={maxheight}
    overflow={overflow}
    isshowtopandbottompaddingonly={isShowTopAndBottomPaddingOnly}
    isCardNested={isCardNested}
  >
    {children}
  </Wrapper>
);

export default CardWrapper;
