import { ProfileDto } from "openapi";

export const getUserName = (profile?: ProfileDto) => {
  const firstName = profile?.firstname ?? "";
  const lastName = profile?.lastname ?? "";

  if (lastName) {
    const hasLongLastName = lastName?.length > 10;
    const surName = hasLongLastName ? `${lastName.slice(0, 10)}..` : lastName;
    if (firstName) {
      return `${firstName.charAt(0)}.${surName}`;
    } else {
      return surName;
    }
  } else {
    if (firstName) {
      return firstName;
    }
  }
  return "";
};
