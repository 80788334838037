import React from "react";
import { Wrapper } from "./styles";

type Props = {
  fullWidth?: boolean;
  style?: React.CSSProperties;
  children: JSX.Element;
};

const Container = ({ children, ...props }: Props) => (
  <Wrapper {...props} id="main-content">
    {children}
  </Wrapper>
);

export default Container;
